import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import CustomSelect from "../../../../components/Select"


//import ReactSelect, { Props } from 'react-select';

const SelectMember = () => {
  useEffect(()=>{
    document.title = 'Select Member';
  }, []);

  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
];
  
 

  return (
    <>
    <div className="h-full lg:h-auto lg:min-h-[calc(100vh_-_107px)] flex lg:justify-between lg:items-center">
        <div className="h-full flex flex-col justify-between items-center lg:h-auto w-full pt-12 lg:py-0 px-8 lg:px-0 min-h-[calc(100vh_-_60px)] lg:min-h-full">
        <div className="max-w-md mx-auto w-full">
        <div className="text-center mb-5 lg:mb-7">
            <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-2">…and your family.</h2>
            <p className='text-cyan text-xs font-bold lg:text-lg'>Please answer the question below.</p>
        </div>
        <form className="" action="#">
          <div className="mb-3">
          <label className="block">
                <span className="text-gray-700 text-xs lg:text-lg mb-2 block">Who are we setting up an account for today?</span>
                <CustomSelect phtext="Select a Member" optionsval={options} />
              </label>
          </div>
          <div className="text-center mt-7 mb-5"><Link to="/set-password" className='btn uppercase'>Continue</Link></div>
          <div className='text-center'><Link to="/registration" className='text-xs font-bold text-secondary uppercase'>GO BACK</Link></div>
      </form>
        </div>
       
      

        
        </div>
      </div>
    </>
  )
}

export default SelectMember