import React from 'react'
import { Link } from "react-router-dom";

const UserEligible = () => {
  return (
    <>
    <div className="px-8 my-10 max-w-md mx-auto">
        <header className='text-left mb-5'>
            <h2 className='font-playfair text-secondary font-bold text-2xl mb-2'>investments</h2>
        </header>

      <div className='pb-5'>
        <p className='text-xs text-secondary font-bold mb-2'>CONGRATS! YOU’RE ELIGIBLE TO INVEST.</p>
        <p className='text-xs text-secondary mb-2'>We just need a little information before you start.</p>
        <p className='text-xs text-secondary mb-5'>By continuing, I confirm I am a U.S. resident, 18 years 
or older, have read the <Link to="#" className='link'>DriveWealth Disclosures and 
Disclaimers</Link>, agree to open a brokerage account, and
agree to the <Link to="#" className='link'>MotivHSA Investments Agreement</Link>, <Link to="#" className='link'>ABG 
Advisory Agreement</Link>, and <Link to="#" className='link'>DriveWealth Customer 
Agreement.</Link></p>
      </div>

      <div className="text-center"><Link to="#" className="btn uppercase">CONFIRM</Link></div>


      <div className="mt-20 text-xs text-secondary">
      <p className='mb-2'><strong>*Important Risk Disclosures:</strong> You should carefully 
consider your risk tolerance, time horizon, and 
financial objectives before making investment 
decisions. By investing, you run the risk of losing 
money or losing buying power (where your money 
does not grow as fast as the cost of living.)</p>

<p>Note: there is a monthly fee to participate in 
investments ($1.50/month plus 50 annualized basis 
points of invested assets/month will be deducted from 
your HSA balance).</p>
      </div>

    </div>
    </>
  )
}

export default UserEligible