import React from 'react'
import { Link } from "react-router-dom";
import SearchBar from '../../components/SearchBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faReceipt,
  faUserDoctor,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons'
import ArrowRightIcon from '../../components/Icons/ArrowRightIcon';
import CustomSelect from '../../components/Select';

import logoicon from "../../assets/images/logo-icon.svg";
//import icontrash from "../../assets/images/svg/trash-can-solid.svg";
import ActionCardButton from '../../components/Button/ActionCardButton';
import iconfindCare from "../../assets/images/svg/magnifying-glass-dollar-solid.svg";
import iconprescriptions from "../../assets/images/svg/prescription-solid.svg";
import CardButton from '../../components/Button/CardButton';
 

const Favorites = () => {
  const options = [
    { value: 'Doctors', label: 'Doctors' },
    { value: 'Facilities', label: 'Facilities' },
  ];

  return (
    <>
    <SearchBar />
    <div className='container lg:px-30px 2xl:px-0 lg:mt-12 mb-8 lg:mb-10 mt-5'>
          <div className='lg:grid lg:grid-cols-12 lg:gap-10'>
          <div className='lg:col-span-3 lg:order-2'>
              <div className='mb-5 lg:mb-12 px-30px lg:px-0' >
              <CustomSelect phtext="Filter By…" optionsval={options} />
              </div>

              <div className="container px-30px 2xl:px-0 lg:hidden">
          <div className="grid grid-cols-12 gap-7 lg:gap-14">
              <div className="col-span-6 lg:col-span-3">
              <CardButton title="FIND CARE" url="/find-care" xicon={iconfindCare} imageClassName="w-[36.4px] h-[36.4px]  mx-auto lg:m-0" />
              </div>
              <div className="col-span-6 lg:col-span-3">
              <CardButton title="PRESCRIPTIONS" url="/prescriptions" xicon={iconprescriptions} imageClassName="w-[27.66px] h-[32.06px]  mx-auto lg:m-0" />
              </div>
          </div>
          </div>

              <div className='hidden lg:block'>
              <ActionCardButton title="" url="/find-care" linktitle="FIND CARE" icon={true} iconimage={iconfindCare} iconcname="w-[13.76px] h-[18.35px] lg:w-[42.36px] lg:h-[42.36px]" />
<ActionCardButton title="" url="/prescriptions" linktitle="PRESCRIPTIONS" icon={true} iconimage={iconprescriptions} iconcname="w-[13.76px] h-[18.35px] lg:w-[34.85px] lg:h-[40.39px]" /></div>

              
            </div>
            <div className='lg:col-span-9 lg:order-1'>
            <header className='w-full hidden lg:flex pb-10'>
                  <div>
                    <h2 className='font-playfair text-secondary font-bold text-2xl lg:text-38px mb-1'>favorites</h2>
                    </div>
                  <div className='ml-auto'>
                      <Link to="" className='text-base font-bold text-cyan mt-2 inline-block'>EDIT FAVORITES</Link>
                  </div>
              </header>



            <div className='mb-10 lg:mb-16 pt-7 lg:pt-0'>
            <div className="flex px-8 lg:pl-7 lg:pr-7 py-2">
<div className="w-3/4 text-xs font-bold uppercase lg:text-base text-secondary">
DOCTORS
</div>
<div className="w-1/4 text-xs lg:text-lg lg:text-right">

</div>
</div>
{/* END */}

<div className="bg-gray-light   border-t-2 border-secondary border-opacity-25 pl-8 pr-8 lg:pl-7 lg:pr-7 py-3">
<div className="w-full flex mb-4 lg:mb-9">
<div className="w-4/5 text-xs">
<div className="flex relative lg:text-base text-secondary">
Dr. Smith
<img src={logoicon} alt="" className="w-3 ml-2" />
</div>
<small className="text-6px lg:text-10px uppercase text-secondary font-bold">
DERMATOLOGY
</small>
</div>
<div className="w-1/5 text-xs text-right">
{/* <Link to="#"><img src={icontrash} className="w-[11.3px] h-[12.92px] lg:w-[20.48px] lg:h-[23.41px] inline-block" alt="" /></Link> */}
</div>
</div>

<Link to="/find-care/provider/open-imaging" className="flex items-center">
<div className="text-secondary text-10px lg:text-sm font-bold uppercase">
VIEW DETAILS
</div>
<div className="ml-auto">
<ArrowRightIcon
fill="#1c3557"
cname="h-[11px] w-[12px] lg:h-[10.85px] lg:w-[10.98px]"
/>
</div>
</Link>
</div>
{/* END */}

<div className="bg-gray-light   border-t-2 border-secondary border-opacity-25 pl-8 pr-8 lg:pl-7 lg:pr-7 py-3">
<div className="w-full flex mb-4 lg:mb-9">
<div className="w-4/5 text-xs">
<div className="flex relative lg:text-base text-secondary">
Dr. Smith
<img src={logoicon} alt="" className="w-3 ml-2" />
</div>
<small className="text-6px lg:text-10px uppercase text-secondary font-bold">
DERMATOLOGY
</small>
</div>
<div className="w-1/5 text-xs text-right">
{/* <Link to="#"><img src={icontrash} className="w-[11.3px] h-[12.92px] lg:w-[20.48px] lg:h-[23.41px] inline-block" alt="" /></Link> */}
</div>
</div>

<Link to="/find-care/provider/open-imaging" className="flex items-center">
<div className="text-secondary text-10px lg:text-sm font-bold uppercase">
VIEW DETAILS
</div>
<div className="ml-auto">
<ArrowRightIcon
fill="#1c3557"
cname="h-[11px] w-[12px] lg:h-[10.85px] lg:w-[10.98px]"
/>
</div>
</Link>
</div>
{/* END */}
            </div>

            <div className='mb-5'>
            <div className="flex px-8 lg:pl-7 lg:pr-7 py-2">
<div className="w-3/4 text-xs font-bold uppercase lg:text-base text-secondary">
FACILITIES
</div>
<div className="w-1/4 text-xs lg:text-lg lg:text-right">

</div>
</div>
{/* END */}

<div className="bg-gray-light   border-t-2 border-secondary border-opacity-25 pl-8 pr-8 lg:pl-7 lg:pr-7 py-3">
<div className="w-full flex mb-4 lg:mb-9">
<div className="w-4/5 text-xs">
<div className="flex relative lg:text-base text-secondary">
Open Imaging
<img src={logoicon} alt="" className="w-3 ml-2" />
</div>
<small className="text-6px lg:text-10px uppercase text-secondary font-bold">
X - RAYS / IMAGING
</small>
</div>
<div className="w-1/5 text-xs text-right">
{/* <Link to="#"><img src={icontrash} className="w-[11.3px] h-[12.92px] lg:w-[20.48px] lg:h-[23.41px] inline-block" alt="" /></Link> */}
</div>
</div>

<Link to="/find-care/provider/open-imaging" className="flex items-center">
<div className="text-secondary text-10px lg:text-sm font-bold uppercase">
VIEW DETAILS
</div>
<div className="ml-auto">
<ArrowRightIcon
fill="#1c3557"
cname="h-[11px] w-[12px] lg:h-[10.85px] lg:w-[10.98px]"
/>
</div>
</Link>
</div>
{/* END */}

<div className="bg-gray-light   border-t-2 border-secondary border-opacity-25 pl-8 pr-8 lg:pl-7 lg:pr-7 py-3">
<div className="w-full flex mb-4 lg:mb-9">
<div className="w-4/5 text-xs">
<div className="flex relative lg:text-base text-secondary">
Open Imaging
<img src={logoicon} alt="" className="w-3 ml-2" />
</div>
<small className="text-6px lg:text-10px uppercase text-secondary font-bold">
X - RAYS / IMAGING
</small>
</div>
<div className="w-1/5 text-xs text-right">
{/* <Link to="#"><img src={icontrash} className="w-[11.3px] h-[12.92px] lg:w-[20.48px] lg:h-[23.41px] inline-block" alt="" /></Link> */}
</div>
</div>

<Link to="/find-care/provider/open-imaging" className="flex items-center">
<div className="text-secondary text-10px lg:text-sm font-bold uppercase">
VIEW DETAILS
</div>
<div className="ml-auto">
<ArrowRightIcon
fill="#1c3557"
cname="h-[11px] w-[12px] lg:h-[10.85px] lg:w-[10.98px]"
/>
</div>
</Link>
</div>
{/* END */}
            </div>
          


            </div>
          </div>
      </div>




    {/* <div className='px-8  mt-5 mb-5 max-w-md mx-auto'>
            <h2 className='font-playfair text-secondary font-bold text-24px mb-2'>favorites</h2>
    </div>
    <div className="px-8 max-w-md mx-auto">
    <div className="grid grid-cols-12 gap-7">
          <div className="col-span-6">
            <div className="bg-gray-light w-full border-t-6 border-cyan rounded-md p-3">
              <Link to="/find-doctor">
                <div className="text-center text-secondary h-14 flex justify-center items-center">
                  <FontAwesomeIcon
                    icon={faUserDoctor}
                    className="text-secondary text-4xl"
                  />
                </div>
                <div className="flex items-center">
                  <div className="text-secondary text-10px font-bold uppercase">
                    Find Doctor
                  </div>
                  <div className="ml-auto">
                    <img src="images/arrow-right.svg" alt="" />
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div className="col-span-6">
            <div className="bg-gray-light w-full border-t-6 border-cyan rounded-md p-3">
              <Link to="/prescriptions">
                <div className="h-14 flex justify-center items-center">
                  <FontAwesomeIcon
                    icon={faPrescription}
                    className="text-secondary text-4xl"
                  />
                </div>

                <div className="flex items-center">
                  <div className="text-secondary text-10px font-bold uppercase">
                  PRESCRIPTIONS
                  </div>
                  <div className="ml-auto">
                    <img src="images/arrow-right.svg" alt="" />
                  </div>
                </div>
              </Link>
            </div>
          </div>

          
        </div>
    </div>


    <div className='my-3 max-w-md mx-auto'>
    <div className='flex px-8 py-4 border-b-2 border-secondary border-opacity-25'>
        <div className="w-3/4 text-xs font-bold uppercase">DOCTORS</div>
    </div>
    <div className='bg-gray-light border-b-2 border-secondary border-opacity-25 px-8 py-3'>
            <div className="w-full flex mb-4">
                <div className="w-3/4 text-xs">
                    <div className='flex relative'>
                    Dr. Smith <img src="../../images/logo-icon.svg" alt="" className='w-3 ml-2' /></div>
                    <small className='text-6px uppercase text-secondary font-bold'>DERMATOLOGY</small>
                </div>
            </div>
             <Link to="#" className='flex'>
            <div className="text-secondary text-10px font-bold uppercase">
            VIEW DETAILS
            </div>
            <div className="ml-auto">
            <img src="../../images/arrow-right.svg" alt="" />
            </div>
            </Link>
         </div>
         <div className='bg-gray-light border-b-2 border-secondary border-opacity-25 px-8 py-3'>
            <div className="w-full flex mb-4">
                <div className="w-3/4 text-xs">
                    <div className='flex relative'>
                    Dr. Johnson <img src="../../images/logo-icon.svg" alt="" className='w-3 ml-2' /></div>
                    <small className='text-6px uppercase text-secondary font-bold'>CHIROPRACTIC</small>
                </div>
            </div>
             <Link to="#" className='flex'>
            <div className="text-secondary text-10px font-bold uppercase">
            VIEW DETAILS
            </div>
            <div className="ml-auto">
            <img src="../../images/arrow-right.svg" alt="" />
            </div>
            </Link>
         </div>
    </div>

    <div className='my-3 max-w-md mx-auto'>
    <div className='flex px-8 py-4 border-b-2 border-secondary border-opacity-25'>
        <div className="w-3/4 text-xs font-bold uppercase">FACILITIES</div>
    </div>
         <div className='bg-gray-light border-b-2 border-secondary border-opacity-25 px-8 py-3'>
            <div className="w-full flex mb-4">
                <div className="w-3/4 text-xs">
                    <div className='flex relative'>
                    Open Imaging <img src="../../images/logo-icon.svg" alt="" className='w-3 ml-2' /></div>
                    <small className='text-6px uppercase text-secondary font-bold'>3 LOCATIONS NEAR YOU</small>
                </div>
            </div>
             <Link to="#" className='flex'>
            <div className="text-secondary text-10px font-bold uppercase">
            VIEW DETAILS
            </div>
            <div className="ml-auto">
            <img src="../../images/arrow-right.svg" alt="" />
            </div>
            </Link>
         </div>
    </div> */}

    </>
  )
}

export default Favorites