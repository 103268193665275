import React from 'react'

const ArrowDownIcon = (props) => {
  return (
    <>
         <svg xmlns="http://www.w3.org/2000/svg" width="10.85" height="10.977" className='inline' viewBox="0 0 10.85 10.977">
  <path id="arrow-right" d="M5.344,34.672l-.485.485a.294.294,0,0,0,0,.416l3.8,3.8H.294A.294.294,0,0,0,0,39.668v.686a.294.294,0,0,0,.294.294H8.66l-3.8,3.8a.294.294,0,0,0,0,.416l.485.485a.294.294,0,0,0,.416,0l5.131-5.131a.294.294,0,0,0,0-.416L5.76,34.672A.294.294,0,0,0,5.344,34.672Z" transform="translate(45.436) rotate(90)" fill="#a9dadc"/>
</svg>
    </>
  )
}

export default ArrowDownIcon