import React from 'react'

const ArrowRight = (props) => {
  return (
    <>
        <svg xmlns="http://www.w3.org/2000/svg" className={`box ${props.cname}`}  viewBox="0 0 10.85 10.977">
<path id="arrow-right" className="w-4 h-4 text-slate-50" d="M5.3,0.1L4.9,0.6C4.7,0.7,4.7,0.9,4.9,1c0,0,0,0,0,0l3.8,3.8H0.3C0.1,4.8,0,4.9,0,5.1  c0,0,0,0,0,0v0.7c0,0.2,0.1,0.3,0.3,0.3h8.4L4.9,9.9c-0.1,0.1-0.1,0.3,0,0.4c0,0,0,0,0,0l0.5,0.5c0.1,0.1,0.3,0.1,0.4,0c0,0,0,0,0,0  l5.1-5.1c0.1-0.1,0.1-0.3,0-0.4c0,0,0,0,0,0L5.8,0.1C5.6,0,5.5,0,5.3,0.1C5.3,0.1,5.3,0.1,5.3,0.1z" fill={props.fill} />
</svg>
    </>
  )
}

export default ArrowRight