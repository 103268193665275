import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {
//   faArrowRight
// } from '@fortawesome/free-solid-svg-icons'

import arrowRightWhite from '../../../../assets/images/arrow-right-white.svg'

function WhichAccount() {

  useEffect(()=>{
    document.title = 'Which Account';
  }, []);

  return (
    <>
        <div className="h-full lg:h-auto lg:min-h-[calc(100vh_-_107px)] flex lg:justify-between lg:items-center">
        <div className="h-full flex flex-col justify-between items-center lg:h-auto w-full pt-12 lg:py-0 px-8 lg:px-0 min-h-[calc(100vh_-_60px)] lg:min-h-full">
        <div className="max-w-627px mx-auto w-full">
        <div className="text-center mb-5 lg:mb-7">
            <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-2">your start card account</h2>
            <p className='text-cyan text-xs font-bold lg:text-lg lg:mb-5'>Please select one of the options below.</p>
            <div className='text-xs lg:text-lg pt-5 text-justify text-secondary'>Please select the banking account you used to register or “Add Different Account” if you wish to use another banking account for expenses paid with your Start card.</div>
        </div>
        <form className="" action="#">
         
         <ul className='bg-gray-light border-t-6 rounded-l-5px rounded-r-5px border-cyan'>
           <li className=''>
           <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                   <input type="radio" className='form-radio' checked />
                   <span className="ml-2 text-xs lg:text-lg text-secondary">Registration Account Name</span>
                 </label>
           </li>
           <li className='p-4'>
           <Link to="/add-bank-account" className='btn uppercase w-full flex justify-center items-center cursor-pointer'>ADD DIFFERENT ACCOUNT
           <img src={arrowRightWhite} alt="" className='ml-auto' />
           </Link>
           </li>
         </ul>             

         <div className="text-center mt-7 mb-5"><Link to="/open-start-hsa" className='btn  uppercase'>Continue</Link></div>
         <div className='text-center'><Link to="/enter-address" className='text-xs font-bold text-secondary uppercase'>Go Back</Link></div>
         
     </form> {/* END  */}
        </div>
        
      

        
        </div>
      </div>
    </>
  )
}

export default WhichAccount