import React from 'react'
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ArrowRightIcon from '../Icons/ArrowRightIcon'

const CardButtonNew = ({faicon, title, url, href, sicon, xicon, imageClassName, titleClass, ...otherProps}) => {
  return (
    
<>
{
        url ? (
          <Link {...otherProps} to={url} className='bg-gray-light w-full border-t-6 border-cyan rounded-md p-3 lg:pl-6 lg:pr-4 lg:py-4  h-[103px] lg:h-[150px] flex flex-col justify-between'>
        { sicon === true ? 
          <div className="text-gray-light h-10 lg:h-12 my-2 lg:my-5 flex justify-left items-left">
            <img src={`${xicon}`} className={imageClassName} alt={title}  />
          </div>
        : <div className="text-center lg:text-left h-10 lg:h-12 my-2 lg:my-5">
              <img src={`${xicon}`} className={imageClassName} alt={title}  />
          </div> 
          }
          
          <div className={`flex items-center ${titleClass}`}>  
          <div className="text-secondary text-10px lg:text-sm font-bold uppercase">{title}</div>
              <ArrowRightIcon  cname="h-[11px] w-3 lg:w-4 lg:h-4 ml-auto" fill="#1c3557" />
          </div>
      </Link>
        ) : (
          <a href={href} className='bg-gray-light w-full border-t-6 border-cyan rounded-md p-3 lg:pl-6 lg:pr-4 lg:py-4  h-[103px] lg:h-[150px] flex flex-col justify-between' {...otherProps}>
{ sicon === true ? 
          <div className="text-gray-light h-10 lg:h-12 my-2 lg:my-5 flex justify-left items-left">
            <img src={`${xicon}`} className={imageClassName} alt={title}  />
          </div>
        : <div className="text-center lg:text-left h-10 lg:h-12 my-2 lg:my-5">
              <img src={`${xicon}`} className={imageClassName} alt={title}  />
          </div> 
          }
          
          <div className={`flex items-center ${titleClass}`}>  
          <div className="text-secondary text-10px lg:text-sm font-bold uppercase">{title}</div>
              <ArrowRightIcon  cname="h-[11px] w-3 lg:w-4 lg:h-4 ml-auto" fill="#1c3557" />
          </div>

          </a>
        )
      }
 
 </>

      
  )
}

export default CardButtonNew