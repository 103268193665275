import React from "react";
import { Link } from "react-router-dom";

const PathProfile = () => {
  return (
    <>
<div className="max-w-md w-full m-auto px-8  mt-7">
        <header className="mb-5">
            <h2 className="font-playfair text-secondary font-bold text-2xl mb-2">
            investor profile
            </h2>
            <p className='text-secondary text-xs'>Answer the following questions to receive a 
recommendation on the investment path that best 
fits your profile. Click Skip below if you prefer to not 
receive a recommendation.
</p>
        </header>
        <form className="" action="#">
         

      

             

            

            <div className="mb-5 mt-7">
              <span className="text-xs text-secondary block mb-5">
              How interested are you in having your HSA investments 
automatically managed for you?
              </span>
              <ul className="bg-gray-light border-t-[6px] rounded-md border-cyan">
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                    <input type="checkbox" className="form-checkbox" />
                    <span className="ml-2 text-xs text-secondary">
                    Not Interested
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25  cursor-pointer">
                    <input type="checkbox" className="form-checkbox" />
                    <span className="ml-2 text-xs text-secondary">
                    Somewhat Interested
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25  cursor-pointer">
                    <input type="checkbox" className="form-checkbox" />
                    <span className="ml-2 text-xs text-secondary">
                    Moderately Interested
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25  cursor-pointer">
                    <input type="checkbox" className="form-checkbox" />
                    <span className="ml-2 text-xs text-secondary">
                    Very Interested
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center w-full p-4 cursor-pointer">
                    <input type="checkbox" className="form-checkbox" />
                    <span className="ml-2 text-xs text-secondary">
                    Extremely Interested
                    </span>
                  </label>
                </li>
              </ul>
            </div>

            <div className="mb-5 mt-7">
              <span className="text-xs text-secondary block mb-5">
              What is your experience level investing in the stock and 
bond markets?


              </span>
              <ul className="bg-gray-light border-t-6 rounded-md border-cyan">
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                    <input type="checkbox" className="form-checkbox" />
                    <span className="ml-2 text-xs text-secondary">
                    First Time
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25  cursor-pointer">
                    <input type="checkbox" className="form-checkbox" />
                    <span className="ml-2 text-xs text-secondary">
                    Beginner
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25  cursor-pointer">
                    <input type="checkbox" className="form-checkbox" />
                    <span className="ml-2 text-xs text-secondary">
                    Intermediate
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25  cursor-pointer">
                    <input type="checkbox" className="form-checkbox" />
                    <span className="ml-2 text-xs text-secondary">
                    Advanced
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center w-full p-4  cursor-pointer">
                    <input type="checkbox" className="form-checkbox" />
                    <span className="ml-2 text-xs text-secondary">
                    Expert
                    </span>
                  </label>
                </li>
              </ul>
            </div>


            <div className="mb-5 mt-7">
              <span className="text-xs text-secondary block mb-5">
              Select all account types you have experience investing 
with:

              </span>
              <ul className="bg-gray-light border-t-6 rounded-md border-cyan">
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                    <input type="checkbox" className="form-checkbox" />
                    <span className="ml-2 text-xs text-secondary">
                    401k / 403b
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                    <input type="checkbox" className="form-checkbox" />
                    <span className="ml-2 text-xs text-secondary">
                    HSA
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                    <input type="checkbox" className="form-checkbox" />
                    <span className="ml-2 text-xs text-secondary">
                    IRA
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                    <input type="checkbox" className="form-checkbox" />
                    <span className="ml-2 text-xs text-secondary">
                    Brokerage
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center w-full p-4  cursor-pointer">
                    <input type="checkbox" className="form-checkbox" />
                    <span className="ml-2 text-xs text-secondary">
                    Other
                    </span>
                  </label>
                </li>
              </ul>
            </div>
          
 

          <div className="text-center mt-7 mb-5"><Link to="#" className='btn uppercase'>CONTINUE</Link></div>
          <div className='text-center'><Link to="#" className='text-xs font-bold text-secondary uppercase'>SKIP</Link></div>
            
        </form>
        </div>
    </>
  )
}

export default PathProfile