import React from 'react'
import { Link } from 'react-router-dom'
import ArrowRightIcon from '../Icons/ArrowRightIcon'

const ActionCardButton = ({title, url, href, linktitle, hidden, icon, iconimage, iconcname, ...otherProps}) => {
  let cxname
  if(hidden === "mobile" ) {
    cxname = "hidden lg:flex";
  } else if (hidden === "desktop" ) {
    cxname = "lg:hidden";
  } else  {
    cxname = "";
  }

  return (
    <>
        
        {
url ? (
<Link  className={`bg-gray-light w-full border-t-6 border-cyan rounded-md h-[103px] lg:h-[150px] my-5 flex flex-col justify-between ${cxname}`}  to={url} >
            {title.length > 0 &&
              <div className='font-playfair text-secondary text-lg lg:text-22px px-4 pt-2 pb-3'>
                  {title}
              </div>
            }

 
                {icon && 
                <div className="flex justify-left items-left p-4">
                  <img src={iconimage} className={iconcname} alt="" />
                </div>}
 

            <div className="flex items-center w-full p-4 text-xs text-secondary">
                <div className="text-secondary text-10px lg:text-sm font-bold uppercase">{linktitle}</div>
                <div className="ml-auto"><ArrowRightIcon  fill="#1c3557" cname="h-[11px] w-3 lg:w-4 lg:h-4" /></div>
            </div>
        </Link>
) : (
  <a href={href} className={`bg-gray-light w-full border-t-6 border-cyan rounded-md h-[103px] lg:h-[150px] my-5 flex flex-col justify-between ${cxname}`} {...otherProps}>
    {title.length > 0 &&
              <div className='font-playfair text-secondary text-lg lg:text-22px px-4 pt-2 pb-3'>
                  {title}
              </div>
            }

 
                {icon && 
                <div className="flex justify-left items-left p-4">
                  <img src={iconimage} className={iconcname} alt="" />
                </div>}
 

            <div className="flex items-center w-full p-4 text-xs text-secondary">
                <div className="text-secondary text-10px lg:text-sm font-bold uppercase">{linktitle}</div>
                <div className="ml-auto"><ArrowRightIcon  fill="#1c3557" cname="h-[11px] w-3 lg:w-4 lg:h-4" /></div>
            </div>
  </a>
)
}  
        
        
    </>
  )
}

export default ActionCardButton