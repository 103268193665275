import React, {  useState } from 'react'
import { Link } from "react-router-dom";
import { Disclosure } from '@headlessui/react'

import SubHeaderUser from '../../components/SubHeaderUser';
import ArrowRightIcon from '../../components/Icons/ArrowRightIcon';

import { ChevronRightIcon, } from '@heroicons/react/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faReceipt,
  faCircleExclamation,
  faPenToSquare,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons'

import ErrorModal from '../../components/UI/ErrorModal';
import Dropzone from '../../components/Dropzone';
import ActionCardButton from '../../components/Button/ActionCardButton';
import proofofcareimg from "../../assets/images/IMG_9121.png";

const ReceiptUploadedGeneratedNewExpens = () => {

  const [isOpen, setIsOpen] = useState(false);

  function openModal(event, modalData){
    setIsOpen({
      title: modalData.title,
      subtitle: modalData.subtitle,
      message: modalData.message,
      image : modalData.image
    });
    return;
  }

  const popupHandler = () => {
    setIsOpen(null);
  };

  const modalObj = {
    title: 'reimbusrsment status',
    message: '<div className="my-4 px-6 lg:text-sm"><p className="text-sm  font-bold mb-2">What this means:</p><p>The status of your reimbursement is defined by the following:</p><p><strong>Upload Receipt:</strong> To receive reimbursement  or applied benefits for an eligible expense, you must upload an itemized receipt under “Proof of Care.” Once you’ve submitted your itemized receipt, this step is considered “done.”</p><p><strong>In Review:</strong> Once Start has approved the expense and reviewed your itemized receipt and determined the final reimbursable amount for care, this step is considered “done.”</p><p><strong>Compete:</strong> When the reimbursable amount for care has been applied, this step is considered “done.”</p><p><strong>Note:</strong> If the expense is denied because it is not an eligible expense under your policy, the reimbursement status will be considered “complete” and an indicator that your  expense was denied will be shown for the expense in your dashboard.</p></div>'
  }

  const modalObj1 = {
    title: 'proof of care',
    message: '<div className="my-4 px-6 lg:text-sm"><p className="text-sm font-bold mb-2">What you need to provide:</p><p>To receive your benefits, please provide the following information in your proof of care no later than 30 days after receiving care.</p><ul><li>Patient’s full name</li><li>Provider’s name and address</li><li>Date(s) of service</li><li>Charge amount(s)</li><li>CPT / Procedure code(s) </li><li>Prescription drug(s) name, form, strength and dosage or NDC code.</li><li>Diagnosis code(s)</li></ul></div>'
  }

  const modalObjImg = {
    title: 'File #XXXX',
    subtitle: 'uploaded MM/DD/YY',
    message: '',
    image : proofofcareimg
  }

  // File upload
  const [file, setFile] = useState(null)


  return (
    <>
        <SubHeaderUser />
        <header className='container px-30px 2xl:px-0 lg:mt-12 mb-5 lg:mb-8 mt-5 flex'>
          <div className='text-left'>
            <h2 className='font-playfair text-secondary font-bold text-2xl lg:text-38px mb-1'>expense #XXXX</h2>
            <p className='text-secondary text-xs font-bold lg:text-base'>MEMBER: JANE DOE</p>
            <p className='text-secondary text-6px lg:text-xs font-bold'>MM/DD/YY</p>
          </div>
        </header>

        <div className='container px-30px 2xl:px-0'>
            <div className='lg:grid lg:grid-cols-12 lg:gap-16'>
                <div className='lg:col-span-6 pb-10'>
                <ActionCardButton title="payment" url="/manage-start-card" linktitle="PAYMENT METHOD: JANE DOE’S ACCT." />

                    

      <Disclosure defaultOpen="true">
      {({ open }) => (
      <div className="card">
      <div className="card-title flex"> <h4 className='lg:text-22px flex items-center'>reimbursement status 
      <FontAwesomeIcon icon={faCircleExclamation} className="text-secondary text-xs ml-2 cursor-pointer" onClick={(e) => {openModal(e, modalObj) }} /></h4>


      {isOpen && (
      <ErrorModal
      title={isOpen.title}
      subtitle={isOpen.subtitle}
      message={isOpen.message}
      image ={isOpen.image}
      onConfirm={popupHandler}
      />
      )}

      <Disclosure.Button className="ml-auto">
      <ChevronRightIcon className={`${ open ? 'transform rotate-90' : ''} h-5 w-5 lg:h-8 lg:w-8 text-secondary`} />
      </Disclosure.Button>
      </div>

      <Disclosure.Panel className="text-gray-500">
      <ul className="list-group">
      <li className="">
      <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
      <input type="checkbox" className="form-checkbox" checked />
      <span className="ml-2 text-xs lg:text-base text-secondary">
      Upload Receipt
      </span>
      </label>
      </li>
      <li className="">
      <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
      <input type="checkbox" className="form-checkbox" />
      <span className="ml-2 text-xs lg:text-base text-secondary">
      In Review
      </span>
      </label>
      </li>
      <li className="">
      <label className="inline-flex items-center w-full p-4 border-b-2 border-transparent  border-opacity-25 cursor-pointer">
      <input type="checkbox" className="form-checkbox"  />
      <span className="ml-2 text-xs lg:text-base text-secondary">
      Completed
      </span>
      </label>
      </li>
      </ul> 
      </Disclosure.Panel>
      {/**/}
      </div>
      )}
      </Disclosure>
        
      <Disclosure defaultOpen="true">
      {({ open }) => (
      <div className="card">
      <div className="card-title flex"> <h4 className='lg:text-22px flex items-center'>proof of care <FontAwesomeIcon icon={faCircleExclamation} className="text-secondary text-xs ml-2 cursor-pointer" onClick={(e) => {openModal(e, modalObj1) }} /></h4>
      <Disclosure.Button className="ml-auto">
      <ChevronRightIcon className={`${ open ? 'transform rotate-90' : ''} h-5 w-5 lg:h-8 lg:w-8 text-secondary`} />
      </Disclosure.Button>
      </div>

      <Disclosure.Panel className="text-gray-500">
      <ul className="list-group">
      <li className='flex items-center w-full px-4 py-4 border-b-2 border-secondary border-opacity-25'>
      <div className='mr-5'><FontAwesomeIcon icon={faReceipt} className="text-secondary text-lg" /></div>
      <div className="text-xs lg:text-base"><Link to="#" onClick={(e) => {openModal(e, modalObjImg) }}>File #XXXX</Link></div>
      <div className="ml-auto">
          <Link to="#" className='mr-3'><FontAwesomeIcon icon={faPenToSquare} className="text-secondary text-xs" /></Link>
          <Link to="#"><FontAwesomeIcon icon={faTrashCan} className="text-secondary text-xs" /></Link>
      </div>
      </li>
      <li className='flex items-center w-full px-4 py-4 border-b-2 border-secondary border-opacity-25'>
      <div className='mr-5'><FontAwesomeIcon icon={faReceipt} className="text-secondary text-lg" /></div>
      <div className="text-xs lg:text-base"><Link to="#" onClick={(e) => {openModal(e, modalObjImg) }}>File #XXXX</Link></div>
      <div className="ml-auto">
          <Link to="#" className='mr-3'><FontAwesomeIcon icon={faPenToSquare} className="text-secondary text-xs" /></Link>
          <Link to="#"><FontAwesomeIcon icon={faTrashCan} className="text-secondary text-xs" /></Link>
      </div>
      </li>

      <li className='px-4 py-3'>
      <Link to="#" className="btn uppercase w-full flex justify-center items-center cursor-pointer bg-primary">
          SUBMIT PROOF
          <ArrowRightIcon fill="#ffffff" cname="h-[11px] w-3 lg:w-4 lg:h-4 ml-auto" />
        </Link>
      </li>
      </ul> 
      </Disclosure.Panel>
      {/**/}
      </div>
      )}
      </Disclosure>
        
      <Disclosure defaultOpen="true">
      {({ open }) => (
      <div className="card">
      <div className="card-title flex"> <h4 className='lg:text-22px flex items-center'>activity <FontAwesomeIcon icon={faCircleExclamation} className="text-secondary text-xs ml-2 cursor-pointer" onClick={(e) => {openModal(e, modalObj1) }} /></h4>
      <Disclosure.Button className="ml-auto">
      <ChevronRightIcon className={`${ open ? 'transform rotate-90' : ''} h-5 w-5 lg:h-8 lg:w-8 text-secondary`} />
      </Disclosure.Button>
      </div>

      <Disclosure.Panel className="text-gray-500">
      <ul className="list-group"> 
        <li className='flex w-full px-4 py-4 border-b-2 border-secondary border-opacity-25 cursor-pointer text-xs lg:text-lg text-secondary'>
          <div className='font-bold'>MM/DD/YY</div>
          <div className="ml-3 lg:text-base">Proof of Care Submitted</div>
          <div className="ml-auto text-green font-bold"></div>
        </li>
        <li className='flex w-full px-4 py-4 border-b-2 border-secondary border-opacity-25 cursor-pointer text-xs lg:text-lg text-secondary'>
          <div className='font-bold'>MM/DD/YY</div>
          <div className="ml-3 lg:text-base">Proof of Care Submitted</div>
          <div className="ml-auto text-green font-bold"></div>
        </li>
        <li className='flex w-full px-4 py-4 border-b-0 border-secondary border-opacity-25 cursor-pointer text-xs lg:text-lg text-secondary'>
          <div className='font-bold'>MM/DD/YY</div>
          <div className="ml-3 lg:text-base">Proof of Care Submitted</div>
          <div className="ml-auto text-green font-bold"></div>
        </li>
      </ul>
      </Disclosure.Panel>
      {/**/}
      </div>
      )}
      </Disclosure>


      

                </div>{/* END */}
                <div className='lg:col-span-6 hidden lg:block'>
                    <div className='border-2 border-secondary p-8 rounded-md border-opacity-50 bg-gray-light'>
                          <h3 className='font-playfair text-secondary font-bold text-22px mb-2'>add proof of care <FontAwesomeIcon icon={faCircleExclamation} className="text-red text-xs ml-1 cursor-pointer" /></h3>
                          <p className='text-secondary text-lg leading-6'>To receive your benefits, please provide the following your proof 
of care no later than 30 days after receiving care. </p>
        <Dropzone setFile={setFile} />
        {file?.name}
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default ReceiptUploadedGeneratedNewExpens