import React from 'react'

const ArrowRightIcon = (props) => {
  return (
    <>
        <svg xmlns="http://www.w3.org/2000/svg"  className={`box ${props.cname}`} viewBox="0 0 15.993 16.529">
        <path id="arrow-right" d="M7.786,34.717l-.707.739a.463.463,0,0,0,0,.633l5.538,5.79H.428A.438.438,0,0,0,0,42.327v1.045a.438.438,0,0,0,.428.448H12.617l-5.538,5.79a.463.463,0,0,0,0,.633l.707.739a.415.415,0,0,0,.606,0l7.476-7.816a.463.463,0,0,0,0-.633L8.392,34.717A.415.415,0,0,0,7.786,34.717Z" transform="translate(0 -34.586)" fill={props.fill} />
        </svg>
    </>
  )
}

export default ArrowRightIcon