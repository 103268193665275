import React, { useEffect, useState, useRef } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight
} from '@fortawesome/free-solid-svg-icons'

// import Swiper core and required modules
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

import ErrorMessage from '../../components/ErrorMessage';

import Logo from '../../assets/images/logo-w.png'
import Logo2x from '../../assets/images/logo-w@2x.png'

import SliderD1 from '../../assets/images/slider-d-1.jpg'
import SliderD2 from '../../assets/images/slider-d-2.jpg'
import SliderD3 from '../../assets/images/slider-d-3.jpg'

import SliderM1 from '../../assets/images/slider-m-1.jpg'
import SliderM2 from '../../assets/images/slider-m-2.jpg'
import SliderM3 from '../../assets/images/slider-m-3.jpg'
import SliderM4 from '../../assets/images/slider-m-2.jpg'
import SliderM5 from '../../assets/images/slider-m-3.jpg'


const SplashScreen = () => {
  const [enteredEmail, setEnteredEmail] = useState('');
  const [enteredPassword, setEnteredPassword] = useState('');
  const [error, setError] = useState();

  const navigateurl  = useNavigate(); 
  const emailRef = useRef();
  const passwordRef = useRef();

  const loginObj = {
    email : 'codechk@gmail.com',
    password : '123456'
  }

  const url = '/dashboard';

  const submitHandler = (event) => {
    event.preventDefault();
    setError(null);

    if (!enteredEmail.includes('@') || enteredEmail.trim().length === 0) {
      setError({
        message: 'Please enter a valid Email Address.'
      });

      emailRef.current.focus();
      return;
    }

    if (enteredPassword.trim().length < 6) {
      setError({
        message: 'Please enter a password minimum of 6 Letters.'
      });
      passwordRef.current.focus();
      return;
    }

    if(enteredEmail === loginObj.email && enteredPassword === loginObj.password){
      navigateurl(url);
    } else {
      setError({
        message: 'Your email or password is incorrect. You may try again or click "Forgot Email or Password."'
      });
      return;
    }
    
  }

  const emailChangeHandler = (event) => {
    setError(null);
    setEnteredEmail(event.target.value);
    
  }

  const passwordChangeHandler = (event) => {
    setError(null);
    setEnteredPassword(event.target.value);
  }
  return (
    <>
      <div className='h-screen lg:flex relative hidden'>
        <Swiper
          // install Swiper modules
          className="hero-area"
          modules={[Autoplay]}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
          }}
          speed={2}
          slidesPerView={1}
          loop={true}
          onSlideChange={(swiperCore) => {
            const {
              activeIndex,
              previousIndex
            } = swiperCore;
            let element = document.getElementById("desktopSlider");
            element.classList.remove("sliderindex"+previousIndex);
            element.classList.add("sliderindex"+activeIndex);
        }}
        >

          <SwiperSlide>
            <div className='relative w-full h-full bg-secondary'>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='relative w-full h-full'>
              <img src={SliderD1} alt="" className='heroImage' />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='relative w-full h-full'>
              <img src={SliderD2} alt="" className='heroImage' />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='relative w-full h-full'>
              <img src={SliderD3} alt="" className='heroImage' />
            </div>
          </SwiperSlide>

        </Swiper>
        <div className="absolute h-full w-full z-10 overflow-hidden" id="desktopSlider">
          <div className="flex min-h-full items-center justify-center">
            <div className="max-w-md w-full ">
              <div className="text-center">
                <img src={Logo} srcSet={`${Logo2x} 2x`} alt="starthealth" className="mx-auto" />
              </div>

              <div className="text-center mt-6">
            {error && (
              <ErrorMessage
                message={error.message}
              />
            )}
          </div>

              <form className="mt-8 space-y-6">
                <label className="block">
                  {/* <input type="text" className="form-control bg-white/[0.8] border-1 border-secondary" placeholder="Email" /> */}
                  <input type="email" className="form-control bg-white/[0.8] border-1 border-secondary" placeholder="Email" ref={emailRef} onChange={emailChangeHandler} />
                </label>

                <label className="block">
                  {/* <input type="password" className="form-control bg-white/[0.8] border-1 border-secondary" placeholder="Password" /> */}
                  <input type="password" className="form-control bg-white/[0.8] border-1 border-secondary" placeholder="Password" ref={passwordRef} onChange={passwordChangeHandler} />
                </label>

                <div className="mt-1 text-center">
                  <Link to="/forgot-password" className="text-xs lg:text-lg font-bold text-white">Forgot Email or Password</Link>
                </div>

                <div className="flex justify-between mt-4">
                  {/* <button className="btn uppercase">Login</button> */}
                  <button className="btn uppercase" onClick={submitHandler}>Login</button>
                </div>
              </form>

              <div className="text-center mt-40 anAccount">
                <h4 className='text-lg font-bold text-white mb-3'>Don’t have an account?</h4>
                <Link to="/enter-your-email" className="text-base	font-bold text-cyan uppercase ">SIGN UP
                <svg xmlns="http://www.w3.org/2000/svg" className="w-[10.98px] h-[10.85px] inline ml-1.5"  viewBox="0 0 10.85 10.977">
<path id="arrow-right" className="w-4 h-4 text-slate-50" d="M5.3,0.1L4.9,0.6C4.7,0.7,4.7,0.9,4.9,1c0,0,0,0,0,0l3.8,3.8H0.3C0.1,4.8,0,4.9,0,5.1  c0,0,0,0,0,0v0.7c0,0.2,0.1,0.3,0.3,0.3h8.4L4.9,9.9c-0.1,0.1-0.1,0.3,0,0.4c0,0,0,0,0,0l0.5,0.5c0.1,0.1,0.3,0.1,0.4,0c0,0,0,0,0,0  l5.1-5.1c0.1-0.1,0.1-0.3,0-0.4c0,0,0,0,0,0L5.8,0.1C5.6,0,5.5,0,5.3,0.1C5.3,0.1,5.3,0.1,5.3,0.1z"  />
</svg>
                </Link>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="h-screen flex relative lg:hidden show-mobile">
        <Swiper
          // install Swiper modules
          className="hero-area"
          modules={[Autoplay]}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
          }}
          speed={2}
          slidesPerView={1}
          loop={true}
        //   onSlideChange={(swiperCore) => {
        //     const {
        //       activeIndex,
        //       previousIndex,
        //     } = swiperCore;
        //     let element = document.getElementById("mobileSlider");
        //     element.classList.remove("sliderindex"+previousIndex);
        //     element.classList.add("sliderindex"+activeIndex);
        // }}
        >
          <SwiperSlide>
            <div className='relative w-full h-full bg-secondary'>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='relative w-full h-full'>
              <span className=''><img src={SliderM1} alt="" className='heroImage' /></span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='relative w-full h-full'>
              <span className=''><img src={SliderM2} alt="" className='heroImage' /></span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='relative w-full h-full'>
              <span className=''><img src={SliderM3} alt="" className='heroImage' /></span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='relative w-full h-full'>
              <span className=''><img src={SliderM4} alt="" className='heroImage' /></span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='relative w-full h-full'>
              <span className=''><img src={SliderM5} alt="" className='heroImage' /></span>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className="absolute  h-full w-full z-10 overflow-hidden ">
          <div className="min-h-full flex flex-wrap">
            <div className='w-full absolute top-1/2 transform -translate-y-1/2'>
              <img className='mx-auto' src={Logo} alt="" />
            </div>
            <div className="w-full self-end text-center pb-12">
              <h3 className='text-white text-lg font-bold mb-3'>Power up your health coverage.</h3>
              <Link to="/login" className="text-base	font-bold text-white uppercase ">Login  
               <FontAwesomeIcon icon={faArrowRight} className="text-white w-2.5 ml-1" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}

export default SplashScreen