import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faUser } from '@fortawesome/free-solid-svg-icons'
import Logo from '../../assets/images/logo-icon.png'
import Logo2x from '../../assets/images/logo-icon@2x.png'

const Header = () => {
  const [toggle, setToggle] = useState(false);

  const hideToggle = () => {
    setToggle(false);
  }

  return (
    <>
    <header className="bg-secondary text-white border-b border-gray-200/25 z-30">
      <div className="container px-30px 2xl:px-0">
      <div className=" mx-auto flex flex-wrap  py-5 flex-row items-center">
        <div className="flex w-2/5 flex-wrap items-center text-base">
          <button onClick={() => setToggle(!toggle)} className="xl:hidden flex items-center text-gray-500 hover:text-gray-600">
          <FontAwesomeIcon icon={faBars} className="text-cyan text-base" />
            <span className="text-xs uppercase text-white font-bold ml-2">Menu</span>
          </button>

        <ul className="xl:flex hidden">
          <li><NavLink to="/dashboard" className={({ isActive }) => isActive ? "mr-5 text-cyan text-xs font-bold uppercase" : "text-xs font-bold uppercase mr-5"}>Home</NavLink></li>
          <li><NavLink to="/hsa-summary" className={({ isActive }) => isActive ? "mr-5 text-cyan text-xs font-bold uppercase" : "text-xs font-bold uppercase mr-5"}>HSA SUMMARY</NavLink></li>
          <li><NavLink to="/find-care" className={({ isActive }) => isActive ? "mr-5 text-cyan text-xs font-bold uppercase" : "text-xs font-bold uppercase mr-5"}>FIND CARE</NavLink></li>
          <li><NavLink to="/prescriptions" className={({ isActive }) => isActive ? "mr-5 text-cyan text-xs font-bold uppercase" : "text-xs font-bold uppercase mr-5"}>PRESCRIPTIONS</NavLink></li>
          <li><NavLink to="/expenses" className={({ isActive }) => isActive ? "mr-5 text-cyan text-xs font-bold uppercase" : "text-xs font-bold uppercase mr-5"}>EXPENSES</NavLink></li>
        </ul>
         
        </div>

        <Link className="flex w-1/5 title-font font-medium items-center text-gray-900 justify-center" to="/dashboard">
        <img src={Logo} srcSet={`${Logo2x} 2x`} alt="starthealth" className="mx-auto" />
        </Link>

        <div className="w-2/5 inline-flex justify-end ml-0 items-center">
          <Link to="/" className="font-bold text-sm mr-6">LOGOUT</Link>
          <Link to="/account"><FontAwesomeIcon icon={faUser} className="text-white text-base" /></Link>
        </div>
      </div>

      </div>
     
    </header>
    {toggle && (
        <div>
    <nav className="menu absolute w-full bg-gray-light border-b-6 border-cyan z-10">
        <ul>
          <li><Link to="/dashboard" onClick={hideToggle} className="inline-flex items-center w-full p-4 px-8 border-b-2 border-secondary border-opacity-25 text-xs text-secondary font-bold uppercase">Home</Link></li>
          <li><Link to="/hsa-summary" onClick={hideToggle} className="inline-flex items-center w-full p-4 px-8 border-b-2 border-secondary border-opacity-25 text-xs text-secondary font-bold uppercase">HSA SUMMARY</Link></li>
          <li><Link to="/find-care" onClick={hideToggle} className="inline-flex items-center w-full p-4 px-8 border-b-2 border-secondary border-opacity-25 text-xs text-secondary font-bold uppercase">FIND CARE</Link></li>
          <li><Link to="/prescriptions" onClick={hideToggle} className="inline-flex items-center w-full p-4 px-8 border-b-2 border-secondary border-opacity-25 text-xs text-secondary font-bold uppercase">PRESCRIPTIONS</Link></li>
          <li><Link to="/expenses" onClick={hideToggle} className="inline-flex items-center w-full p-4 px-8 border-b-2 border-secondary border-opacity-25 text-xs text-secondary font-bold uppercase">EXPENSES</Link></li>
          <li><Link to="/account-settings" onClick={hideToggle} className="inline-flex items-center w-full p-4 px-8 border-b-2 border-secondary border-opacity-25 text-xs text-secondary font-bold uppercase">SETTINGS</Link></li>
          <li><Link to="/" onClick={hideToggle} className="inline-flex items-center w-full p-4 px-8  text-xs text-secondary font-bold uppercase">LOG OUT</Link></li>
        </ul>
    </nav>
          <div className="absolute w-full h-full bg-gray-light/75" onClick={hideToggle} ></div>
      </div>
     )}
    </>
  );
};
export default Header;