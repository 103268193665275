import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleExclamation
} from '@fortawesome/free-solid-svg-icons'

import { Disclosure } from '@headlessui/react'
import { ChevronRightIcon, } from '@heroicons/react/solid'


const SelectReimbursementPreference = () => {

  const [checked, setChecked] = useState(true);
  

  useEffect(() => {
    document.title = 'Reimbursement Preference';
  }, []);


  //const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="h-full lg:h-auto lg:min-h-[calc(100vh_-_107px)] flex lg:justify-between lg:items-center">
        <div className="h-full flex flex-col justify-between items-center lg:h-auto w-full pt-12 lg:py-0 px-8 lg:px-0 min-h-[calc(100vh_-_60px)] lg:min-h-full">
        <div className="max-w-627px mx-auto w-full">
        <div className="text-center mb-5 lg:mb-7">
            <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-2">
            set your refund preference
            </h2>
            <p className='text-cyan text-xs font-bold lg:text-lg'>Please select your preferred reimbursement method.</p>
        </div>
        <div className="">
            
        
              <div className="card">
                <div className="card-title flex"><h4 className='lg:text-22px flex items-center'>reimbursement preferences 
                  <FontAwesomeIcon icon={faCircleExclamation} className="text-secondary text-xs ml-2 cursor-pointer"  /></h4>
  
                   
  
                  
                </div>
  
                
                <ul className="list-group">
      <li className="">
      <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
      
        <input type="radio" className=' form-radio' name='SetYourRefund' value="option1" defaultChecked={checked} onChange={() => setChecked(!checked)} />
        <span className="ml-3 text-xs lg:text-base text-secondary">HSA Contribution      </span>
      </label>
      </li>
      <li className="">
      <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
      <input type="radio" className='form-radio' name='SetYourRefund' value="option2" onChange={() => setChecked(!checked)} />
        <span className="ml-3 text-xs lg:text-base text-secondary">Premium Credit</span>
       
      </label>
      </li>
      <li className="">
        <label className="inline-flex items-center w-full p-4 border-b-2 border-transparent border-opacity-25 cursor-pointer">
          
          <input type="radio" className='form-radio' name='SetYourRefund' value="option3" onChange={() => setChecked(!checked)}  />
          <span className="ml-3 text-xs lg:text-base text-secondary" >Check in the Mail</span>
        </label>
      </li>
      </ul>
               
                {/**/}
              </div>
         
  
              <div className="text-center mt-7 mb-5"><Link to="#" className='btn  uppercase'>Continue</Link></div>
              <div className='text-center'><Link to="/open-start-hsa" className='text-xs font-bold text-secondary uppercase'>Go Back</Link></div>
  
            </div>
  
        </div>
         

       

        
        </div>
      </div>
    </>
  )
}

export default SelectReimbursementPreference