import React from 'react'
import { Link } from "react-router-dom";

const Mailto = ({ mailto, label }) => {
  return (
    <>
        <Link
            to='javascript:void(0)'
            onClick={(e) => {
                window.location.href = mailto;
                e.preventDefault();
            }}
        >
            {label}
        </Link>
    </>
  )
}

export default Mailto