import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import CustomSelect from "../../../components/Select";
import Input from "../../../components/Input";

const AddInitialMember = ({onNext}) => {

   const [checked, setChecked] = useState(true);

  const options = [
    { value: 'Male', label: 'Male' },
    { value: 'female', label: 'Female' },
  ];

  const HeightOptions = [
    { value: '5', label: '5' },
    { value: '6', label: '6' },
  ];

  const [disable, setDisable] = useState(true);

  const navigateurl  = useNavigate(); 

  const url = '/select-your-quote-for-individual';

  function handleChange(event) {
    setDisable(event.target.value === "");
    console.log("new value", event.target.value);
  }

  const submitHandler = (event) => {
    navigateurl(url);
  }

  return (
    <>
      <div className="h-full lg:h-auto lg:min-h-[calc(100vh_-_107px)] flex lg:justify-between lg:items-center">
        <div className="h-full flex flex-col justify-between items-center lg:h-auto w-full pt-12 lg:px-0 min-h-[calc(100vh_-_60px)] lg:min-h-full">
        <div className="container px-30px 2xl:px-0">
        <div className="text-center mb-5 lg:mb-12">
            <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-2">we’d like to get to know you.</h2>
            <p className='text-cyan text-xs font-bold lg:text-lg'>Please answer the questions below.</p>
        </div>
        <div className="lg:grid lg:grid-cols-12 lg:gap-16">
        
        <div className="lg:col-span-6 mb-6">

        <div className="flex mb-3 space-x-3">
              <div className="w-1/2">
                <Input
                  className="form-control"
                  placeholder="First Name"
                  type="text"
                  id="fname"
                />
              </div>
              <div className="w-1/2">
                <Input
                  className="form-control"
                  placeholder="Last Name"
                  type="text"
                  id="lname"
                />
              </div>
            </div>

           

            <div className="flex mb-3 space-x-3">
              <div className="w-1/2">
                <Input 
                  className="form-control"
                  placeholder="Birthday"
                  type="text"
                  id="birthday"
                />
              </div>
              <div className="w-1/2">
              <CustomSelect phtext="Height" optionsval={HeightOptions} />
                
              </div>
            </div>

            <div className="flex mb-3 space-x-3">
              <div className="w-1/2">
              <Input 
                  className="form-control"
                  placeholder="Weight (lbs / BMI)"
                  type="text"
                  id="Weight"
                />
              </div>
              <div className="w-1/2">
              <CustomSelect phtext="Select Gender" optionsval={options} />
              </div>
            </div>

            <div className="mb-5 mt-6">
              <span className="text-xs lg:text-lg text-secondary block mb-2">
                Any tobacco use in the past 12 months?
              </span>
              <ul className="bg-gray-light border-t-6 rounded-md border-cyan">
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                    <input type="radio" className='form-radio' name='anyTobacco' value="anyTobacco" defaultChecked={checked} onChange={() => setChecked(!checked)}  />
                    <span className="ml-4 text-xs lg:text-base text-secondary">
                      Yes
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-transparent border-opacity-25 cursor-pointer">
                    <input type="radio" className='form-radio' name='anyTobacco' value="anyTobacco2" defaultChecked={checked} onChange={() => setChecked(!checked)}  />
                    <span className="ml-4 text-xs lg:text-base text-secondary">
                      No
                    </span>
                  </label>
                </li>
              </ul>
            </div>

            <div className="mb-5 mt-6">
              <span className="text-xs lg:text-lg text-secondary block mb-2">
                Are you currently pregnant or have reason to suspect you might be pregnant?
              </span>
              <ul className="bg-gray-light border-t-6 rounded-md border-cyan">
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                  <input type="radio" className='form-radio' name='pregnant'  value="pregnant" defaultChecked={checked} onChange={() => setChecked(!checked)}   />
                    <span className="ml-4 text-xs lg:text-base text-secondary">
                     Yes
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-transparent border-opacity-25 cursor-pointer">
                  <input type="radio" className='form-radio' name='pregnant' value="pregnant2" defaultChecked={checked} onChange={() => setChecked(!checked)}  />
                    <span className="ml-4 text-xs lg:text-base text-secondary">
                      No
                    </span>
                  </label>
                </li>
              </ul>
            </div>

            <div className="mb-5 mt-6">
              <span className="text-xs lg:text-lg text-secondary block mb-2">
              In the past 24 months, have you been recommended to have, or been scheduled for, diagnostic testing, treatment, or surgery that has not been completed?
              </span>
              <ul className="bg-gray-light border-t-6 rounded-md border-cyan">
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                  <input type="radio" className='form-radio' name='testing' value="testing" defaultChecked={checked} onChange={() => setChecked(!checked)}  />
                    <span className="ml-4 text-xs lg:text-base text-secondary">
                     Yes
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-transparent border-opacity-25 cursor-pointer">
                  <input type="radio" className='form-radio' name='testing' value="testing2" defaultChecked={checked} onChange={() => setChecked(!checked)}  />
                    <span className="ml-4 text-xs lg:text-base text-secondary">
                      No
                    </span>
                  </label>
                </li>
              </ul>
            </div>

            <div className="mb-5 mt-6">
              <span className="text-xs lg:text-lg text-secondary block mb-2">
              Within the past 24 months, have you had a health related condition for which you have not sought medical advice or treatment?
              </span>
              <ul className="bg-gray-light border-t-6 rounded-md border-cyan">
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                  <input type="radio" className='form-radio' name='condition' value="condition" defaultChecked={checked} onChange={() => setChecked(!checked)}  />
                    <span className="ml-4 text-xs lg:text-base text-secondary">
                     Yes
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-transparent border-opacity-25 cursor-pointer">
                  <input type="radio" className='form-radio' name='condition' value="condition2" defaultChecked={checked} onChange={() => setChecked(!checked)}  />
                    <span className="ml-4 text-xs lg:text-base text-secondary">
                      No
                    </span>
                  </label>
                </li>
              </ul>
            </div>
            
            <div className="mb-5 mt-6">
              <span className="text-xs lg:text-lg text-secondary block mb-2">
              Within the past five years, have you received any abnormal test results, medical or surgical treatment, healthcare professional consultation, or prescribed medication for any of the following conditions?
              </span>
              <h3 className="text-secondary text-base font-bold py-3">Please check ALL that apply.</h3>
              <ul className="bg-gray-light border-t-6 rounded-md border-cyan">
                <li className="">
                  <label className="inline-flex w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                    <Input type="checkbox" className="form-checkbox mt-1" />
                    <span className="ml-4 text-xs lg:text-base text-secondary">
                    Arthritis, Reumatologic disorder or any disease or disorder of the joints, bones, muscles or back. (Check if any condition that has lasted more than one month or that you have been prescribed anything other than over the counter pain relievers.) 
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                    <Input type="checkbox" className="form-checkbox" />
                    <span className="ml-4 text-xs lg:text-base text-secondary">
                      AIDS or tested positive for HIV
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                    <Input type="checkbox" className="form-checkbox" />
                    <span className="ml-4 text-xs lg:text-base text-secondary">
                    Asthma, Emphysema, COPD, TB, or any other disease or disorder of the respiratory system
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex  w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                    <Input type="checkbox" className="form-checkbox mt-1" />
                    <span className="ml-4 text-xs lg:text-base text-secondary">
                    Cardiovascular disease or disorder of the heart, arteries, blood vessels or blood. (Check for any condition for which you were treated with any procedure or prescribed a medication other than statins.)
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center  w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                    <Input type="checkbox" className="form-checkbox" />
                    <span className="ml-4 text-xs lg:text-base text-secondary">
                    Cancer or tumor
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                    <Input type="checkbox" className="form-checkbox mt-1" />
                    <span className="ml-4 text-xs lg:text-base text-secondary">
                    Chemical dependency, drug or alcohol abuse, or any other mental health disease or disorder
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                    <Input type="checkbox" className="form-checkbox mt-1" />
                    <span className="ml-4 text-xs lg:text-base text-secondary">
                    Crohn's disease, ulcerative colitis, hepatitis or any other disorder of the liver, stomach, colon or intestines. (Check for any condition for which you received a treatment or prescribed a medication other than over the counter medications.
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                    <Input type="checkbox" className="form-checkbox" />
                    <span className="ml-4 text-xs lg:text-base text-secondary">
                    Diabetes or any other pancreas disorder
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center  w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                    <Input type="checkbox" className="form-checkbox" />
                    <span className="ml-4 text-xs lg:text-base text-secondary">
                    Immune system disease or disorder
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                    <Input type="checkbox" className="form-checkbox" />
                    <span className="ml-4 text-xs lg:text-base text-secondary">
                    Kidney disease or disorder
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                    <Input type="checkbox" className="form-checkbox" />
                    <span className="ml-4 text-xs lg:text-base text-secondary">
                    Neurological system disorder
                    </span>
                  </label>
                </li>
              <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-transparent border-opacity-25 cursor-pointer">
                    <Input type="checkbox" className="form-checkbox" />
                    <span className="ml-4 text-xs lg:text-base text-secondary">
                    Stroke
                    </span>
                  </label>
                </li>
                </ul>
            </div>

            <div className="mb-5 mt-6">
              <span className="text-xs lg:text-lg text-secondary block mb-2">
              I certify that the above information is true to the best of my knowledge. I understand that this may become part of an application for health insurance and is subject to the Utah code regarding such applications.
              </span>
              <ul className="bg-gray-light border-t-6 rounded-md border-cyan">
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-transparent border-opacity-25 cursor-pointer">
                  <Input type="radio" className="form-radio" onChange={handleChange} />
                    <span className="ml-2 text-xs lg:text-base text-secondary">
                    The above information is true.
                    </span>
                  </label>
                </li>

              </ul>
            </div>
 
 

            <Link
              to="/adding-family-members"
              className="btn uppercase w-full flex justify-center items-center cursor-pointer bg-cyan"
            >
              <FontAwesomeIcon
                icon={faPlus}
                className="text-white w-2.5 mr-2"
              />{" "}
              ADD A FAMILY MEMBER
              <FontAwesomeIcon
                icon={faArrowRight}
                className="text-white w-2.5 ml-auto"
              />
            </Link>

          </div>
          <div className="lg:col-span-6">
          <div className="bg-transparent lg:bg-gray-light border-0 lg:border-t-6 rounded-md border-cyan">
            <div className='text-lg leading-6 px-8 text-secondary pt-7 hidden lg:block'>
            I certify that all information completed on this form is true, correct and complete. I acknowledge that if any information provided is false, the insurer may without advance notice pursue any remedies available under state or federal law, including declaring the coverage null and void and canceling 
the coverage retroactive to its original effective date.
              </div>
          <div className="text-center mt-7 pb-5 lg:pb-8 px-8">
          <button className='btn uppercase w-full lg:flex lg:justify-center items-center cursor-pointer bg-primary disabled:opacity-75' disabled={disable} onClick={submitHandler}>
            GET QUOTE
              <FontAwesomeIcon
                icon={faArrowRight}
                className="text-white w-3 ml-auto hidden lg:inline-block"
              />
            </button>
          {/* <button className='btn uppercase w-full lg:flex lg:justify-center items-center cursor-pointer bg-primary disabled:opacity-75' disabled={disable} 
          onClick={onNext}
          >
            GET QUOTE
              <FontAwesomeIcon
                icon={faArrowRight}
                className="text-white w-3 ml-auto hidden lg:inline-block"
              />
            </button> */}
            
             
            </div>
  <div className='text-center mb-10 lg:mb-0'>
    {/* <Link to="/add-initial-member" className='text-xs font-bold text-secondary uppercase lg:hidden block'>Go Back</Link> */}
    
    </div>
          </div>
          </div>
          
        </div>
        </div>
         
      

        
        </div>
      </div>
    </>
  );
};

export default AddInitialMember;
