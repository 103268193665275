import React from "react";
import { Outlet } from "react-router-dom"
import Footer from "../components/Footer"
import Header from "../components/Header"

const AppLayout = () => {
  return <>
    <div className="wrapper relative min-h-screen">
    <Header />
    <main id="main" className="lg:pb-[48px]">
      <Outlet />
    </main>
    <Footer />
    </div>
  </>;
};
export default AppLayout;
