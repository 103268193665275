import React, { useState, useEffect, useRef } from 'react'
import Logo from '../../../assets/images/logo-blue.png';
import Logo2x from '../../../assets/images/logo-blue@2x.png';
import ErrorMessage from '../../../components/ErrorMessage';

const ForgotPassword = () => {

  useEffect(() => {
    document.title = 'Forget Password';
  }, []);

  const [enteredEmail, setEnteredEmail] = useState('');
  const [error, setError] = useState();

  const emailRef = useRef();

  const loginObj = {
    email: 'codechk@gmail.com'
  }

  const submitHandler = (event) => {
    event.preventDefault();
    setError(null);

    if (!enteredEmail.includes('@') || enteredEmail.trim().length === 0) {
      setError({
        message: 'Please enter a valid Email Address.'
      });

      emailRef.current.focus();
      return;
    }

    if (enteredEmail !== loginObj.email) {
      setError({
        message: 'The email you entered is not associated with a Start policy. Please try again, or call us.'
      });
      return;
    }

  }

  const emailChangeHandler = (event) => {
    setError(null);
    setEnteredEmail(event.target.value);

  }

  return (
    <>
      <div className="min-h-full flex  justify-center py-12 px-12 sm:px-6 lg:px-8">
        <div className="max-w-md w-full pt-40">

          <div className="text-center">
            <img src={Logo} srcSet={`${Logo2x} 2x`} alt="starthealth" className="mx-auto" />
          </div>
          <div className='text-xs text-secondary pt-5'>Please enter the email address associated with your Start policy. </div>

          <div className="text-center mt-6">
            {error && (
              <ErrorMessage
                message={error.message}
              />
            )}
          </div>

          <form className="mt-8 space-y-6" action="#">
            <label className="block">
              <input type="email" className="form-control" placeholder="Email" ref={emailRef} onChange={emailChangeHandler} />
            </label>
            <div className="flex justify-between mt-4">
              <button className="btn uppercase" onClick={submitHandler}>Submit</button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default ForgotPassword