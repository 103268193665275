import React from 'react'
import { Link } from "react-router-dom";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {
//   faGear,
// } from '@fortawesome/free-solid-svg-icons'
import ArrowRightIcon from '../../components/Icons/ArrowRightIcon';

const Account = () => {
  return (
    <>
       <div className='container px-30px 2xl:px-0'>
        <div className="text-left lg:mt-12 mb-5 mt-5">
          <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-1">account</h2>
          <p className="text-secondary text-xs font-bold lg:text-base">MEMBER: JANE DOE</p>
        </div>

        <div className="lg:grid lg:grid-cols-12 lg:gap-10">
          <div className="lg:col-span-6">
             

        
              <Link to="/account-details" className='card block mt-0'>
              <h4 className='card-title lg:text-22px lg:pb-3'>jane doe <div className='text-10px font-opensans lg:text-10px font-bold uppercase text-secondary'>PLAN ID: SHSAXXXXXX</div></h4>
              
              <div className='text-3xl font-bold text-primary px-4'></div>
              <div  className='card-footer'>
              <div className='font-bold text-10px lg:text-sm'>VIEW DETAILS</div>
              <div className='text-secondary ml-auto'>
              <ArrowRightIcon fill="#1c3557" cname="h-[11px] w-3 lg:w-4 lg:h-4 ml-auto mt-1" />
              </div>
              </div>
              </Link>
                {/* END .card */}

           
              <Link to="/manage-start-card" className='card block'>
              <h4 className='card-title  lg:text-22px lg:pb-10'>add bank account</h4>
              <div className='text-3xl font-bold text-primary px-4'></div>
              <div  className='card-footer'>
              <div className='font-bold text-10px lg:text-sm'>VIEW DETAILS</div>
              <div className='text-secondary ml-auto'>
              <ArrowRightIcon fill="#1c3557" cname="h-[11px] w-3 lg:w-4 lg:h-4 ml-auto mt-1" />
              </div>
              </div>
              </Link>
                {/* END .card */}


          </div>
           
        </div>
      </div>
     
    </>
  )
}

export default Account