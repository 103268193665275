import { Link } from "react-router-dom"

const SubHeader = () => {
    return (
        <>
        <div className="bg-secondary">
        <div className="container px-30px 2xl:px-0 py-8">
           <div className="flex text-white mb-4">
              <div className="mr-auto text-xs uppercase font-bold lg:text-base">HSA CONTRIBUTIONS</div>
              <div className="text-10px lg:text-sm font-bold uppercase">
              <Link to="/account-summary"  className="flex items-center">
                ACCOUNT SUMMARY
              
              <svg xmlns="http://www.w3.org/2000/svg" width="10.977" height="10.85" className="ml-2" viewBox="0 0 10.977 10.85"><path d="M5.344,34.672l-.485.485a.294.294,0,0,0,0,.416l3.8,3.8H.294A.294.294,0,0,0,0,39.668v.686a.294.294,0,0,0,.294.294H8.66l-3.8,3.8a.294.294,0,0,0,0,.416l.485.485a.294.294,0,0,0,.416,0l5.131-5.131a.294.294,0,0,0,0-.416L5.76,34.672A.294.294,0,0,0,5.344,34.672Z" transform="translate(0 -34.586)" fill="#fff"/></svg>
              </Link>
              </div>
           </div>

           <div className="w-full rounded-full bg-gray-200 h-2 lg:h-[22px] relative">
            <div className="bg-cyan rounded-full h-2 lg:h-[22px]" style={{width: "80%"}}></div>
          </div>

        <div className="mt-4 text-white text-xs lg:text-base"><strong>Contributions:</strong> $2,934 of $3,650</div>
        </div>
      </div>
        </>
      )
    };
export default SubHeader;