import React, {  useState } from 'react'
import { Link } from "react-router-dom";
import { Disclosure } from '@headlessui/react'

import Input from '../../components/Input';

import { ChevronRightIcon, } from '@heroicons/react/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faReceipt,
  faCircleExclamation,
  faPenToSquare,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons'

import ErrorModal from '../../components/UI/ErrorModal';
import Dropzone from '../../components/Dropzone';
import ActionCardButton from '../../components/Button/ActionCardButton';
import proofofcareimg from "../../assets/images/IMG_9121.png";

const PreApprovalForm = () => {

    const [isOpen, setIsOpen] = useState(false);

    function openModal(event, modalData){
      setIsOpen({
        title: modalData.title,
        subtitle: modalData.subtitle,
        message: modalData.message,
        image : modalData.image
      });
      return;
    }
  
    const popupHandler = () => {
      setIsOpen(null);
    };
  
    const modalObj1 = {
      title: 'pre-approval documents',
      message: '<div className="my-4 px-6 lg:text-sm"><p className="text-sm font-bold mb-2">Itemized Quote</p><p>Abcdefg</p><p className="text-sm font-bold mt-5  mb-2">Medical Records</p><p className="text-sm font-bold mb-2"><p>Abcdefg</p><p className="text-secondary mt-12 text-[10px]"><strong>QUESTIONS?</strong> call us at 801-XXX-XXXX.</p></div>'
    }
  
    // const modalObjImg = {
    //   title: 'File #XXXX',
    //   subtitle: 'uploaded MM/DD/YY',
    //   message: '',
    //   image : proofofcareimg
    // }
  
    // File upload
    //const [file, setFile] = useState(null)

  return (
    <>
    <header className='container px-30px 2xl:px-0 lg:mt-12 mb-5 mt-5 flex'>
     <div className="text-left">
       <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-1">pre-approval</h2>
       <p className='text-secondary text-xs lg:text-lg pt-3'>Work with your medical provider to obtain needed pre-approval documents. One obtained, complete the form below, upload documentation, and click submit</p> 
     </div>
  </header>

  <div className='container px-30px 2xl:px-0'>
  <div className="lg:grid lg:grid-cols-12 lg:gap-16">
       <div className="lg:col-span-6 pb-10">
       <form className="" action="#">
         
         <div className="mb-3">
            <div className="w-full">
                <Input type="text" className="form-control" placeholder="Procedure Name" />
             </div>
         </div>

         <div className="mb-3">
         <div className="w-full">
         <Input type="text" className="form-control" placeholder="Provider Name" />
             </div>
         </div>

         <div className="mb-3">
         <div className="w-full">
         <Input type="text" className="form-control" placeholder="Expected Procedure Date (MM/DD/YY)" />
             </div>
         </div>
         
         {/*  */}


<Disclosure defaultOpen="true">
{({ open }) => (
<div className="card">
<div className="card-title flex"> <h4 className='lg:text-22px flex items-center'>pre-approval documents <FontAwesomeIcon icon={faCircleExclamation} className="text-secondary text-xs ml-2 cursor-pointer" onClick={(e) => {openModal(e, modalObj1) }} /></h4>
{isOpen && (
      <ErrorModal
      title={isOpen.title}
      subtitle={isOpen.subtitle}
      message={isOpen.message}
      image ={isOpen.image}
      onConfirm={popupHandler}
      />
      )}

<Disclosure.Button className="ml-auto">
<ChevronRightIcon className={`${ open ? 'transform rotate-90' : ''} h-5 w-5 lg:h-8 lg:w-8 text-secondary`} />
</Disclosure.Button>
</div>

<Disclosure.Panel className="text-gray-500">
<p className='px-4 pb-3 pt-2 text-xs text-secondary'>Attach all required documents below to submit a pre-auth expense requests.</p>
    <ul className="list-group">
        <li className='flex items-center w-full px-4 py-4 border-b-2 border-secondary border-opacity-25'>
        <div className='mr-5'><FontAwesomeIcon icon={faReceipt} className="text-secondary text-lg" /></div>
        <div className="text-xs lg:text-base"><Link to="#" className="text-secondary">+ Itemized Quote</Link></div>
        </li>
        <li className='flex items-center w-full px-4 py-4 border-b-2 border-transparent border-opacity-25'>
        <div className='mr-5'><FontAwesomeIcon icon={faReceipt} className="text-secondary text-lg" /></div>
        <div className="text-xs lg:text-base"><Link to="#" className="text-secondary">+ Medical Records</Link></div>
        </li>
    </ul> 
</Disclosure.Panel>
{/**/}
</div>
)}
</Disclosure>
        
      


         
     <div className="text-center lg:text-left mt-7 lg:mt-10 mb-5"><Link to="/pre-approval-confirmation" className='btn uppercase'>Submit</Link></div>
     <div className='text-center mb-10 lg:mb-0'><Link to="/" className='text-xs font-bold text-secondary uppercase lg:hidden block'>Go Back</Link></div>
     </form>
       </div> {/* END */}

       {/* <div className="lg:col-span-6 hidden lg:block">
           <h3 className='uppercase text-secondary mb-2 font-bold text-lg'>WHERE TO FIND YOUR INFORMATION:</h3>
         


       </div> */}
 </div>
  </div> 
 </>
  )
}

export default PreApprovalForm