import React, { useState } from "react";
import { Link } from "react-router-dom";
import SearchBar from "../../components/SearchBar";

import logoicon from "../../assets/images/logo-icon.svg";
import ArrowRightIcon from "../../components/Icons/ArrowRightIcon";

import ErrorModal from "../../components/UI/ErrorModal";

const Medication = () => {
      // Modal

  const [isOpen, setIsOpen] = useState(false);

  function openModal(event, modalData) {
    setIsOpen({
      title: modalData.title,
      message: modalData.message,
      buttonlink: modalData.buttonlink,
      buttontext: modalData.buttontext,
    });
    return;
  }

  const popupHandler = () => {
    setIsOpen(null);
  };

  const modalObj = {
    title: "reimbursement details",
    message:
      '<div className="my-4"> <ol className="list-group"><li className="flex px-4 py-2 text-xs lg:text-sm text-secondary border-b-2 border-secondary border-opacity-25"><div className="w-4/5">Medication Type</div><div className="w-1/3 text-right text-secondary font-bold text-10px lg:text-sm">GENERIC</div></li><li className="flex px-4 py-2 text-xs lg:text-sm text-secondary border-b-2 border-secondary border-opacity-25"><div className="w-4/5">Start Reimbursement</div><div className="w-1/3 text-right text-secondary font-bold text-10px lg:text-sm">$100</div></li><li className="px-4 py-2 lg:text-sm"><p>Start’s reimbursement amount will be applied to your deductible.</p><strong className="my-1 block">NOTE:</strong><p>Start encourages members to utilize GoodRx to find the best price for their medications. Once purchased, Start requires that you upload an itemized receipt so that the expense is applied towards your plan’s defined benefit amounts.</p><p>Questions? Call XXX-XXX-XXXX</p></li></ol></div>',
    buttonlink: "",
    buttontext: "",
  };
  return (
    <>
     <SearchBar />
     <div className="container px-30px 2xl:px-0 lg:mt-12 mb-5 mt-5 flex">
        <div className="text-left">
          <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-1">
          medication name
          </h2>
          <p className="text-secondary text-xs font-bold lg:text-base">
          TYPE: GENERIC
          </p>
        </div>
      </div>

      <div className="container lg:px-30px 2xl:px-0">
        <div className="lg:grid lg:grid-cols-12 lg:gap-16">
          <div className="lg:col-span-6 pb-10 mt-4">
            <div className="px-30px lg:px-0">
              <Link
              className="block bg-gray-light w-full border-t-6 border-cyan rounded-md  p-3 lg:p-4"
                to="#"
                onClick={(e) => {
                  openModal(e, modalObj);
                }}
              >
                <div className="font-playfair text-secondary font-bold text-24px lg:text-22px lg:mb-2">
                start reimbursement
                </div>
                <div className="my-3 lg:mt-5 lg:mb-5 text-primary text-28px lg:text-[32px] font-bold">
                  $100
                </div>
                <div className="flex items-center">
                  <div className="text-secondary text-10px lg:text-sm font-bold uppercase">
                  View Details
                  </div>
                  <div className="ml-auto">
                  <ArrowRightIcon
                    fill="#1c3557"
                    cname="h-[11px] w-[12px] lg:h-[16.53px] lg:w-[15.99px]"
                  />
                  </div>
                </div>
              </Link>
              {isOpen && (
                <ErrorModal
                  title={isOpen.title}
                  message={isOpen.message}
                  buttonlink={isOpen.buttonlink}
                  buttontext={isOpen.buttontext}
                  onConfirm={popupHandler}
                />
              )}
             </div>

            
          </div>

          <div className="lg:col-span-6">
           
            

             

            

            


          </div>
        </div>
      </div>
    </>
  )
}

export default Medication