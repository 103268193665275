import React from "react";
import { Link } from "react-router-dom";

const Sitemap = () => {
  return (
    <>
      <div className="container w-2/4 m-auto my-5 pb-10">
        <h2 className="mb-1 text-lg font-semibold text-secondary">LOGIN</h2>
        <ul className="mb-6  list-disc list-inside">
          <li><Link className="text-secondary text-xs" target="_blank" to="/">Splash Screen</Link></li>
          <li><Link className="text-secondary text-xs" target="_blank" to="/login">Login</Link></li>
          <li><Link className="text-secondary text-xs" target="_blank" to="/forgot-password">Forgot Email or Password</Link></li>
        </ul>

        <h2 className="mb-1 text-lg font-semibold text-secondary">REGISTRATION / Sign up</h2>
        <ul className="mb-6 list-disc list-inside">
          <li><Link className="text-secondary text-xs" target="_blank" to="/where-do-you-live">Where do you live?</Link></li>
          <li><Link className="text-secondary text-xs" target="_blank" to="/add-initial-member">Add Initial Member</Link></li>
          <li><Link className="text-secondary text-xs" target="_blank" to="/adding-family-members">Adding Family Members</Link></li>
          <li><Link className="text-secondary text-xs" target="_blank" to="/select-your-quote-for-individual">Select Your Quote for Individual</Link></li>
          <li><Link className="text-purple-700 text-xs" target="_blank" to="/select-your-quote-for-family">Select Your Quote for Family</Link></li>
          {/* <li><Link className="text-purple-700 text-xs" target="_blank" to="/select-go-live-date">Select Go Live Date</Link></li> */}
          <li><Link className="text-purple-700 text-xs" target="_blank" to="/checkout">Checkout</Link></li>
          <li><Link className="text-purple-700 text-xs" target="_blank" to="/confirmation">Confirmation</Link></li>
          <li><Link className="text-purple-700 text-xs" target="_blank" to="/membership-denied">Membership Denied</Link></li>
          <li><Link className="text-purple-700 text-xs" target="_blank" to="/add-initial-member1">Add Initial Member – 1</Link></li>
        </ul>

        <h2 className="mb-1 text-lg font-semibold text-secondary">ACCOUNT SET UP</h2>
        <ul className="mb-6 list-disc list-inside">
          <li><Link className="text-secondary text-xs" target="_blank" to="/registration">Opening Screen</Link></li>
          <li><Link className="text-secondary text-xs" target="_blank" to="/need-registration">Need Registration</Link></li>
          <li><Link className="text-secondary text-xs" target="_blank" to="/select-member">Select a Member</Link></li>
          <li><Link className="text-secondary text-xs" target="_blank" to="/set-password">Set a Password</Link></li>
          <li><Link className="text-secondary text-xs" target="_blank" to="/enter-address">Enter Address</Link></li>
          <li><Link className="text-secondary text-xs" target="_blank" to="/which-account">Which Account?</Link></li>
          <li><Link className="text-secondary text-xs" target="_blank" to="/add-bank-account">Add a Bank Account</Link></li>
          <li><Link className="text-secondary text-xs" target="_blank" to="/open-start-hsa">Open Start HSA</Link></li>
          {/* <li><Link className="text-red-700 text-xs"  to="#">Open Start HSA – 1 (POPUP)</Link></li> */}
          <li><Link className="text-secondary text-xs" target="_blank" to="/account-ordering">Account Ordering</Link></li>
          <li><Link className="text-secondary text-xs" target="_blank" to="/select-reimbursement-preference#">Select Reimbursement Preference</Link></li>
        </ul>

        <h2 className="mb-1 text-lg font-semibold text-secondary">MAIN DASH</h2>
        <ul className="mb-6  list-disc list-inside">
          <li><Link className="text-secondary  text-xs" target="_blank" to="/dashboard">User Dashboard</Link></li>
        </ul>


        <h2 className="mb-1 text-lg font-semibold text-secondary">HSA SUMMARY</h2>
        <ul className="mb-6  list-disc list-inside">
          <li><Link className="text-secondary text-xs" target="_blank" to="/hsa-summary">HSA Summary</Link></li>
          <li><Link className="text-secondary text-xs" target="_blank" to="/account-summary">HSA Account Summary</Link></li>
          <li><Link className="text-secondary text-xs" target="_blank" to="/transaction-history">HSA Transaction History</Link></li>
          <li><Link className="text-secondary text-xs" target="_blank" to="/contributions">HSA Contributions</Link></li>
          {/* <li><Link className="text-red-700 text-xs"  to="#">HSA Contributions Submit Button Selected (POPUP)</Link></li> */}
          <li><Link className="text-secondary text-xs" target="_blank" to="/start-card">Start Card</Link></li>
          <li><Link className="text-secondary text-xs" target="_blank" to="/start-card-activation">Start Card Activation</Link></li>
          <li><Link className="text-red-700 text-xs" target="_blank" to="#">Call Start Health (Camera Open)</Link></li>
          <li><Link className="text-secondary text-xs" target="_blank" to="/manage-start-card">Manage Start Card</Link></li>
          <li><Link className="text-secondary text-xs" target="_blank" to="/add-bankaccount">Add a Bank Account</Link></li>
          {/*<li><Link className="text-secondary text-xs" target="_blank" to="/investments">Investments Dashboard</Link></li>
           <li><Link className="text-purple-700 text-xs" to="#">Investments Registration – User Eligible</Link></li>
          <li><Link className="text-purple-700 text-xs" to="#">Investments Registration – Investor Profile</Link></li>
          <li><Link className="text-purple-700 text-xs" to="#">Investments Registration – Investments Verification Status</Link></li>
          <li><Link className="text-purple-700 text-xs" to="#">Investments Registration – Path Profile</Link></li>
          <li><Link className="text-purple-700 text-xs" to="#">Investments Registration – Investments Verification Status – 1</Link></li>
          <li><Link className="text-purple-700 text-xs" to="#">Investments Dashboard – Managed Path</Link></li>
          <li><Link className="text-purple-700 text-xs" to="#">Investments Dashboard – Managed Path – 1</Link></li>
          <li><Link className="text-purple-700 text-xs" to="#">Investments Dashboard – Managed Path – 2</Link></li>
          <li><Link className="text-purple-700 text-xs" to="#">Investments Dashboard – Self Directed Path – Pending Investments</Link></li> */}

          
          </ul>
        <h2 className="mb-1 text-lg font-semibold text-secondary">FIND CARE</h2>
        <ul className="mb-6   list-disc list-inside">
          <li><Link className="text-secondary text-xs" target="_blank" to="/find-care">Find Care</Link></li>
          <li><Link className="text-secondary text-xs" target="_blank" to="/find-care/category/mri-brain">Find Care Search Selection </Link></li>
          {/* <li><Link className="text-red-700 text-xs"   to="#">Find Care Search Selection – Reimbursement Details (POPUP)</Link></li> */}
          <li><Link className="text-secondary text-xs" target="_blank" to="/find-care/provider/open-imaging">Find Care Provider Selected</Link></li>
          <li><Link className="text-secondary text-xs" target="_blank" to="/find-care/doctor-visit">Find Care Care Type Tile Selected</Link></li>
        </ul>

        <h2 className="mb-1 text-lg font-semibold text-secondary">FIND DOCTOR</h2>
        <ul className="mb-6   list-disc list-inside">
          <li><Link className="text-secondary  text-xs" target="_blank" to="/find-doctor/">Find Doctor Dashboard</Link></li>
          <li><Link className="text-secondary  text-xs" target="_blank" to="/find-doctor/category/dermatology">Find Doctor Search Selection</Link></li>
          <li><Link className="text-secondary  text-xs" target="_blank" to="/find-doctor/dr-smith">Find Doctor Search Selection </Link></li>
          <li><Link className="text-secondary  text-xs" target="_blank" to="/find-doctor/services/cyst-removal">Find Doctor Search Service Selection </Link></li>
        </ul>


        <h2 className="mb-1 text-lg font-semibold text-secondary">Favorites</h2>
        <ul className="mb-6  list-disc list-inside">
          <li><Link className="text-secondary  text-xs" target="_blank" to="/favorites">Favorites</Link></li>
        </ul>

        <h2 className="mb-1 text-lg font-semibold text-secondary">EXPENSE DASH</h2>
        <ul className="mb-6  list-disc list-inside">
          <li><Link className="text-secondary  text-xs" target="_blank" to="/expenses">Expenses Dashboard</Link></li>
          <li><Link className="text-secondary  text-xs" target="_blank" to="/upload-expense">Upload an Expense – 1</Link></li>
          <li><Link className="text-secondary  text-xs" target="_blank" to="/receipt-uploaded">Receipt Uploaded Generated New Expense </Link></li>
          <li><Link className="text-secondary  text-xs" target="_blank" to="/receipt-uploaded-1">Payment / Card Generated New Expense</Link></li>
          <li><Link className="text-secondary  text-xs" target="_blank" to="/receipt-uploaded-2">General Payment / Card Generated Denied Expense</Link></li>
          <li><Link className="text-secondary  text-xs" target="_blank" to="/receipt-uploaded-3"> General Payment / Card Generated Partial Reimbursed Expense</Link></li>
          <li><Link className="text-red text-xs" target="_blank" to="#">Uploaded Expense File Selected</Link></li>
          <li><Link className="text-secondary  text-xs" target="_blank" to="/pre-deductible-1">Pre-Deductible General Open Expense Page – In Review</Link></li>
          <li><Link className="text-secondary  text-xs" target="_blank" to="/pre-deductible-2">Pre-Deductible General Completed Expense</Link></li>
          <li><Link className="text-secondary  text-xs" target="_blank" to="/pre-deductible-3">Pre-Deductible General Completed Expense – 1 </Link></li>
        </ul>

        <h2 className="mb-1 text-lg font-semibold text-secondary">Account</h2>
        <ul className="mb-6  list-disc list-inside">
          <li><Link className="text-secondary  text-xs" target="_blank" to="/account">Account</Link></li>
          <li><Link className="text-secondary  text-xs" target="_blank" to="/account-details">Account Details</Link></li>
          <li><Link className="text-secondary  text-xs" target="_blank" to="/account-settings">Account Details</Link></li>
        </ul>
      </div>
    </>
  );
};

export default Sitemap;
