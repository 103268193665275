import React from 'react'
import { Link } from 'react-router-dom'

const PreApprovalRequestConfirmation = () => {
  return (
    <>
     <header className='container px-30px 2xl:px-0 lg:mt-12 mb-5 mt-5 flex'>
     <div className="text-left">
       <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-1">request submitted</h2>
      
     </div>
  </header>

  <div className='container px-30px 2xl:px-0'>
  <div className="lg:grid lg:grid-cols-12 lg:gap-16">
       <div className="lg:col-span-6 pb-10">
       
<p className='text-secondary text-xs lg:text-lg pt-3'>Thank you for submitting your pre-approval request. You can view the status of your pre-approval request within your Expense Dashboard.</p> 

<p className='text-secondary text-xs lg:text-lg pt-3'><strong>Note:</strong> Once approved, please <strong>call us the day before your procedure</strong> so that your Start Card can be loaded with funds equal to the amount of the reimbursable amount for the procedure.</p> 


 
      


         
     <div className="text-center lg:text-left mt-7 lg:mt-10 mb-5"><Link to="/expenses" className='btn uppercase'>OKAY</Link></div>
     
 
       </div> {/* END */}

       {/* <div className="lg:col-span-6 hidden lg:block">
           <h3 className='uppercase text-secondary mb-2 font-bold text-lg'>WHERE TO FIND YOUR INFORMATION:</h3>
         


       </div> */}
 </div>
  </div> 
    </>
  )
}

export default PreApprovalRequestConfirmation