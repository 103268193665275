import { BrowserRouter} from "react-router-dom"
import Routing from "./routes";
import ScrollToTop from "./components/ScrollToTop"


function App() {
  return (
    <>
      <BrowserRouter>
        <Routing />
        <ScrollToTop />
      </BrowserRouter>
    </>
  );
}

export default App;
