import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import Input from '../../../../components/Input';

 const Registration = () => {

  useEffect(()=>{
    document.title = 'Registration';
  }, []);

  return (
    <>
      <div className="h-full lg:h-auto lg:min-h-[calc(100vh_-_107px)] flex lg:justify-between lg:items-center">
        <div className="h-full flex flex-col justify-between items-center lg:h-auto w-full pt-12 lg:py-0 px-8 lg:px-0 min-h-[calc(100vh_-_60px)] lg:min-h-full">
        <div className="max-w-md mx-auto w-full">
        <div className="text-center mb-5 lg:mb-7">
            <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-2">
                nice to see you.
            </h2>
            <p className='text-cyan text-xs font-bold lg:text-lg'>Please complete the fields below.</p>
        </div>
        <form className="" action="#">
            <div className="flex mb-3">
              <Input
                type="text" 
                className="form-control mr-[15px]" 
                placeholder="First Name"
              />
              <Input
                className="form-control"
                placeholder="Last Name"
                type="text"
                id="lname"
              />
            </div>
            <div className="mb-3">
              <Input
                type="text"
                className="form-control"
                placeholder="Email"
              />
            </div>
            <div className="mb-3">
              <Input
                type="text"
                className="form-control"
                placeholder="Zip Code"
              />
            </div>

            
              <div className="text-center mt-7 mb-5"><Link to="/select-member" className='btn uppercase'>Continue</Link></div>
              <div className='text-center'><Link to="/login" className='text-xs font-bold text-secondary uppercase'>Cancel</Link></div>
           
        </form>
        </div>
        <div className='text-center text-xs text-secondary font-bold lg:hidden'>
          <Link to="/need-registration">Information not matching any members.</Link>
        </div>

        {/* <div className='text-center text-xs text-secondary font-bold mt-28 hidden  lg:block'>
          <Link to="#" className='text-cyan font-bold text-lg mb-2 inline-block'>Account Associated with Family Policy</Link> <br />
          <Link to="#" className='text-cyan font-bold text-lg'>Information entered not associated with active policy.</Link>
        </div> */}

        
        </div>
      </div>
    </>
  );
}

export default Registration;