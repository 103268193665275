import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight
} from '@fortawesome/free-solid-svg-icons'
import ArrowRightIcon from '../../components/Icons/ArrowRightIcon';
import ActionCardButton from '../../components/Button/ActionCardButton';



const ManageStartCard = () => {
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    document.title = 'Account Ordering';
  }, []);

  return (
    <>
     <header className='container px-30px 2xl:px-0 lg:mt-12 mb-5 mt-5 flex'>
        <div className="text-left">
          <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-1">manage card
</h2>
          <p className="text-secondary text-xs font-bold lg:text-base">MEMBER: JANE DOE</p>
        </div>
     </header>

     <div className='container px-30px 2xl:px-0'>
     <div className="lg:grid lg:grid-cols-12 lg:gap-16">
          

          <div className="lg:col-span-6">
              

 

              <div className='card lg:block   mt-0'>
        <h4 className='card-title lg:text-22px'>account ordering</h4>

        <ol className=''>
              <li className=''>
                <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                  <span className=" text-xs lg:text-base text-secondary">Start HSA Account</span>
                  <input type="radio" className='ml-auto form-radio' name='accountOrdering' value="option1" defaultChecked={checked}
                  onChange={() => setChecked(!checked)} />
                </label>
              </li>
              <li className=''>
                <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                  <span className=" text-xs lg:text-base text-secondary">Jane’s Checking Account</span>
                  <input type="radio" className='ml-auto form-radio' name='accountOrdering'  value="option2" onChange={() => setChecked(!checked)}/>
                </label>
              </li>
              <li className='p-4'>
                <Link to="/add-bankaccount" className='btn uppercase w-full flex justify-center items-center cursor-pointer'>ADD ACCOUNT
                  <FontAwesomeIcon icon={faArrowRight} className="text-white w-2.5 ml-auto" />
                </Link>
              </li>
            </ol>

        
      </div> {/* END .card */}


      {/* <div className='card lg:block hidden'>
        <h4 className='card-title lg:text-22px'>request new card</h4>
        <div className='text-3xl font-bold text-primary px-4'></div>
              <Link to="#" className='card-footer'>
                <div className='font-bold text-10px lg:text-sm'>CALL START HEALTH</div>
                <div className='text-secondary ml-auto'>
                <ArrowRightIcon fill="#1c3557" cname="h-[11px] w-3 lg:w-4 lg:h-4" />
                </div>
              </Link>
      </div>  */}

<ActionCardButton title="request new card" url="#" linktitle="CALL START HEALTH"     />
      {/* END .card */}



          </div>

          <div className="lg:col-span-6 pb-10">
                 
          </div> {/* END */}
    </div>
     </div>
    </>
  )
}

export default ManageStartCard