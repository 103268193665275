import React from 'react'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight
} from '@fortawesome/free-solid-svg-icons'
import ArrowRightIcon from '../../components/Icons/ArrowRightIcon';
import ActionCardButton from '../../components/Button/ActionCardButton';



const StartCardActivation = () => {
  return (
    <>
   
   <header className='container px-30px 2xl:px-0 lg:mt-12 mb-5 mt-5 flex'>
        <div className="text-left">
          <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-1">start card</h2>
          <p className="text-secondary text-xs font-bold lg:text-base">MEMBER: JANE DOE</p>
        </div>
     </header>

     <div className='container px-30px 2xl:px-0'>
     <div className="lg:grid lg:grid-cols-12 lg:gap-16">
          <div className="lg:col-span-6 pb-10 hidden lg:block">
                <div className='bg-gray-light text-secondary text-lg font-playfair w-full h-[212px] flex justify-center items-center rounded-[20px]'>
                member card image
                </div>
          </div> {/* END */}

          <div className="lg:col-span-6">


          <ActionCardButton title="activate hsa debit card" url="#" linktitle="CALL START HEALTH"   />
       
       {/* END .card */}



          </div>
    </div>
     </div>
    
    </>
  )
}

export default StartCardActivation