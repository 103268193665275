import React, {useEffect} from 'react'
import { Link } from "react-router-dom"
import SearchBar from '../../components/SearchBar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUserDoctor,
  faPrescription,
  faStethoscope,
  faFlask,
  faLaptopMedical,
  faXRay,
  faBedPulse,
  faCrutch,
  faBaby,
  faTruckMedical,
  faHospitalUser,
  faUserInjured,
  faHospital,
  faThermometer,
  faBookmark,
  faReceipt,
  
} from '@fortawesome/free-solid-svg-icons'

import CardButton from '../../components/Button/CardButton'

import CircleIconButton from '../../components/Button/CircleIconButton'
import ArrowRightIcon from '../../components/Icons/ArrowRightIcon'

import logoicon from '../../assets/images/logo-icon.svg'

import iconFindDoctor from "../../assets/images/svg/user-doctor-solid.svg";
import iconPrescriptions from "../../assets/images/svg/prescription-solid.svg";
import iconViewFavorites from "../../assets/images/svg/bookmark-solid.svg";
import iconUploadReceipt from "../../assets/images/svg/receipt-solid.svg";

const FindCare = () => {

  useEffect(()=>{
    document.title = 'Find Care';
  }, []);

  return (
    <>
      <SearchBar />
      <div className="bg-white lg:bg-gray-light py-6 lg:py-12">
        <div className="container px-30px 2xl:px-0">
          <div className="grid grid-cols-12 gap-7 lg:gap-14">
              <div className="col-span-6 lg:col-span-3">
                <CardButton title="FIND DOCTOR" xicon={iconFindDoctor} url="/find-doctor" imageClassName="w-[30.73px] h-[35.12px] lg:w-[35.34px] lg:h-[40.39px] mx-auto lg:m-0"  />
              </div>
              <div className="col-span-6 lg:col-span-3">
                <CardButton title="PRESCRIPTIONS" xicon={iconPrescriptions} url="/prescriptions" imageClassName="w-[27.66px] h-[32.06px] lg:w-[34.85px] lg:h-[40.39px] mx-auto lg:m-0"  />
              </div>
              <div className="col-span-6 lg:col-span-3">
                <CardButton title="VIEW FAVORITES" xicon={iconViewFavorites} url="/favorites" imageClassName="w-[21.8px] h-[29.06px] lg:w-[27.52px] lg:h-[36.7px] mx-auto lg:m-0"  />
              </div>
              <div className="col-span-6 lg:col-span-3">
                <CardButton title="Expenses" xicon={iconUploadReceipt} url="/expenses" imageClassName="w-[21.8px] h-[29.06px] lg:w-[27.52px] lg:h-[36.7px] mx-auto lg:m-0"  />
              </div>
          </div>
          </div>
        </div>

        <div className="lg:py-8 container lg:px-30px 2xl:px-0">
        <div className="lg:grid lg:grid-cols-12 lg:gap-14">
          <div className="col-span-9 pb-8 md:pb-0">
          <ul className="grid overflow-hidden grid-cols-3 lg:grid-cols-4 grid-rows-2 gap-x-6 lg:gap-y-14 gap-y-3 mx-9 md:mx-0">
              <li className="text-center">
                <CircleIconButton title="Doctor Visit" faxicon={faStethoscope} xurl="/find-care/doctor-visit"  />
              </li>
              <li className="text-center">
              <CircleIconButton title="Lab Work" faxicon={faFlask} xurl="#"  />
              </li>
              <li className="text-center">
              <CircleIconButton title="Telemedicine" faxicon={faLaptopMedical} xurl="#"  />
              </li>
              <li className="text-center">
              <CircleIconButton title="X-Rays / Imaging" faxicon={faXRay} xurl="#"  />
              </li>
              <li className="text-center">
              <CircleIconButton title="Surgery" faxicon={faBedPulse} xurl="#"  />
              </li>
              <li className="text-center">
              <CircleIconButton title="Equipment / Supplies" faxicon={faCrutch} xurl="#"  />
              </li>
              <li className="text-center">
              <CircleIconButton title="Maternity" faxicon={faBaby} xurl="#"  />
              </li>
              <li className="text-center">
              <CircleIconButton title="Transportation" faxicon={faTruckMedical} xurl="#"  />
              </li>
              <li className="text-center">
              <CircleIconButton title="Hospital Admission" faxicon={faHospitalUser} xurl="#"  />
              </li>
              <li className="text-center">
              <CircleIconButton title="Therapy / Rehab" faxicon={faUserInjured} xurl="#"  />
              </li>
              <li className="text-center">
              <CircleIconButton title="Same-Day Facility Services" faxicon={faHospital} xurl="#"  />
              </li>
              <li className="text-center">
              <CircleIconButton title="Medicine" faxicon={faThermometer} xurl="#"  />
                
              </li>
            </ul>
          </div>
          {/*  END */}
          <div className="col-span-3 hidden lg:block">
              <h2 className="font-playfair text-secondary font-bold text-[32px] mb-5">recently viewed</h2>
              <ul className='bg-gray-light w-full border-t-6 border-cyan rounded-md'>
                <li className='bg-gray-light border-b-2 border-secondary border-opacity-25'>
                  <Link to="#" className='block px-6 pt-5 pb-2'>
                  <div className='flex relative text-secondary'>
                  Dr. Smith <img src={logoicon} alt="" className='w-3 ml-2' /></div>
                  <small className='text-8px uppercase text-secondary font-bold block'>DERMATOLOGY</small>
                    <div className='flex items-center mt-5'>
                        <div className='text-secondary text-10px lg:text-sm font-bold uppercase'>VIEW DETAILS </div> 
                        <div className='ml-auto'><ArrowRightIcon fill="#1c3557" cname="h-[10.98px] w-[10.98px]" /></div> 
                    </div>
                  </Link>
                </li>
                <li className='bg-gray-light border-b-2 border-secondary border-opacity-25'>
                  <Link to="#" className='block px-6 pt-5 pb-2'>
                  <div className='flex relative text-secondary'>
                  Dr. Smith <img src={logoicon} alt="" className='w-3 ml-2' /></div>
                  <small className='text-8px uppercase text-secondary font-bold block'>DERMATOLOGY</small>
                    <div className='flex items-center mt-5'>
                        <div className='text-secondary text-10px lg:text-sm font-bold uppercase'>VIEW DETAILS </div> 
                        <div className='ml-auto'><ArrowRightIcon fill="#1c3557" cname="h-[10.98px] w-[10.98px]" /></div> 
                    </div>
                  </Link>
                </li>
                <li className='bg-gray-light border-b-2 border-secondary border-opacity-25'>
                  <Link to="#" className='block px-6 pt-5 pb-2'>
                  <div className='flex relative text-secondary'>
                  Dr. Smith <img src={logoicon} alt="" className='w-3 ml-2' /></div>
                  <small className='text-8px uppercase text-secondary font-bold block'>DERMATOLOGY</small>
                    <div className='flex items-center mt-5'>
                        <div className='text-secondary text-10px lg:text-sm font-bold uppercase'>VIEW DETAILS </div> 
                        <div className='ml-auto'><ArrowRightIcon fill="#1c3557" cname="h-[10.98px] w-[10.98px]" /></div> 
                    </div>
                  </Link>
                </li>
                <li className='h-10'></li>
              </ul>
              
          </div>
        </div>
        </div>

    </>
  );
}

export default FindCare