import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import CustomSelect from "../../../components/Select";
import Input from "../../../components/Input";


const AddInitialMember1 = () => {
  const options = [
    { value: 'Male', label: 'Male' },
    { value: 'female', label: 'Female' },
  ];
  return (
    <>
        <div className="h-full lg:h-auto lg:min-h-[calc(100vh_-_107px)] flex lg:justify-between lg:items-center">
        <div className="h-full flex flex-col justify-between items-center lg:h-auto w-full pt-12 lg:px-0 min-h-[calc(100vh_-_60px)] lg:min-h-full">
        <div className="container px-30px 2xl:px-0">
        <div className="text-center mb-5 lg:mb-12">
            <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-2">we’d like to get to know you.</h2>
            <p className='text-cyan text-xs font-bold lg:text-lg'>Please answer the questions below.</p>
        </div>
        <form className="" action="#">
        <div className="lg:grid lg:grid-cols-12 lg:gap-16">
        
        <div className="lg:col-span-6 mb-6">

        <div className="flex mb-3 space-x-3">
              <div className="w-1/2">
                <Input
                  className="form-control"
                  placeholder="First Name"
                  type="text"
                  id="fname"
                />
              </div>
              <div className="w-1/2">
                <Input
                  className="form-control"
                  placeholder="Last Name"
                  type="text"
                  id="lname"
                />
              </div>
            </div>

            <div className="mb-3">
              <div className="w-full">
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                />
              </div>
            </div>

            <div className="flex mb-3 space-x-3">
              <div className="w-1/2">
                <Input 
                  className="form-control"
                  placeholder="Birthday"
                  type="text"
                  id="birthday"
                />
              </div>
              <div className="w-1/2">
              <CustomSelect phtext="Select Gender" optionsval={options} />
              </div>
            </div>

            <div className="mb-5 mt-6">
              <span className="text-xs lg:text-lg text-secondary block mb-2">
                Have you in the past 5 years been diagnosed with, or treated for any of the following? Select all that apply.
              </span>
              <ul className="bg-gray-light border-t-6 rounded-md border-cyan">
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                    <Input type="checkbox" className="form-checkbox" />
                    <span className="ml-2 text-xs lg:text-base text-secondary">
                      Diabetes
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                    <Input type="checkbox" className="form-checkbox" />
                    <span className="ml-2 text-xs lg:text-base text-secondary">
                      Systemic Lupus
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-transparent border-opacity-25 cursor-pointer">
                    <Input type="checkbox" className="form-checkbox"  />
                    <span className="ml-2 text-xs lg:text-base text-secondary">
                      Liver Disease
                    </span>
                  </label>
                </li>
              </ul>
            </div>

            <div className="mb-5 mt-6">
              <span className="text-xs lg:text-lg text-secondary block mb-2">
                Have you had any of the following in the last five years?
              </span>
              <ul className="bg-gray-light border-t-6 rounded-md border-cyan">
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                    <Input type="checkbox" className="form-checkbox" />
                    <span className="ml-2 text-xs lg:text-base text-secondary">
                      Hepatitis C
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                    <Input type="checkbox" className="form-checkbox" />
                    <span className="ml-2 text-xs lg:text-base text-secondary">
                      Back Surgery
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-transparent border-opacity-25 cursor-pointer">
                    <Input type="checkbox" className="form-checkbox" />
                    <span className="ml-2 text-xs lg:text-base text-secondary">
                      Weighed over 300 lbs. as a male or 260 lbs. as a female
                    </span>
                  </label>
                </li>
              </ul>
            </div>

            <div className="mb-5 mt-6">
              <span className="text-xs lg:text-lg text-secondary block mb-2">
              In the past 12 months, have you?
              </span>
              <ul className="bg-gray-light border-t-6 rounded-md border-cyan">
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                    <Input type="checkbox" className="form-checkbox" />
                    <span className="ml-2 text-xs lg:text-base text-secondary">
                      Used tobacco products in any form
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                    <Input type="checkbox" className="form-checkbox" />
                    <span className="ml-2 text-xs lg:text-base text-secondary">
                      Visited a chiropractor five or more times
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-transparent border-opacity-25 cursor-pointer">
                    <Input type="checkbox" className="form-checkbox" />
                    <span className="ml-2 text-xs lg:text-base text-secondary">
                      Visited a licensed mental health five or more times
                    </span>
                  </label>
                </li>
              </ul>
            </div>

            <div className="mb-5 mt-6">
              <span className="text-xs lg:text-lg text-secondary block mb-2">
                Are you currently?
              </span>
              <ul className="bg-gray-light border-t-6 rounded-md border-cyan">
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                    <Input type="checkbox" className="form-checkbox" />
                    <span className="ml-2 text-xs lg:text-base text-secondary">
                    Pregnant, or suspect you might be?
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-transparent border-opacity-25 cursor-pointer">
                    <Input type="checkbox" className="form-checkbox" />
                    <span className="ml-2 text-xs lg:text-base text-secondary">
                    Financially responsible for an unborn child, 
anticipating adoption, applying for, or have 
applied for adoption?
                    </span>
                  </label>
                </li>
              </ul>
            </div>

            <div className="mb-3">
              <div className="w-full">
                <span className="text-xs lg:text-lg text-secondary block mb-2">
                What prescribed medical treatments (procedures, 
services, devices, or medication) do you expect to receive 
in the next 12 months?
                </span>
                <textarea className="form-control" rows="3"></textarea>
              </div>
            </div>

            <div className="mb-3">
              <div className="w-full">
                <span className="text-xs lg:text-lg text-secondary block mb-2">
                What prescriptions do you take regularly?
                </span>
                <input type="text" className="form-control" />
              </div>
            </div>

            <div className="mb-3">
              <div className="w-full">
                <span className="text-xs lg:text-lg text-secondary block mb-2">
                Would you like to provide any additional or clarifying 
information about your answers above?
                </span>
                <textarea className="form-control" rows="3"></textarea>
              </div>
            </div>

            <Link
              to="/adding-family-members"
              className="btn uppercase w-full flex justify-center items-center cursor-pointer bg-cyan"
            >
              <FontAwesomeIcon
                icon={faPlus}
                className="text-white w-2.5 mr-2"
              />{" "}
              ADD A FAMILY MEMBER
              <FontAwesomeIcon
                icon={faArrowRight}
                className="text-white w-2.5 ml-auto"
              />
            </Link>

          </div>
          <div className="lg:col-span-6">
          <div className="bg-transparent lg:bg-gray-light border-0 lg:border-t-6 rounded-md border-cyan">
            <div className='text-lg leading-6 px-8 text-secondary pt-7 hidden lg:block'>
            Please confirm the information entered is correct before continuing with your account set up.
              </div>
          <div className="text-center mt-7 pb-5 lg:pb-8 px-8">
            <Link to="/select-your-quote-for-individual" className='btn uppercase w-full lg:flex lg:justify-center items-center cursor-pointer bg-primary' >
            GET QUOTE
              <FontAwesomeIcon
                icon={faArrowRight}
                className="text-white w-3 ml-auto hidden lg:inline-block"
              />
            </Link>
            
             
            </div>
  <div className='text-center mb-10 lg:mb-0'><Link to="/add-initial-member" className='text-xs font-bold text-secondary uppercase lg:hidden block'>Go Back</Link></div>
          </div>
          </div>
          
        </div>
        </form>
        </div>
         
      

        
        </div>
      </div>
    </>
  )
}

export default AddInitialMember1