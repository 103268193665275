import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import Input from '../../../../components/Input';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faPlus } from "@fortawesome/free-solid-svg-icons";

import ErrorModal from '../../../../components/UI/ErrorModal';
import CustomSelect from '../../../../components/Select';

function OpenStartHSA() {

  const handleChange = (event) => {
    const { value, name } = event.target;
    this.useState({ [name]: value });
}

  useEffect(()=>{
    document.title = 'Open Start HSA';
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  function openModal(event, modalData){
    setIsOpen({
      title: modalData.title,
      message: modalData.message,
      buttonlink: modalData.buttonlink,
      buttontext: 'Submit'
    });
    return;
  }

  const popupHandler = () => {
    setIsOpen(null);
  };

  const modalObj = {
    title: 'hsa eligibility',
    message: '<div className="my-4 px-6 lg:text-sm"><p className="text-sm lg:text-base font-bold mb-2">Please confirm your HSA eligibility.</p><p>To be eligible for an HSA, you must meet the following requirements: </p><ul><li>Covered by a HDHP.</li><li>Have no other health coverage except what is permitted by the IRS.</li><li>Not enrolled in Medicare, TRICARE, or TRICARE for Life.</li><li>Not claimed as a dependent on someone else’s tax return.</li><li>Have not received Veteran Affairs (VA) benefits within the past 3 months, with the exception of preventive care.</li><li>You do not have an FSA or HRA. </li></ul><p>Other restrictions may apply.</p><p>By clicking submit, you confirm you are eligible to participate in a health savings account (HSA).</p></div>',
    buttonlink: '/account-ordering'
  }

  const options = [
    { value: 'one', label: 'One' },
    { value: 'two', label: 'Two' },
    { value: 'three', label: 'Three' }
  ];

  return (
    <>
 <div className="h-full lg:h-auto lg:min-h-[calc(100vh_-_107px)] flex lg:justify-between lg:items-center">
        <div className="h-full flex flex-col justify-between items-center lg:h-auto w-full pt-12 xl:pt-0    lg:px-0 min-h-[calc(100vh_-_60px)] lg:min-h-full">
        <div className="container px-30px 2xl:px-0">
        <div className="text-center mb-5 lg:mb-12">
            <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-2">open your start hsa</h2>
            <p className='text-cyan text-xs font-bold lg:text-lg'>Please confirm and enter the required information.</p>
        </div>
        <form className="" action="#">
        <div className="lg:grid lg:grid-cols-12 lg:gap-16">
        
        <div className="lg:col-span-6">
            <div className="flex mb-3 space-x-3">
                <div className="w-1/2">
                  <Input
                          className="form-control"
                          placeholder="First Name"
                          type="text"
                          id="fname"
                  />
                </div>
                <div className="w-1/2">
                <Input
                        className="form-control"
                        placeholder="Last Name"
                        type="text"
                        id="lname"
                />
                </div>
            </div>



            <div className="mb-3">
            <div className="w-full">
                <Input
                        type="email"
                        className="form-control"
                        placeholder="janedoe@gmail.com"
                />
                </div>
            </div>

            <div className="flex mb-3 space-x-3">
                <div className="w-1/2">
                    <Input
                        className="form-control"
                        placeholder="01/01/1980"
                        type="text"
                        id="date"
                    />
                </div>
                <div className="w-1/2">
                <CustomSelect phtext="Female" optionsval={options} />
                </div>
            </div>

            <div className="mb-3">
                <div className="w-full">
                    <Input
                        type="text"
                        className="form-control"
                        placeholder="1111 Dock St."
                    />
                </div>
            </div>



            <div className="flex mb-3 space-x-3">
                <div className="w-1/2">
                  <Input
                          className="form-control"
                          placeholder="Line 2 (Optional)"
                          type="text"
                          id="pobox"
                  />
                </div>
                <div className="w-1/2">
                <Input
                        className="form-control"
                        placeholder="South jordan"
                        type="text"
                        id="zip"
                />
                </div>
            </div>

            <div className="flex mb-3 space-x-3">
                <div className="w-1/2">
                <CustomSelect phtext="State" optionsval={options} />
                </div>
                <div className="w-1/2">
                    <Input
                        className="form-control"
                        placeholder="84009"
                        type="text"
                        id="fname"
                    />
                </div>

            </div>

            <div className="mb-3">
            <div className="w-full">
                <Input
                        type="text"
                        className="form-control bg-cyan/25"
                        placeholder="Social Security Number"
                />
                </div>
            </div>


            <div className="mb-3">
            <div className="w-full">
                <Input
                        type="text"
                        className="form-control bg-cyan/25"
                        placeholder="Mobile Phone"
                />
                </div>
            </div>

               
            
                
        

        </div>
          <div className="lg:col-span-6">
          <div className="bg-transparent lg:bg-gray-light border-0 lg:border-t-6 rounded-md border-cyan">
            <div className='text-lg leading-6 px-8 text-secondary pt-7 hidden lg:block'>
            Please confirm the information entered is correct before continuing with your account set up.
              </div>
          <div className="text-center mt-7 pb-5 lg:pb-8 px-8">
            <Link to="/open-start-hsa" className='btn uppercase w-full lg:flex lg:justify-center items-center cursor-pointer bg-primary' onClick={(e) => {openModal(e, modalObj) }}>
              Continue
              <FontAwesomeIcon
                icon={faArrowRight}
                className="text-white w-3 ml-auto hidden lg:inline-block"
              />
            </Link>
            
            {isOpen && (
                <ErrorModal
                  title={isOpen.title}
                  message={isOpen.message}
                  buttonlink={isOpen.buttonlink}
                  buttontext={isOpen.buttontext}
                  onConfirm={popupHandler}
                />
              )}
            </div>
  <div className='text-center'><Link to="/which-account" className='text-xs font-bold text-secondary uppercase lg:hidden block'>Go Back</Link></div>
          </div>
          </div>
          
        </div>
        </form>

        </div>
        </div>
      </div>
    </>
  )
}

export default OpenStartHSA