import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import ArrowRightIcon from "../../components/Icons/ArrowRightIcon";
import ActionCardButton from "../../components/Button/ActionCardButton";

//import iconRecipt from "../../assets/images/svg/bookmark-solid.svg";

const AccountDetails = () => {
  return (
    <>
      <header className="container px-30px 2xl:px-0 lg:mt-12 mb-8 lg:mb-10 mt-5 flex">
        <div className="text-left flex w-full">
          <div className="">
            <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-1 flex">
              account summary
            </h2>
            <p className="text-secondary text-xs font-bold lg:text-base">
              MEMBER: JANE DOE
            </p>
            <small className="block text-8px text-secondary uppercase font-bold lg:hidden">
              PLAN ID: SHSAXXXXXX
            </small>
          </div>
          <div className="ml-auto">
            <Link to="/account-settings">
              <FontAwesomeIcon
                icon={faGear}
                className="text-secondary text-base lg:text-2xl"
              />
            </Link>
          </div>
        </div>
      </header>

      <div className="container px-30px 2xl:px-0">
        <div className="lg:grid lg:grid-cols-12 lg:gap-16">
          <div className="lg:col-span-6 pb-6">

          <div className='card mt-0'>
          <h4 className='card-title pb-0 lg:text-22px'>details</h4>
           
           <div className="grid grid-cols-5 py-4 bg-gray-light lg:bg-transparent border-b-2 border-b-secondary border-opacity-25">
              <div className="col-span-3"><div className="pl-4  text-xs lg:text-base text-secondary">Premium</div></div>
              <div className="col-span-2"><div className="pr-9 text-secondary text-xs lg:text-base  font-bold lg:text-right max-w-[114px]  lg:max-w-[142px] ml-auto">$429/mo</div></div>
           </div>
           <Link to="#" className="grid grid-cols-5 py-4 bg-gray-light lg:bg-transparent border-b-2 border-b-secondary border-opacity-25">
              <div className="col-span-3"><div className="pl-4  text-xs lg:text-base text-secondary">Service Limits</div></div>
              <div className="col-span-2"><div className="pr-9  text-secondary flex">
              <ArrowRightIcon fill="#1c3557" cname="h-[11px] w-3 lg:w-4 lg:h-4 ml-auto mt-1" /></div></div>
           </Link>
           <Link to="#" className="grid grid-cols-5 py-4 bg-gray-light lg:bg-transparent border-b-2 border-b-secondary border-opacity-25">
              <div className="col-span-3"><div className="pl-4  text-xs lg:text-base text-secondary">Health Information</div></div>
              <div className="col-span-2"><div className="pr-9  text-secondary flex">
              <ArrowRightIcon fill="#1c3557" cname="h-[11px] w-3 lg:w-4 lg:h-4 ml-auto mt-1" /></div></div>
           </Link>
           <Link to="#" className="grid grid-cols-5 py-4 bg-gray-light lg:bg-transparent border-b-2 border-b-transparent border-opacity-25">
              <div className="col-span-3"><div className="pl-4  text-xs lg:text-base text-secondary">What’s Not Covered</div></div>
              <div className="col-span-2"><div className="pr-9  text-secondary flex">
              <ArrowRightIcon fill="#1c3557" cname="h-[11px] w-3 lg:w-4 lg:h-4 ml-auto mt-1" /></div></div>
           </Link>
          </div> {/* END .card */}

        


          <div className='card'>
          <h4 className='card-title pb-0 lg:text-22px'>documents</h4>
           <Link to="#" className="grid grid-cols-5 py-4 bg-gray-light lg:bg-transparent border-b-2 border-b-secondary border-opacity-25">
              <div className="col-span-3"><div className="pl-4  text-xs lg:text-base text-secondary">View Policy</div></div>
              <div className="col-span-2"><div className="pr-9  text-secondary flex">
              <ArrowRightIcon fill="#1c3557" cname="h-[11px] w-3 lg:w-4 lg:h-4 ml-auto mt-1" /></div></div>
           </Link>
           <Link to="#" className="grid grid-cols-5 py-4 bg-gray-light lg:bg-transparent border-b-2 border-b-secondary border-opacity-25">
              <div className="col-span-3"><div className="pl-4  text-xs lg:text-base text-secondary">View Application</div></div>
              <div className="col-span-2"><div className="pr-9  text-secondary flex">
              <ArrowRightIcon fill="#1c3557" cname="h-[11px] w-3 lg:w-4 lg:h-4 ml-auto mt-1" /></div></div>
           </Link>
           <Link to="#" className="grid grid-cols-5 py-4 bg-gray-light lg:bg-transparent border-b-2 border-b-transparent border-opacity-25">
              <div className="col-span-3"><div className="pl-4  text-xs lg:text-base text-secondary">Pending Debits</div></div>
              <div className="col-span-2"><div className="pr-9  text-secondary flex">
              <ArrowRightIcon fill="#1c3557" cname="h-[11px] w-3 lg:w-4 lg:h-4 ml-auto mt-1" /></div></div>
           </Link>
          </div> {/* END .card */}
 


 

              <ActionCardButton title="stop coverage" url="#" linktitle="CALL START HEALTH" />
                {/* END .card */}

     
          </div>
          <div className="lg:col-span-6"></div>
        </div>
      </div>
    </>
  );
};

export default AccountDetails;
