import React from 'react'
import ReactDOM from 'react-dom'
import { Link } from "react-router-dom"

import Card from './Card'
import Button from './Button'
import classes from './ErrorModal.module.css'
import parse from 'html-react-parser'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleXmark
} from '@fortawesome/free-solid-svg-icons'


const Backdrop = (props) => {
    return (
        <div className={classes.backdrop} onClick={props.onConfirm} />
    );
}

const ModalOverlay = (props) => {
    return ( 
        <Card className={classes.modal}>
            <header className="border-t-8 border-cyan px-6 pt-2 h-10">

           
          <h2 className='font-playfair text-secondary text-lg lg:text-2xl'>{props.title}</h2> 
          {props.subtitle && 
            
            <h3 className='text-secondary font-bold uppercase text-10px '>{props.subtitle}</h3>
          }
          <Button onClick={props.onConfirm}>
          <FontAwesomeIcon
                  icon={faCircleXmark}
                  className="text-secondary text-xe"
                />
          </Button>
            </header>
            <div className="popup-content">
            {parse(props.message)}
            </div>

            {props.image && 
            <div className="popup-image w-56 mx-auto py-10">
              <img src={props.image} alt="" />
              </div>
            }

            

            {props.buttonlink && 
            <div className="my-8 px-6 popup-content text-center"><Link to={props.buttonlink} className="btn uppercase">
              {props.buttontext} 
              </Link></div>
            }
            {/* <footer className={classes.actions}></footer> Submit */}
        </Card>
    );
}

const ErrorModal = (props) => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(<Backdrop onConfirm={props.onConfirm} />, document.getElementById('backdrop-root'))}
      {ReactDOM.createPortal(<ModalOverlay onConfirm={props.onConfirm} message={props.message} title={props.title} subtitle={props.subtitle} buttonlink={props.buttonlink} image={props.image} buttontext={props.buttontext} />, document.getElementById('overlay-root'))}
    </React.Fragment>
  );
};

export default ErrorModal;