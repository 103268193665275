import React from 'react'
import { Link } from "react-router-dom";
import SearchBar from '../../components/SearchBar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight,
  faBookmark
} from '@fortawesome/free-solid-svg-icons'
import { useJsApiLoader } from "@react-google-maps/api"
import Map from "../../Map"
import ArrowRightIcon from '../../components/Icons/ArrowRightIcon';
import logoicon from "../../assets/images/logo-icon.svg";

const DoctorDetails = () => {

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCpF6GJcN6LJoYUsRZr_JG5QaIeq4UowWo", 
    id: 'google-map-script'
  });

  return (
    <>
    <SearchBar />
    <header className='container px-30px 2xl:px-0 lg:mt-12 mb-5 lg:mb-12 mt-5 flex'>
  <div className='text-left flex w-full'>
  <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-1 flex">dr. smith
  <img src={logoicon} alt="" className='ml-2 w-5' /></h2>
  <div className="ml-auto">
  <FontAwesomeIcon
            icon={faBookmark}
            className="text-secondary text-base"
          />
  </div>
  </div>
</header>

<div className='container px-0 lg:px-30px 2xl:px-0'>
  <div className='lg:grid lg:grid-cols-12 lg:gap-16'>
      <div className='lg:col-span-6 pb-10'>

      <div className="address mb-8 lg:mb-14 px-30px lg:px-0">
          <h4 className='text-xs lg:text-base text-secondary font-bold uppercase'>CONTACT INFORMATION
</h4>
          <ul className='mt-2'>
            <li className='text-xs lg:text-lg text-secondary'>Phone: XXX-XXX-XXXX</li>
            <li className='text-xs lg:text-lg text-secondary'>Email: info@smithderm.com</li>
            <li className='text-xs lg:text-lg text-secondary'>Address: XXXX S XXXX E Draper, UT</li>
          </ul>
        </div>

        <div className='lg:hidden block mb-8 lg:mb-14 '>
        <h1 className='px-8 text-secondary uppercase font-bold text-xs'>LOCATIONS</h1>
            <div className='mt-2 h-[253px] w-full bg-gray-light flex justify-center items-center'> { isLoaded && <Map /> } </div>
        </div>


        <div className='list'>
        <div className='flex  items-center py-3 pl-8 lg:pl-12 pr-5'>
        <h4 className='flex-grow text-xs lg:text-base text-secondary font-bold uppercase'>SERVICES</h4>
        </div>
        <Link to="#" className='flex items-center bg-gray-light border-t-2 border-t-secondary border-opacity-25 py-3 lg:py-4 pl-8 lg:pl-12 pr-5 lg:pr-7'>
        <h4 className='flex-grow text-xs lg:text-base text-secondary'>Cyst Removal</h4>
          <ArrowRightIcon fill="#1c3557" cname="h-[11px] w-[12px] lg:h-[16.53px] lg:w-[15.99px]"/>
        </Link>
        <Link to="#" className='flex  items-center bg-gray-light border-t-2 border-t-secondary border-opacity-25 py-3 pl-8 lg:pl-12 pr-5 lg:pr-7'>
        <h4 className='flex-grow text-xs lg:text-base text-secondary'>General Consultation</h4>
        <ArrowRightIcon fill="#1c3557" cname="h-[11px] w-[12px] lg:h-[16.53px] lg:w-[15.99px]"/>
        </Link>
        <Link to="#" className='flex  items-center bg-gray-light border-t-2 border-t-secondary border-opacity-25 py-3 pl-8 lg:pl-12 pr-5 lg:pr-7'>
        <h4 className='flex-grow text-xs lg:text-base text-secondary'>Acutane Treatment</h4>
        <ArrowRightIcon fill="#1c3557" cname="h-[11px] w-[12px] lg:h-[16.53px] lg:w-[15.99px]"/>
        </Link>
        </div>

      </div>
      <div className='lg:col-span-6 hidden lg:block'>
             <h1 className='px-8 pl-0 text-secondary uppercase font-bold text-base'>LOCATIONS</h1>
            <div className='mt-2   w-full flex justify-center items-center'> { isLoaded && <Map /> } </div>
      </div>
  </div>
</div>
    </>
  )
}

export default DoctorDetails