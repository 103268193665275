import React from 'react'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation, faPlus } from "@fortawesome/free-solid-svg-icons";
import ArrowRightIcon from '../../components/Icons/ArrowRightIcon';

import ActionCardButton from '../../components/Button/ActionCardButton'
import iconRecipt from "../../assets/images/svg/bookmark-solid.svg";

const UploadAnExpense = () => {
  return (
    <>
       <header className='container px-30px 2xl:px-0 lg:mt-12 mb-5 lg:mb-0 mt-5 flex'>
        <div className="text-left">
          <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-1">which expense is it for?</h2>
          <p className="text-secondary text-xs font-bold lg:text-base hidden lg:block">MEMBER: JANE DOE</p>
        </div>
      </header>


     <div className="lg:py-8 container lg:px-30px 2xl:px-0">
            <div className="lg:grid lg:grid-cols-12 lg:gap-14">
                <div className="col-span-9">

<div className='grid grid-cols-5 py-4'>
    <div className='col-span-3'><div className='pl-8 text-xs text-secondary font-bold lg:text-base'>
      <span className='hidden lg:block'>EXPENSE HISTORY</span>
      <span className='lg:hidden'>OPEN HISTORY</span>
      </div></div>
    <div className='col-span-2'><div className='text-secondary text-xs lg:text-lg pr-8 lg:text-right max-w-[114px] lg:max-w-[142px] ml-auto'>Amount Paid</div></div>
</div>

<div className='grid grid-cols-5 py-4 bg-gray-light lg:bg-transparent border-t-2 border-t-secondary border-opacity-25'>
    <div className='col-span-3'>
    <div className='pl-8'>
                        <div className="text-secondary text-xs lg:text-base mb-1">Mountain Point #4321 
                        <FontAwesomeIcon icon={faCircleExclamation} className="text-red text-xs ml-1 cursor-pointer" /></div>
                        <div className="text-secondary text-8px lg:text-10px font-bold leading-1 lg:leading-3">
                            MEMBER: JANE DOE <br/>
                            02/22/22
                        </div>

                        <div className="mt-4 flex items-center">
                            <Link to="/pre-deductible-2"
                                className="py-1 text-center w-[64px] inline-block rounded-5px text-10px bg-primary text-white cursor-pointer font-medium uppercase">PENDING</Link>
                        </div>
                    </div>
    </div>
    <div className='col-span-2'><div className='text-secondary text-xs lg:text-lg  pr-8 font-bold lg:text-right max-w-[114px]  lg:max-w-[142px] ml-auto'>$439.75</div></div>
</div>
{/* item #1 */}



<div className='py-4 bg-gray-light lg:bg-transparent border-t-2 border-t-secondary border-opacity-25'>
    <div className='col-span-5'>
      <div className='lg:w-60 mx-30px'>
          <Link
              to="/receipt-uploaded"
              className="btn uppercase w-full flex justify-center items-center cursor-pointer bg-primary"
            >
              <FontAwesomeIcon
                icon={faPlus}
                className="text-white w-2.5 mr-2  lg:hidden"
              /> 
              NEW EXPENSE
              <ArrowRightIcon fill="#ffffff" cname="h-[11px] w-3 lg:w-[10.98px] lg:h-[10.85px] ml-auto" />
            </Link> 
            </div>
    </div>
</div>
{/* item #6 */}

<div className='py-4 bg-gray-light lg:bg-transparent h-[29rem]'></div>

</div>

                {/*  */}
                <div className="col-span-3 hidden lg:block">
                <div className='mb-6'>
                    <h2 className='font-playfair text-secondary font-bold text-[32px] mb-1'>questions?</h2>
                    <p className='text-lg text-secondary'>Need help? Just give us a call at
                801-XXX-XXXX and we’d be happy
                to help.</p>
                    </div>

                    {/* <div className="bg-gray-light w-full border-t-6 border-cyan rounded-md mb-6">
              <Link to="/favorites" className='block p-3'>
                <div className="text-gray-light h-14 flex justify-left items-left">
                  <FontAwesomeIcon
                    icon={faBookmark}
                    className="text-secondary text-base"
                  />
                </div>
                <div className="flex items-center">
                  <div className="text-secondary text-10px lg:lg:text-sm font-bold uppercase">
                    VIEW FAVORITES
                  </div>
                  <div className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg"   className='h-[11px] w-3 lg:w-4 lg:h-4  ml-auto' viewBox="0 0 15.993 16.529">
                    <path id="arrow-right" d="M7.786,34.717l-.707.739a.463.463,0,0,0,0,.633l5.538,5.79H.428A.438.438,0,0,0,0,42.327v1.045a.438.438,0,0,0,.428.448H12.617l-5.538,5.79a.463.463,0,0,0,0,.633l.707.739a.415.415,0,0,0,.606,0l7.476-7.816a.463.463,0,0,0,0-.633L8.392,34.717A.415.415,0,0,0,7.786,34.717Z" transform="translate(0 -34.586)" fill="#1c3557"/>
                    </svg>
                  </div>
                </div>
              </Link>
            </div> */}
            <ActionCardButton title="" url="/favorites" linktitle="VIEW FAVORITES" icon={true} iconimage={iconRecipt} iconcname="w-[13.76px] h-[18.35px]" />
            {/* END  */}

            <div className="bg-gray-light w-full border-t-6 border-cyan rounded-md">
              <Link to="#" className='block p-3'>
                <div className="flex items-center">
                  <div className="text-secondary text-10px lg:text-sm font-bold uppercase">
                  REFER A FRIEND
                  </div>
                  <div className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg"   className='h-[11px] w-3 lg:w-4 lg:h-4  ml-auto' viewBox="0 0 15.993 16.529">
                    <path id="arrow-right" d="M7.786,34.717l-.707.739a.463.463,0,0,0,0,.633l5.538,5.79H.428A.438.438,0,0,0,0,42.327v1.045a.438.438,0,0,0,.428.448H12.617l-5.538,5.79a.463.463,0,0,0,0,.633l.707.739a.415.415,0,0,0,.606,0l7.476-7.816a.463.463,0,0,0,0-.633L8.392,34.717A.415.415,0,0,0,7.786,34.717Z" transform="translate(0 -34.586)" fill="#1c3557"/>
                    </svg>
                  </div>
                </div>
              </Link>
            </div>


                </div>
                 {/*  ENd */}


                
            </div>
        </div>

    </>
  )
}
 
export default UploadAnExpense