import React from 'react'
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CircleIconButton = (props) => {
  return (
    <>
        <Link to={props.xurl}>
                  <div className="w-14 h-14 inline-flex items-center justify-center rounded-full bg-cyan mb-1 lg:mb-2">
                    <FontAwesomeIcon
                      icon={props.faxicon}
                      className="text-white w-7 h-7"
                    />
                  </div>
                  <h3 className="text-xs lg:text-base text-secondary">{props.title}</h3>
                </Link>
    </>
  )
}

export default CircleIconButton