import React, { useState, useCallback,  Suspense, lazy } from 'react'

const EnterYourEmail = lazy(() => import('./EnterYourEmail'))
const Wheredoyoulive = lazy(() => import('./Wheredoyoulive'))
const AddInitialMember = lazy(() => import('./AddInitialMember'))
const SelectYourQuoteforIndividual = lazy(() => import('./SelectYourQuoteforIndividual'))
const SelectGoLiveDate = lazy(() => import('./SelectGoLiveDate'))
const Checkout = lazy(() => import('./Checkout'))
const Confirmation = lazy(() => import('./Confirmation'))

const RegistrationNew = () => {

    const [registerStep, setRegisterStep] = useState(0)

	const handleNext = useCallback( () => {
		setRegisterStep(registerStep + 1)
	}, [registerStep])

	const handleBack = useCallback( () => {
		setRegisterStep(registerStep - 1)
	}, [registerStep])

	const handleSkip = () => {
		setRegisterStep(4)
	}

  return (
    <>
    <Suspense fallback={<></>}>
    { registerStep === 0 && <EnterYourEmail onNext={handleNext} onSkip={handleSkip} /> }
    { registerStep === 1 && <Wheredoyoulive onNext={handleNext} onBack={handleBack} /> }
    { registerStep === 2 && <AddInitialMember onNext={handleNext} onBack={handleBack} /> }
    { registerStep === 3 && <SelectYourQuoteforIndividual onNext={handleNext} onBack={handleBack}/> }
    { registerStep === 4 && <SelectGoLiveDate onNext={handleNext} onBack={handleBack}/> }
    { registerStep === 5 && <Checkout onNext={handleNext} onBack={handleBack}/> }
    { registerStep === 6 && <Confirmation onNext={handleNext} onBack={handleBack}/> }
    </Suspense>

        
        
    </>
  )
}

export default RegistrationNew