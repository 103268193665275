import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight, faCircleExclamation
} from '@fortawesome/free-solid-svg-icons'
//import Button from '../../../../components/Button';

function AccountOrdering() {

  const [checked, setChecked] = useState(true);

  useEffect(() => {
    document.title = 'Account Ordering';
  }, []);

  return (
    <>
      <div className="h-full lg:h-auto lg:min-h-[calc(100vh_-_107px)] flex lg:justify-between lg:items-center">
        <div className="h-full flex flex-col justify-between items-center lg:h-auto w-full pt-12 lg:py-0 px-8 lg:px-0 min-h-[calc(100vh_-_60px)] lg:min-h-full">
        <div className="max-w-627px mx-auto w-full">
        <div className="text-center mb-5 lg:mb-12">
            <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-2">
            how do you want to pay?
            </h2>
            <p className='text-cyan text-xs font-bold lg:text-lg'>Please select desired account to pay for expenses.</p>
        </div>
        

        <div className="" action="#">

<ul className='bg-gray-light border-t-6 rounded-l-5px rounded-r-5px border-cyan'>
  <li className='p-4'>
    <h3 className='font-playfair text-secondary text-lg  lg:text-22px'>account ordering <FontAwesomeIcon icon={faCircleExclamation} className="text-secondary text-xs ml-1" /></h3>
  </li>
  <li className=''>
    <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
      <span className=" text-xs text-secondary lg:order-1 lg:ml-5  lg:text-base">Start HSA Account</span>
      <input type="radio" className='form-radio lg:order-0 ml-auto lg:ml-0' name='accountOrdering' value="option1" defaultChecked={checked}
      onChange={() => setChecked(!checked)} />
    </label>
  </li>
  <li className=''>
    <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">

      <span className=" text-xs text-secondary  lg:order-1 lg:ml-5  lg:text-base">Jane’s Checking Account</span>
      <input type="radio" className='form-radio  lg:order-0 ml-auto lg:ml-0' name='accountOrdering'  value="option2" onChange={() => setChecked(!checked)}/>
    </label>
  </li>
  <li className='p-4'>
    <Link to="/add-bank-account" className='btn uppercase w-full flex justify-center items-center cursor-pointer'>ADD ACCOUNT
      <FontAwesomeIcon icon={faArrowRight} className="text-white w-2.5 ml-auto" />
    </Link>
  </li>
</ul>

<div className="text-center mt-7 mb-5"><Link to="/select-reimbursement-preference" className='btn  uppercase'>Continue</Link></div>
<div className='text-center'><Link to="/open-start-hsa" className='text-xs font-bold text-secondary uppercase'>Go Back</Link></div>


{/* <Button className="btn">
ADD ACCOUNT

</Button> */}
</div>

        </div>
        

        

        
        </div>
      </div>
    </>
  )
}

export default AccountOrdering