import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import Calendar from 'react-calendar'
//import 'react-calendar/dist/Calendar.css'
import dayjs from 'dayjs';

const SelectGoLiveDate = () => {
  const navigateurl  = useNavigate();
  const url = '/checkout';

  const [date, changeDate] = useState(new Date())

  const changeDay = (value) => {
      changeDate(value);
      navigateurl(url);
  }

  return (
    <>
      <div className="h-full lg:h-auto lg:min-h-[calc(100vh_-_107px)] flex lg:justify-between lg:items-center">
        <div className="h-full flex flex-col justify-between items-center lg:h-auto w-full pt-12 lg:py-0 px-8 lg:px-0 min-h-[calc(100vh_-_60px)] lg:min-h-full">
          <div className="max-w-lg mx-auto w-full">
            <div className="text-center mb-5 lg:mb-7">
              <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-2">
                select your effective date.
              </h2>
              <p className='text-cyan text-xs font-bold lg:text-lg'>Please select your desired effective date with Start.</p>
            </div>

            <Calendar
              onChange={changeDate}
              value={date}
              minDate={new Date()}
              maxDate={new Date(new Date().getFullYear(), 11, 31)}
              formatMonthYear={(locale, date) => dayjs(date).format('MMMM')}
              onClickDay = {changeDay}
            />

          </div>
        </div>
      </div>
    </>
  )
}

export default SelectGoLiveDate