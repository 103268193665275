import React from 'react'
import { Link } from "react-router-dom"
import ArrowDownIcon from '../../components/Icons/ArrowDownIcon'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faBookmark, faDollarSign, faChartLine, faCreditCard, faReceipt, faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import CustomSelect from '../../components/Select'

const TransactionHistory = () => {

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ];

    const years = [
        { value: '2020', label: '2020' },
        { value: '2021', label: '2021' },
        { value: '2022', label: '2022' }
    ];

    return (
        <>
            <header className='container px-30px 2xl:px-0 lg:mt-12 mb-5 mt-5 flex'>
                <div className="text-left">
                    <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-1">history</h2>
                    <p className="text-secondary text-xs font-bold lg:text-base">MEMBER: JANE DOE</p>
                </div>

                <div className='ml-auto'>
                    <div className='flex items-center'>
                        <div className="pr-5 hidden lg:block"><Link to="" className='text-base font-bold text-cyan'>DOWNLOAD FILE <ArrowDownIcon /> </Link></div>
                        <div className="w-72 pr-4  hidden lg:block">
                            <CustomSelect phtext="Filter By…" optionsval={options} />
                        </div>
                        <div className="lg:w-72">
                            <CustomSelect phtext="Select Year" optionsval={years} />
                        </div>
                    </div>
                </div>
            </header>

            <div className="lg:py-8 container lg:px-30px 2xl:px-0">

                <div className='grid  grid-cols-5 lg:grid-cols-9 py-4'>
                    <div className='col-span-3 lg:col-span-2'><div className='pl-8 text-xs text-secondary font-bold lg:text-base'>TRANSACTIONS</div></div>
                    <div className="col-span-2 hidden lg:block text-secondary">Type</div>
                    <div className="col-span-2 hidden lg:block text-secondary">Status</div>
                    <div className="col-span-2 hidden lg:block text-secondary">Date</div>
                    <div className='col-span-2 lg:col-span-1 lg:text-lg text-right pr-8 text-secondary text-xs'>Amount</div>
                </div>

                <div className='grid grid-cols-5 lg:grid-cols-9 py-4 bg-gray-light lg:bg-transparent border-t-2 border-t-secondary border-opacity-25'>
                    <div className='col-span-3 lg:col-span-2'>
                        <div className='pl-8'>
                            <div className="text-secondary text-xs lg:text-base mb-1">Mountain Point #4321</div>
                            <div className="text-secondary text-8px font-bold uppercase lg:hidden">
                                USER WITHDRAW <br />
                                02/22/22
                            </div>

                            <div className="mt-4 flex items-center">
                                <Link to="/pre-deductible-2"
                                    className="py-1 text-center w-[64px] inline-block rounded-5px text-10px bg-primary text-white cursor-pointer font-medium uppercase">PENDING</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-2 hidden text-secondary lg:block">User Withdraw</div>
                    <div className="col-span-2 hidden text-secondary lg:block">Pending</div>
                    <div className="col-span-2 hidden text-secondary lg:block">02/22/22</div>
                    <div className='col-span-2 lg:col-span-1 lg:text-lg text-right pr-8 text-secondary text-xs font-bold'>- $23.83</div>
                </div>
                {/* item #1 */}

                <div className='grid grid-cols-5 lg:grid-cols-9 py-4 bg-gray-light lg:bg-transparent border-t-2 border-t-secondary border-opacity-25'>
                    <div className='col-span-3 lg:col-span-2'>
                        <div className='pl-8'>
                            <div className="text-secondary text-xs  lg:text-base mb-1">Interest Earned</div>
                            <div className="text-secondary text-8px font-bold uppercase lg:hidden">
                                Start Reimbursement  <br />
                                01/12/22
                            </div>

                            <div className="mt-4 flex items-center">
                                <Link to="/pre-deductible-2"
                                    className="py-1 text-center w-[64px] inline-block rounded-5px text-10px bg-cyan text-white cursor-pointer font-medium uppercase">Complete</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-2 hidden lg:block text-secondary">Interest Earned</div>
                    <div className="col-span-2 hidden lg:block text-secondary">Complete</div>
                    <div className="col-span-2 hidden lg:block text-secondary">01/12/22</div>
                    <div className='col-span-2 lg:col-span-1 lg:text-lg text-right pr-8 text-secondary text-xs font-bold'>- $18.54</div>
                </div>
                {/* item #1 */}

                <div className='grid grid-cols-5 lg:grid-cols-9 py-4 bg-gray-light lg:bg-transparent border-t-2 border-t-secondary border-opacity-25'>
                    <div className='col-span-3 lg:col-span-2'>
                        <div className='pl-8'>
                            <div className="text-secondary text-xs lg:text-base mb-1">Start Reimbursement</div>
                            <div className="text-secondary text-8px font-bold uppercase lg:hidden">
                                Start Reimbursement  <br />
                                08/06/21
                            </div>

                            <div className="mt-4 flex items-center">
                                <Link to="/pre-deductible-2"
                                    className="py-1 text-center w-[64px] inline-block rounded-5px text-10px bg-cyan text-white cursor-pointer font-medium uppercase">Complete</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-2 hidden lg:block text-secondary">Start Reimbursement</div>
                    <div className="col-span-2 hidden lg:block text-secondary">Complete</div>
                    <div className="col-span-2 hidden lg:block text-secondary">08/06/21</div>
                    <div className='col-span-2 lg:col-span-1 lg:text-lg text-right pr-8 text-secondary text-xs font-bold'>- $1.70</div>
                </div>
                {/* item #1 */}

                <div className='grid grid-cols-5 lg:grid-cols-9 py-4 bg-gray-light lg:bg-transparent border-t-2 border-t-secondary border-opacity-25'>
                    <div className='col-span-3 lg:col-span-2'>
                        <div className='pl-8'>
                            <div className="text-secondary text-xs lg:text-base mb-1">User Direct Deposit</div>
                            <div className="text-secondary text-8px font-bold lg:hidden">
                                USER WITHDRAW <br />
                                03/28/21
                            </div>

                            <div className="mt-4 flex items-center">
                                <Link to="/pre-deductible-2"
                                    className="py-1 text-center w-[64px] inline-block rounded-5px text-10px bg-cyan text-white cursor-pointer font-medium uppercase">Complete</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-2 hidden lg:block text-secondary">User Contribution</div>
                    <div className="col-span-2 hidden lg:block text-secondary">Complete</div>
                    <div className="col-span-2 hidden lg:block text-secondary">03/28/21</div>
                    <div className='col-span-2 lg:col-span-1 lg:text-lg text-right pr-8 text-secondary text-xs font-bold'>+ $2.80</div>
                </div>
                {/* item #1 */}

            </div>
        </>
    )
}

export default TransactionHistory