import React, {useEffect, useState} from 'react'
import { Link } from "react-router-dom";
import Button from '../../../../components/Button';
import Input from "../../../../components/Input"

function SetPassword() {

  useEffect(()=>{
    document.title = 'Set Password';
  }, []);

 
const handleChange = (event) => {
    const { value, name } = event.target;
    this.useState({ [name]: value });
}


  return (
    <>
   <div className="h-full lg:h-auto lg:min-h-[calc(100vh_-_107px)] flex lg:justify-between lg:items-center">
        <div className="h-full flex flex-col justify-between items-center lg:h-auto w-full pt-12 lg:py-0 px-8 lg:px-0 min-h-[calc(100vh_-_60px)] lg:min-h-full">
        <div className="max-w-md mx-auto w-full">
        <div className="text-center mb-5 lg:mb-7">
            <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-2">hi, jane doe 👋</h2>
            <p className='text-cyan text-xs font-bold lg:text-lg'>Please set your account password.</p>
        </div>
        <form action=''>
          <div className="mb-3">
            <label className="block">
                  <span className="text-secondary text-xs lg:text-lg block mb-3">Please enter a password.</span>
                  <Input 
                  type="password" 
                  className="form-control" 
                  placeholder="Password"
                  handleChange={handleChange}
                  />
            </label>
          </div>

          <div className="mb-3">
            <label className="block">
                  <span className="text-secondary text-xs lg:text-lg block mb-3">Confirm password.</span>
                  <Input 
                    type="password" 
                    className="form-control" 
                    placeholder="Confirm Password"
                    handleChange={handleChange} 
                    />
            </label>
          </div>

  <div className="text-center mt-7 mb-5">
    <Link to="/enter-address" className='btn uppercase'>Continue</Link>
 </div>
  <div className='text-center'><Link to="/select-member" className='text-xs font-bold text-secondary uppercase'>GO BACK</Link></div>

      </form>
        </div>
        
      

        
        </div>
      </div>
    </>
  )
}

export default SetPassword