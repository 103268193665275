import React from "react";
import { Outlet } from "react-router-dom"
import Footer from "../components/Footer"
import Header from "../components/Header/HeaderBeforeRegistration"

const AppLayoutBeforeLogin = () => {
  return (
    <>
       <div className="wrapper relative min-h-screen">
    <Header />
    <main id="main" className="lg:pb-[48px] min-h-[calc(100vh_-_70px)]">
      <Outlet />
    </main>
    <Footer />
    </div>
    </>
  )
}

export default AppLayoutBeforeLogin