import React from 'react'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Input from "../../../components/Input";

const Checkout = () => {
  return (
    <>
<div className="h-full lg:h-auto lg:min-h-[calc(100vh_-_107px)] flex lg:justify-between lg:items-center">
        <div className="h-full flex flex-col justify-between items-center lg:h-auto w-full pt-12    lg:px-0 min-h-[calc(100vh_-_60px)] lg:min-h-full">
        <div className="container px-30px 2xl:px-0">
        <div className="text-center mb-5 lg:mb-12">
            <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-2">you’re almost done!</h2>
            <p className='text-cyan text-xs font-bold lg:text-lg'>Please enter your banking information to checkout.</p>
            <p className='pt-5 text-xs text-secondary text-left  lg:hidden'>This will be the account charged for your monthly premium. During your account set up, you’ll have the option to use this account for your Start card. If you don’t add and select a different account, this will be the default account your Start card will pull from.</p>
        </div>
        <form className="" action="#">
        <div className="lg:grid lg:grid-cols-12 lg:gap-16">
        
        
        <div className="lg:col-span-6">
        <div className="mb-3">
        <h3 class="hidden lg:block uppercase text-lg text-secondary font-bold">YOUR BANK INFORMATION</h3>
          </div>
        <div className="mb-3">
            <div className="w-full">
                <Input 
                        type="text"
                        className="form-control"
                        placeholder="Bank Name"
                />
                </div>
            </div>

            <div className="mb-3">
            <div className="w-full">
                <Input
                        type="text"
                        className="form-control"
                        placeholder="Routing Number (9 Digits)"
                />
                </div>
            </div>

            <div className="mb-3">
            <div className="w-full">
                <Input
                        type="text"
                        className="form-control"
                        placeholder="Confirm Routing Number"
                />
                </div>
            </div>

            <div className="mb-3">
            <div className="w-full">
                <Input
                        type="text"
                        className="form-control"
                        placeholder="Account Number"
                />
                </div>
            </div>

            <div className="mb-3">
            <div className="w-full">
                <Input
                        type="text"
                        className="form-control"
                        placeholder="Confirm Account Number"
                />
                </div>
            </div>


            <div className="mb-5 mt-6">
                <span className='text-xs text-secondary block mb-2 lg:text-lg'>Please select the account type:</span>
            <ul className='bg-gray-light border-t-[6px] rounded-md border-cyan'>
              <li className=''>
              <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                      <Input type="radio" value="option1" name='selectAccountType' className='form-radio text-secondary' checked />
                      <span className="ml-2 text-xs lg:text-base text-secondary">Checking</span>
                    </label>
              </li>
              <li className=''>
              <label className="inline-flex items-center w-full p-4 cursor-pointer">
                      <Input type="radio" value="option2" className='form-radio text-secondary' name='selectAccountType' />
                      <span className="ml-2 text-xs lg:text-base text-secondary">Saving</span>
                    </label>
              </li>
            </ul>
            </div>


 
        </div>

          <div className="lg:col-span-6">
          <div className="bg-transparent lg:bg-gray-light border-0 lg:border-t-6 rounded-md border-cyan">
            <div className='text-lg leading-6 px-8 text-secondary pt-7 hidden lg:block'>
            This will be the account charged for your monthly premium. During your account set up, you’ll have the option to use this account for your Start card. If you don’t add and select a different account, this will be the default account your Start card will pull from.
              </div>
          <div className="text-center mt-7 pb-5 lg:pb-8 px-8">
            <Link to="/confirmation" className='btn uppercase w-full lg:flex lg:justify-center items-center cursor-pointer bg-primary' >
              Continue
              <FontAwesomeIcon
                icon={faArrowRight}
                className="text-white w-3 ml-auto hidden lg:inline-block"
              />
            </Link>
            
            
            </div>
  <div className='text-center'><Link to="/select-go-live-date" className='text-xs font-bold text-secondary uppercase lg:hidden block'>Go Back</Link></div>
          </div>
          </div>
          
        </div>
        </form>
        </div>
         
      

        
        </div>
      </div>
    </>
  )
}

export default Checkout