import React, {useState} from 'react'
import { Link } from "react-router-dom";
import { ActionLink } from '../../components/shared'
import ActionCardButton from '../../components/Button/ActionCardButton';
import iconRecipt from "../../assets/images/svg/receipt-solid.svg";
import ArrowDownIcon from "../../components/Icons/ArrowDownIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleExclamation} from "@fortawesome/free-solid-svg-icons";
import ErrorModal from "../../components/UI/ErrorModal";
import SearchBar from "../../components/SearchBar";
import CardButtonNew from '../../components/Button/CardButtonNew';
import iconFindCare from "../../assets/images/svg/magnifying-glass-dollar-solid.svg";

const Prescription = () => {



    //

    const [isOpen, setIsOpen] = useState(false);

    function openModal(event, modalData){
        setIsOpen({
            title: modalData.title,
            message: modalData.message,
            buttonlink: modalData.buttonlink,
            buttontext: modalData.buttontext
        });
        return;
    }

    const popupHandler = () => {
        setIsOpen(null);
    };

    const modalObj = {
        title: 'additional info. needed',
        message: '<div className="my-4 pl-6 pr-10 lg:text-sm"><p className="text-sm font-bold mb-2">What you need to provide:</p><p>To receive your benefits, please provide the following information in your proof of care no later than 30 days after receiving care. </p><ul className="text-xs lg:text-sm"><li>Patient’s full name</li><li>Provider’s name and address</li><li>Date(s) of service</li><li>Charge amount(s)</li><li>CPT / Procedure code(s) </li><li>Prescription drug(s) name, form, strength  and dosage or NDC code.</li><li>Diagnosis code(s)</li></ul></div>',
        buttonlink: '',
        buttontext: ''
    }

    const preapproveObj = {
        title: 'pre-approval',
        message: '<div className="my-4 pl-6 pr-10 lg:text-sm"><p className="font-bold mb-2">Pre-Approval Expense:</p><p>Any planned non-emergent <strong>expense over $2,000</strong> requires a pre-approval request.</p><p>Your request will be reviewed by Start, and once approved your Start card limit will increase.</p><p className="font-bold mb-2">Requires the following information:</p><ul className="text-xs lg:text-sm"><li>Procedure name</li><li>Provider\'s name</li><li>Expected date(s) of service</li><li>Itemized quote</li><li>Medical records</li></ul></div>',
        buttonlink: '/pre-approval',
        buttontext: 'CONTINUE'
    }

    return (
    <>
        <SearchBar page="Prescription"/>
    <div className='container px-30px 2xl:px-0 mb-5 lg:mb-0'>
        <div className="text-left lg:mt-12 lg-5 mt-5">
          <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-1">prescription</h2>
          <p className="text-secondary text-xs font-bold lg:text-base mb-2  hidden lg:block">MEMBER: JANE DOE</p>
          <div className="lg:grid lg:grid-cols-12 lg:gap-10 mt-2">
<div className="lg:col-span-6">
 <p className='text-secondary text-xs lg:text-base'>Start encourages members to utilize <ActionLink  href="https://www.goodrx.com/" target="_blank" className="font-bold">GoodRx</ActionLink> to find 
the best price for their medications. Once purchased, 
Start requires that you upload an itemized receipt so 
that the expense is applied towards your plan’s 
defined benefit amounts. </p>
</div>
</div>
        </div>
    </div>

        <div className='container lg:px-30px 2xl:px-0'>
        <div className="lg:grid lg:grid-cols-12 lg:gap-10">
          <div className="lg:col-span-6">
              <div className="grid lg:block grid-cols-12 gap-7 lg:gap-14 px-30px lg:px-0 ">
                  <div className="col-span-6 lg:col-span-3 lg:mb-6 hidden lg:block">
                      <ActionCardButton title="goodrx portal" href="https://www.goodrx.com/" target="_blank" linktitle="LAUNCH" />
                  </div>
                  <div className="col-span-6 lg:col-span-3 hidden lg:block">
                      <ActionCardButton title="" url="/upload-expense" linktitle="UPLOAD RECEIPT" icon={true} iconimage={iconRecipt} iconcname="w-[9.6px] h-[12.79px] lg:w-[16.6px] lg:h-[22.14px]" />
                  </div>

                  <div className="col-span-6 lg:col-span-3 lg:mb-6 block lg:hidden">
                  <CardButtonNew title="GOODRX PORTAL" xicon={iconFindCare} href="https://www.google.com/" target="_blank" imageClassName="w-[32.4px] h-[32.04px] lg:w-[35.34px] lg:h-[40.39px] mx-auto lg:m-0" />
                  </div>
                  <div className="col-span-6 lg:col-span-3 block lg:hidden">
                  <CardButtonNew  title="EXPENSES" xicon={iconRecipt} url="/expenses" imageClassName="w-[21.8px] h-[29.06px] lg:w-[35.34px] lg:h-[40.39px] mx-auto lg:m-0" />
                  </div>
              </div>
          </div>
           <div className="lg:col-span-6">
               <div className='mt-5 lg:mt-8'>
                   <div className='grid grid-cols-5 py-4'>
                       <div className='col-span-3'><div className='pl-8 text-xs text-secondary font-bold lg:text-base'>EXPENSE HISTORY</div></div>
                       <div className='col-span-2'><div className='text-secondary text-xs lg:text-lg pr-8 lg:text-right max-w-[114px] lg:max-w-[142px] ml-auto'>Amount Paid</div></div>
                   </div>

                   <div className='grid grid-cols-5 py-4 bg-gray-light lg:bg-transparent border-t-2 border-t-secondary border-opacity-25'>
                       <div className='col-span-3'>
                           <div className='pl-8'>
                               <div className="text-secondary text-xs lg:text-base mb-1">Mountain Point #4321
                                   <FontAwesomeIcon
                                       icon={faCircleExclamation}
                                       className="text-red text-xe ml-1 cursor-pointer"
                                       onClick={(e) => {openModal(e, modalObj) }}
                                   />


                                   {isOpen && (
                                       <ErrorModal
                                           title={isOpen.title}
                                           message={isOpen.message}
                                           buttonlink={isOpen.buttonlink}
                                           buttontext={isOpen.buttontext}
                                           onConfirm={popupHandler}
                                       />
                                   )}
                               </div>
                               <div className="text-secondary text-8px lg:text-10px font-bold leading-1 lg:leading-3">
                                   MEMBER: JANE DOE <br/>
                                   02/22/22
                               </div>

                               <div className="mt-4 flex items-center">
                                   <Link to="/pre-deductible-2"
                                         className="py-1 text-center w-[64px] inline-block rounded-5px text-10px bg-primary text-white cursor-pointer font-medium uppercase">PENDING</Link>
                               </div>
                           </div>
                       </div>
                       <div className='col-span-2'><div className='text-secondary text-xs lg:text-lg  pr-8 font-bold lg:text-right max-w-[114px]  lg:max-w-[142px] ml-auto'>$439.75</div></div>
                   </div>
                   {/* item #1 */}

                   <div className='grid grid-cols-5 py-4 bg-gray-light lg:bg-transparent border-t-2 border-t-secondary border-opacity-25'>
                       <div className='col-span-3'>
                           <div className='pl-8'>
                               <div className="text-secondary text-xs lg:text-base mb-1">Smiths #5232</div>
                               <div className="text-secondary text-8px lg:text-10px font-bold leading-1 lg:leading-3">
                                   MEMBER: JANE DOE <br/>
                                   01/12/22
                               </div>

                               <div className="mt-4 flex items-center">
                                   <Link to="/pre-deductible-2"
                                         className="py-1 text-center w-[64px] inline-block rounded-5px text-10px bg-cyan text-white cursor-pointer font-medium uppercase">complete</Link>
                               </div>
                           </div>
                       </div>
                       <div className='col-span-2'><div className='text-secondary text-xs lg:text-lg  pr-8 font-bold lg:text-right max-w-[114px]  lg:max-w-[142px] ml-auto'>$56.32</div></div>
                   </div>
                   {/* item #2 */}

                   <div className='grid grid-cols-5 py-4 bg-gray-light lg:bg-transparent border-t-2 border-t-secondary border-opacity-25'>
                       <div className='col-span-3'>
                           <div className='pl-8'>
                               <div className="text-secondary text-xs lg:text-base mb-1">Family Medicine #7381</div>
                               <div className="text-secondary text-8px lg:text-10px font-bold leading-1 lg:leading-3">
                                   MEMBER: JANE DOE <br/>
                                   08/06/21
                               </div>

                               <div className="mt-4 flex items-center">
                                   <Link to="/pre-deductible-2"
                                         className="py-1 text-center w-[64px] inline-block rounded-5px text-10px bg-cyan text-white cursor-pointer font-medium uppercase">complete</Link>
                               </div>
                           </div>
                       </div>
                       <div className='col-span-2'><div className='text-secondary text-xs lg:text-lg  pr-8 font-bold lg:text-right max-w-[114px]  lg:max-w-[142px] ml-auto'>$20.63</div></div>
                   </div>
                   {/* item #3 */}

                   <div className='grid grid-cols-5 py-4 bg-gray-light lg:bg-transparent border-t-2 border-t-secondary border-opacity-25'>
                       <div className='col-span-3'>
                           <div className='pl-8'>
                               <div className="text-secondary text-xs lg:text-base mb-1">Family Medicine #2493</div>
                               <div className="text-secondary text-8px lg:text-10px font-bold leading-1 lg:leading-3">
                                   MEMBER: JANE DOE <br/>
                                   03/28/21
                               </div>

                               <div className="mt-4 flex items-center">
                                   <Link to="/pre-deductible-2"
                                         className="py-1 text-center w-[64px] inline-block rounded-5px text-10px bg-cyan text-white cursor-pointer font-medium uppercase">complete</Link>
                               </div>
                           </div>
                       </div>
                       <div className='col-span-2'><div className='text-secondary text-xs lg:text-lg  pr-8 font-bold lg:text-right max-w-[114px]  lg:max-w-[142px] ml-auto'>$72.50</div></div>
                   </div>
                   {/* item #4 */}

                   <div className='grid grid-cols-5 py-4 bg-gray-light lg:bg-transparent border-t-2 border-t-secondary border-b-2 border-b-secondary border-opacity-25'>
                       <div className='col-span-3'>
                           <div className='pl-8'>
                               <div className="text-secondary text-xs lg:text-base mb-1">Unknown #2673</div>
                               <div className="text-secondary text-8px lg:text-10px font-bold leading-1 lg:leading-3">
                                   MEMBER: JANE DOE <br/>
                                   03/28/21
                               </div>

                               <div className="mt-4 flex items-center">
                            <span
                                className="py-1 text-center w-[64px] inline-block rounded-5px text-10px bg-secondary text-white font-medium uppercase">denied</span>
                                   <span className="ml-2 text-secondary text-6px font-bold">
NOT COVERED <br/>BY START
                      </span>
                               </div>
                           </div>
                       </div>
                       <div className='col-span-2'><div className='text-secondary text-xs lg:text-lg  pr-8 font-bold lg:text-right max-w-[114px]  lg:max-w-[142px] ml-auto'>$5.00</div></div>
                   </div>
                   {/* item #5 */}


               </div>




           </div>
        </div>
        </div>
    </>
  )
}

export default Prescription