import React from "react";
import { Link } from "react-router-dom";
//import Logo from '../../assets/images/logo-icon.svg'
import Logo from '../../assets/images/logo-icon.png'
import Logo2x from '../../assets/images/logo-icon@2x.png'

const HeaderBeforeRegistration = () => {
  return (
    <>
      <header className="bg-secondary text-white border-b border-gray-200/25 z-30 hidden lg:block">
      <div className="container px-30px 2xl:px-0">
      <div className="mx-auto flex flex-wrap py-4 flex-row items-center">
        <div className="flex w-2/5 flex-wrap items-center text-base">
        <ul className="flex">
          <li className="text-xs font-bold uppercase">Home</li>
        </ul>
        </div>

        <Link className="flex w-1/5 title-font font-medium items-center text-gray-900 justify-center" to="#">
        <img src={Logo} srcSet={`${Logo2x} 2x`} alt="starthealth" className="mx-auto" />
        </Link>

        <div className="w-2/5 inline-flex justify-end ml-0">
            <Link to="#" className="text-xs font-bold uppercase">LOGIN</Link>
        </div>
      </div>
      </div>
    </header>
    </>
  )
}

export default HeaderBeforeRegistration