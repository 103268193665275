import React, {useState} from 'react'
import { Link } from "react-router-dom";
import { Switch } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGear,
  faArrowRight,
  faCircleInfo
} from '@fortawesome/free-solid-svg-icons'
const AccountSettings = () => {
  const [checked, setChecked] = useState(true);
  
  // switch
  const [enabled, setEnabled] = useState(true)

  return (
    <>
    <header className="container px-30px 2xl:px-0 lg:mt-12 mb-8 lg:mb-10 mt-5 flex">
  <div className='text-left flex w-full'>
  <div className="">
  <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-1 flex">account settings</h2>
  <p className="text-secondary text-xs font-bold lg:text-base">MEMBER: JANE DOE</p>
  <small className='block text-8px lg:text-10px text-secondary uppercase font-bold'>START HSA PLAN</small>
  </div>
  <div className="ml-auto">
  <FontAwesomeIcon
            icon={faGear}
            className="text-secondary text-base lg:text-2xl"
          />
  </div>
  </div>
</header>

<div className='container px-30px 2xl:px-0'>
  <div className="lg:grid lg:grid-cols-12 lg:gap-16">
    <div className="lg:col-span-6 pb-10">
    <div className='card lg:mt-0'>
        <h4 className='card-title lg:text-22px pb-0 flex items-center'>reimbursement preference <FontAwesomeIcon icon={faCircleInfo} className="text-secondary text-xs ml-2" /></h4>
<ol className=''>
  <li className=''>
    <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
      <span className=" text-xs lg:text-base text-secondary">HSA Contribution</span>
      <input type="radio" className='ml-auto form-radio' name='HSAC' value="option1" defaultChecked={checked} onChange={() => setChecked(!checked)} />
    </label>
  </li>
  <li className=''>
    <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
      <span className=" text-xs lg:text-base text-secondary">Check in the Mail</span>
      <input type="radio" className='ml-auto form-radio' name='HSAC'  value="option2" onChange={() => setChecked(!checked)}/>
    </label>
  </li>
  <li className=''>
    <label className="inline-flex items-center w-full p-4   border-secondary border-opacity-25 cursor-pointer">
      <span className=" text-xs lg:text-base text-secondary">Premium Credit</span>
      <input type="radio" className='ml-auto form-radio' name='HSAC'  value="option3" onChange={() => setChecked(!checked)}/>
    </label>
  </li>
</ol>
        
      </div> {/* END .card */}



      <div className='card'>
        <h4 className='card-title pb-0 flex items-center lg:text-22px'>account ordering <FontAwesomeIcon icon={faCircleInfo} className="text-secondary text-xs ml-2" /></h4>
        <ol className=''>
              <li className=''>
                <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                  <span className=" text-xs lg:text-base text-secondary">Start HSA Account</span>
                  <input type="radio" className='ml-auto form-radio' name='accountOrdering' value="option01" defaultChecked={checked}
                  onChange={() => setChecked(!checked)} />
                </label>
              </li>
              <li className=''>
                <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                  <span className=" text-xs lg:text-base text-secondary">Jane’s Checking Account</span>
                  <input type="radio" className='ml-auto form-radio' name='accountOrdering'  value="option02" onChange={() => setChecked(!checked)}/>
                </label>
              </li>
              <li className='p-4'>
                <Link to="/add-bankaccount" className='btn uppercase w-full flex justify-center items-center cursor-pointer'>ADD ACCOUNT
                  <FontAwesomeIcon icon={faArrowRight} className="text-white w-2.5 ml-auto" />
                </Link>
              </li>
            </ol>
        
      </div> {/* END .card */}

      <div className='card'>
        <h4 className='card-title pb-0 flex items-center lg:text-22px'>your location  <FontAwesomeIcon icon={faCircleInfo} className="text-secondary text-xs ml-2" /></h4>
        <ol className=''>
              <li className=''>
                <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                  <span className=" text-xs lg:text-base text-secondary">Location</span>
                  <span className='ml-auto'>
                  <Switch
      checked={enabled}
      onChange={setEnabled}
      className={`${
        enabled ? 'bg-secondary' : 'bg-gray-200'
      } relative inline-flex h-4 w-6 items-center rounded-full`}
    >
      <span className="sr-only">Enable notifications</span>
      <span
        className={`${
          enabled ? 'translate-x-3' : 'translate-x-1'
        } inline-block h-2 w-2 transform rounded-full bg-white`}
      />
    </Switch>

                  </span>
                </label>
              </li>
              
              <li className='p-4'>
                <Link to="#" className='btn uppercase w-full flex justify-center items-center cursor-pointer'>CHANGE LOCATION
                  <FontAwesomeIcon icon={faArrowRight} className="text-white w-2.5 ml-auto" />
                </Link>
              </li>
            </ol>
        
      </div> {/* END .card */}
    </div>
    <div className="lg:col-span-6 hidden lg:block"></div>
  </div>
</div>
    </>
  )
}

export default AccountSettings