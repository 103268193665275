import { Routes, Route } from "react-router-dom"

import AppLayout from "../layout/AppLayout"
import AppLayoutBeforeLogin from "../layout/AppLayoutBeforeLogin"

import Login from "../pages/Auth/Signin"

import Registration from "../pages/Auth/Signup/Account/Registration"
import NeedRegistration from "../pages/Auth/Signup/Account/NeedRegistration"
import SelectMember from "../pages/Auth/Signup/Account/SelectMember"
import SetPassword from "../pages/Auth/Signup/Account/SetPassword"
import EnterAddress from "../pages/Auth/Signup/Account/EnterAddress"
import WhichAccount from "../pages/Auth/Signup/Account/WhichAccount"
import AddBankAccount from "../pages/Auth/Signup/Account/AddBankAccount"
import OpenStartHSA from "../pages/Auth/Signup/Account/OpenStartHSA"
import AccountOrdering from "../pages/Auth/Signup/Account/AccountOrdering"
import SelectReimbursementPreference from "../pages/Auth/Signup/Account/SelectReimbursementPreference"


import RegistrationNew from "../pages/Auth/Signup/RegistrationNew"
import Wheredoyoulive from "../pages/Auth/Signup/Wheredoyoulive"
import AddInitialMember from "../pages/Auth/Signup/AddInitialMember"
import AddingFamilyMembers from "../pages/Auth/Signup/AddingFamilyMembers"
import SelectYourQuoteforIndividual from "../pages/Auth/Signup/SelectYourQuoteforIndividual"
import SelectYourQuoteforFamily from "../pages/Auth/Signup/SelectYourQuoteforFamily"
import SelectGoLiveDate from "../pages/Auth/Signup/SelectGoLiveDate"
import Checkout from "../pages/Auth/Signup/Checkout"
import Confirmation from "../pages/Auth/Signup/Confirmation"
import MembershipDenied from "../pages/Auth/Signup/MembershipDenied"
import AddInitialMember1 from "../pages/Auth/Signup/AddInitialMember1"


import Dashboard from "../pages/Dashboard"
import Error from "../pages/Error"
import HSASummary from "../pages/HSASummary"
import FindCare from "../pages/FindCare"
import FindDoctor from "../pages/FindDoctor"
import CareDetail from "../pages/FindCare/CareDetail"
import ForgotPassword from "../pages/Auth/ForgotPassword"
import SplashScreen from "../pages/SplashScreen"
import Contributions from "../pages/HSASummary/Contributions"
import AccountSummary from "../pages/HSASummary/AccountSummary"
import TransactionHistory from "../pages/HSASummary/TransactionHistory"
import StartCardActivation from "../pages/HSASummary/StartCardActivation"
import AddaBankAccount from "../pages/HSASummary/AddaBankAccount"
import StartCard from "../pages/HSASummary/StartCard"
import ManageStartCard from "../pages/HSASummary/ManageStartCard"

import Investments from "../pages/HSASummary/Investments"
import UserEligible from "../pages/HSASummary/Investments/UserEligible"
import InvestorProfile from "../pages/HSASummary/Investments/InvestorProfile"
import InvestmentsVerificationStatus from "../pages/HSASummary/Investments/InvestmentsVerificationStatus"
import PathProfile from "../pages/HSASummary/Investments/PathProfile"
import InvestmentsVerificationStatus1 from "../pages/HSASummary/Investments/InvestmentsVerificationStatus1"
import ManagedPath from "../pages/HSASummary/Investments/ManagedPath"
import ManagedPath1 from "../pages/HSASummary/Investments/ManagedPath1"
import ManagedPath2 from "../pages/HSASummary/Investments/ManagedPath2"
import SelfDirectedPathPendingInvestments from "../pages/HSASummary/Investments/SelfDirectedPathPendingInvestments"


import Prescription from "../pages/Prescription"
import FindCareList from "../pages/FindCare/FindCareList"
import Favorites from "../pages/FindCare/Favorites";
import CategoryDetails from "../pages/FindDoctor/CategoryDetails"
import DoctorDetails from "../pages/FindDoctor/DoctorDetails"
import DoctorService from "../pages/FindDoctor/DoctorService"
import FindCareProvider from "../pages/FindCare/FindCareProvider"
import Account from "../pages/Account"
import AccountDetails from "../pages/Account/AccountDetails"
import AccountSettings from "../pages/Account/AccountSettings"
import Expenses from "../pages/Expenses"
import Sitemap from "../pages/Sitemap"
import UploadAnExpense from "../pages/Expenses/UploadAnExpense"
import ReceiptUploadedGeneratedNewExpens from "../pages/Expenses/ReceiptUploadedGeneratedNewExpens"
import CardGeneratedDeniedExpense from "../pages/Expenses/CardGeneratedDeniedExpense"
import CardGeneratedNewExpense from "../pages/Expenses/CardGeneratedNewExpense"
import CardGeneratedPartialReimbursedExpense from "../pages/Expenses/CardGeneratedPartialReimbursedExpense"
import PreDeductibleGeneralOpenExpensePage from "../pages/Expenses/PreDeductibleGeneralOpenExpensePage"
import PreDeductibleGeneralCompletedExpense from "../pages/Expenses/PreDeductibleGeneralCompletedExpense"
import PreDeductibleGeneralCompletedExpense1 from "../pages/Expenses/PreDeductibleGeneralCompletedExpense1"
import EnterYourEmail from "../pages/Auth/Signup/EnterYourEmail"
import PreApprovalForm from "../pages/Expenses/PreApprovalForm"
import PreApprovalRequestConfirmation from "../pages/Expenses/PreApprovalRequestConfirmation"
import Medication from "../pages/Prescription/Medication"

const Routing = () => {
  return (
    <>
        <Routes>
        <Route path="/" element={<SplashScreen />}></Route>
          <Route path="login" element={<Login />}></Route>
          <Route path="forgot-password" element={<ForgotPassword />}></Route>
          
          <Route element={<AppLayoutBeforeLogin />} >
          <Route path="registration" element={<Registration />}></Route>
          <Route path="select-member" element={<SelectMember />}></Route>
          <Route path="set-password" element={<SetPassword />}></Route>
          <Route path="enter-address" element={<EnterAddress />}></Route>
          <Route path="which-account" element={<WhichAccount />}></Route>
          <Route path="add-bank-account" element={<AddBankAccount />}></Route>
          <Route path="open-start-hsa" element={<OpenStartHSA />}></Route>
          <Route path="account-ordering" element={<AccountOrdering />}></Route>
          <Route path="select-reimbursement-preference" element={<SelectReimbursementPreference />}></Route>
          <Route path="need-registration" element={<NeedRegistration />}></Route>

          <Route path="registrationnew" element={<RegistrationNew />}></Route>
          <Route path="enter-your-email" element={<EnterYourEmail />}></Route>
          <Route path="where-do-you-live" element={<Wheredoyoulive />}></Route>
          <Route path="add-initial-member" element={<AddInitialMember />}></Route>
          <Route path="adding-family-members" element={<AddingFamilyMembers />}></Route>
          <Route path="select-your-quote-for-individual" element={<SelectYourQuoteforIndividual />}></Route>
          <Route path="select-your-quote-for-family" element={<SelectYourQuoteforFamily />}></Route>
          <Route path="select-go-live-date" element={<SelectGoLiveDate />}></Route>
          <Route path="checkout" element={<Checkout />}></Route>
          <Route path="confirmation" element={<Confirmation />}></Route>
          <Route path="membership-denied" element={<MembershipDenied />}></Route>
          <Route path="add-initial-member1" element={<AddInitialMember1 />}></Route>
          </Route>
          
          <Route element={<AppLayout />} >
            <Route path="dashboard" element={<Dashboard />} />
            
             {/* HSA SUMMARY */}
            <Route path="hsa-summary" element={<HSASummary />} />
            <Route path="contributions" element={<Contributions />} />
            <Route path="account-summary" element={<AccountSummary />} />
            <Route path="transaction-history" element={<TransactionHistory />} />
            <Route path="start-card-activation" element={<StartCardActivation />} />
            <Route path="add-bankaccount" element={<AddaBankAccount />} />
            <Route path="start-card" element={<StartCard />} />

            <Route path="investments" element={<Investments />} />
            <Route path="user-eligible" element={<UserEligible />} />
            <Route path="investor-profile" element={<InvestorProfile />} />
            <Route path="investments-verification-status" element={<InvestmentsVerificationStatus />} />
            <Route path="path-profile" element={<PathProfile />} />
            <Route path="investments-verification-status1" element={<InvestmentsVerificationStatus1 />} />
            <Route path="managed-path" element={<ManagedPath />} />
            <Route path="managed-path1" element={<ManagedPath1 />} />
            <Route path="managed-path2" element={<ManagedPath2 />} />
            <Route path="self-directed-path" element={<SelfDirectedPathPendingInvestments />} /> 

            <Route path="manage-start-card" element={<ManageStartCard />} />

             {/* FIND CARE */}
            <Route path="find-care" element={<FindCare />} />
            <Route path="find-care/:id" element={<FindCareList />} />
            <Route path="find-care/category/:id" element={<CareDetail />} />
            <Route path="find-care/provider/:id" element={<FindCareProvider />} />
            

            <Route path="prescriptions" element={<Prescription />} />
            <Route path="prescriptions/:id" element={<Medication  />} />
            
             {/* FIND DOCTOR */}
            <Route path="find-doctor" element={<FindDoctor />} />
            <Route path="find-doctor/category/:id" element={<CategoryDetails />} />
            <Route path="find-doctor/:id" element={<DoctorDetails />} />
            <Route path="find-doctor/services/:id" element={<DoctorService />} />

            { <Route path="favorites" element={<Favorites />} /> }
            {/* Account Settings */}
            <Route path="account" element={<Account />} />
            <Route path="account-details" element={<AccountDetails />} />
            <Route path="account-settings" element={<AccountSettings />} />             
            
            {/* Expenses */}
            <Route path="expenses" element={<Expenses />} />
            <Route path="upload-expense" element={<UploadAnExpense />} /> 
            <Route path="pre-approval" element={<PreApprovalForm />} /> 
            <Route path="pre-approval-confirmation" element={<PreApprovalRequestConfirmation />} /> 
            <Route path="receipt-uploaded" element={<ReceiptUploadedGeneratedNewExpens />} /> 
            <Route path="receipt-uploaded-1" element={<CardGeneratedDeniedExpense />} /> 
            <Route path="receipt-uploaded-2" element={<CardGeneratedNewExpense />} /> 
            <Route path="receipt-uploaded-3" element={<CardGeneratedPartialReimbursedExpense />} />

            <Route path="pre-deductible-1" element={<PreDeductibleGeneralOpenExpensePage />} />
            <Route path="pre-deductible-2" element={<PreDeductibleGeneralCompletedExpense />} />
            <Route path="pre-deductible-3" element={<PreDeductibleGeneralCompletedExpense1 />} />

            <Route path="sitemap" element={<Sitemap />} /> 

            {/* */}
            
          </Route>
          <Route path="*" element={<Error />} />
        </Routes>
    </>
  )
}

export default Routing