import React from 'react';
import Select from 'react-select';

const CustomSelect = (props) => {

    const colourStyles = {
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color);
        //console.log({ data, isDisabled, isFocused, isSelected });
        return {
          ...styles,
          
          backgroundColor: 
          isDisabled ? '#F7F7F7' : 
          isSelected ? '#F7F7F7' : 
          isFocused ? '#F7F7F7' : null,
  
          color: "#1C3557"
        };
      }
    };

  return (
    <>
    <Select 
    styles={colourStyles}
    options={props.optionsval}
    placeholder={props.phtext}
    className="xe-custom-style"
    classNamePrefix="xe"
    />
    </>
  )
}

export default CustomSelect