import React from 'react'
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'

import SubHeader from '../../components/SubHeader'
import CardButton from '../../components/Button/CardButton'

import iconHasSummary from "../../assets/images/svg/dollar-sign-solid.svg";
import iconUploadReceiptt from "../../assets/images/svg/receipt-solid.svg";
import iconInvest from "../../assets/images/svg/chart-line-solid.svg";
import iconStartCard from "../../assets/images/svg/credit-card-solid.svg";
import iconRecipt from "../../assets/images/svg/bookmark-solid.svg";
import ActionCardButton from '../../components/Button/ActionCardButton'



const HSASummary = () => {
  return (
    <>
    <SubHeader />

    <div className="bg-white lg:bg-gray-light py-6 lg:py-12">
        <div className="container px-30px 2xl:px-0">
          <div className="grid grid-cols-12 gap-7 lg:gap-14">
              <div className="col-span-6 lg:col-span-3">
                <CardButton title="CONTRIBUTE" xicon={iconHasSummary} url="/contributions" imageClassName="w-[21.67px] h-[38.52px] lg:w-[28.48px] lg:h-[50.64px] mx-auto lg:m-0" />
              </div>
              <div className="col-span-6 lg:col-span-3">
                <CardButton title="INVEST" xicon={iconInvest} url="/investments" imageClassName="w-[34.44px] h-[30.14px] lg:w-[41.23px] lg:h-[36.08px] mx-auto lg:m-0"  />
              </div>
              <div className="col-span-6 lg:col-span-3">
                <CardButton title="START CARD" xicon={iconStartCard} url="/start-card" imageClassName="w-[32px] h-[24.89px] lg:w-[46.38px] lg:h-[36.08px] mx-auto lg:m-0"  />
              </div>
              <div className="col-span-6 lg:col-span-3">
                <CardButton title="Expenses" xicon={iconUploadReceiptt} url="/expenses" imageClassName="w-[21.8px] h-[29.06px] lg:w-[27.52px] lg:h-[36.7px] mx-auto lg:m-0" />
              </div>
          </div>
          </div>
        </div>
 

        <div className="lg:py-8 container lg:px-30px 2xl:px-0">
            <div className="lg:grid lg:grid-cols-12 lg:gap-14">
                <div className="col-span-9">

<div className='grid grid-cols-5 py-4'>
    <div className='col-span-3'><div className='pl-8 text-xs text-secondary font-bold lg:text-base'>EXPENSE HISTORY</div></div>
    <div className='col-span-2'><div className='text-secondary text-xs lg:text-lg pr-8 lg:text-right max-w-[114px] lg:max-w-[142px] ml-auto'>Amount Paid</div></div>
</div>

<div className='grid grid-cols-5 py-4 bg-gray-light lg:bg-transparent border-t-2 border-t-secondary border-opacity-25'>
    <div className='col-span-3'>
    <div className='pl-8'>
                        <div className="text-secondary text-xs lg:text-base mb-1">Mountain Point #4321 
                        <FontAwesomeIcon icon={faCircleExclamation} className="text-red text-xe ml-1" /></div>
                        <div className="text-secondary text-8px lg:text-10px font-bold  leading-none lg:leading-3">
                            MEMBER: JANE DOE <br/>
                            02/22/22
                        </div>

                        <div className="mt-4 flex items-center">
                            <Link to="/pre-deductible-2"
                                className="py-1 text-center w-[64px] inline-block rounded-5px text-10px bg-primary text-white cursor-pointer font-medium uppercase">Pending</Link>
                        </div>
                    </div>
    </div>
    <div className='col-span-2'><div className='text-secondary text-xs lg:text-lg  pr-8 font-bold lg:text-right max-w-[114px]  lg:max-w-[142px] ml-auto'>$439.75</div></div>
</div>
{/* item #1 */}

<div className='grid grid-cols-5 py-4 bg-gray-light lg:bg-transparent border-t-2 border-t-secondary border-opacity-25'>
    <div className='col-span-3'>
    <div className='pl-8'>
                        <div className="text-secondary text-xs lg:text-base mb-1">Smiths #5232</div>
                        <div className="text-secondary text-8px lg:text-10px font-bold  leading-none lg:leading-3">
                            MEMBER: JANE DOE <br/>
                            01/12/22
                        </div>

                        <div className="mt-4 flex items-center">
                            <Link to="/pre-deductible-2"
                                className="py-1 text-center w-[64px] inline-block rounded-5px text-10px bg-cyan text-white cursor-pointer font-medium uppercase">complete</Link>
                        </div>
                    </div>
    </div>
    <div className='col-span-2'><div className='text-secondary text-xs lg:text-lg  pr-8 font-bold lg:text-right max-w-[114px]  lg:max-w-[142px] ml-auto'>$56.32</div></div>
</div>
{/* item #2 */}

<div className='grid grid-cols-5 py-4 bg-gray-light lg:bg-transparent border-t-2 border-t-secondary border-opacity-25'>
    <div className='col-span-3'>
    <div className='pl-8'>
                        <div className="text-secondary text-xs lg:text-base mb-1">Family Medicine #7381</div>
                        <div className="text-secondary text-8px lg:text-10px font-bold  leading-none lg:leading-3">
                            MEMBER: JANE DOE <br/>
                            08/06/21
                        </div>

                        <div className="mt-4 flex items-center">
                            <Link to="/pre-deductible-2"
                                className="py-1 text-center w-[64px] inline-block rounded-5px text-10px bg-cyan text-white cursor-pointer font-medium uppercase">complete</Link>
                        </div>
                    </div>
    </div>
    <div className='col-span-2'><div className='text-secondary text-xs lg:text-lg  pr-8 font-bold lg:text-right max-w-[114px]  lg:max-w-[142px] ml-auto'>$20.63</div></div>
</div>
{/* item #3 */}

<div className='grid grid-cols-5 py-4 bg-gray-light lg:bg-transparent border-t-2 border-t-secondary border-opacity-25'>
    <div className='col-span-3'>
    <div className='pl-8'>
                        <div className="text-secondary text-xs lg:text-base mb-1">Family Medicine #2493</div>
                        <div className="text-secondary text-6px lg:text-8px text-base font-bold  leading-none lg:leading-3">
                            MEMBER: JANE DOE <br/>
                            03/28/21
                        </div>

                        <div className="mt-4 flex items-center">
                            <Link to="/pre-deductible-2"
                                className="py-1 text-center w-[64px] inline-block rounded-5px text-10px bg-cyan text-white cursor-pointer font-medium uppercase">complete</Link>
                        </div>
                    </div>
    </div>
    <div className='col-span-2'><div className='text-secondary text-xs lg:text-lg  pr-8 font-bold lg:text-right max-w-[114px]  lg:max-w-[142px] ml-auto'>$72.50</div></div>
</div>
{/* item #4 */}

<div className='grid grid-cols-5 py-4 bg-gray-light lg:bg-transparent border-t-2 border-t-secondary border-opacity-25'>
    <div className='col-span-3'>
    <div className='pl-8'>
                        <div className="text-secondary text-xs lg:text-base mb-1">Unknown #2673</div>
                        <div className="text-secondary text-8px lg:text-10px font-bold leading-none lg:leading-3">
                            MEMBER: JANE DOE <br/>
                            03/28/21
                        </div>

                        <div className="mt-4 flex items-center">
                            <span
                                className="py-1 text-center w-[64px] inline-block rounded-5px text-10px bg-secondary text-white font-medium uppercase">denied</span>
                            <span className="ml-2 text-secondary text-6px font-bold">
NOT COVERED <br/>BY START
                      </span>
                        </div>
                    </div>
    </div>
    <div className='col-span-2'><div className='text-secondary text-xs lg:text-lg  pr-8 font-bold lg:text-right max-w-[114px]  lg:max-w-[142px] ml-auto'>$5.00</div></div>
</div>
{/* item #5 */}

<div className='py-4 bg-gray-light lg:bg-transparent border-t-2 border-t-secondary border-opacity-25'>
    <div className='col-span-5 hidden lg:block'>
        <Link to="/transaction-history" className='text-sm font-bold text-secondary pl-8'>
        VIEW ALL EXPENSES
        <svg xmlns="http://www.w3.org/2000/svg"   className='h-[10.85px] w-[10.98px] inline-block ml-3 -mt-px' viewBox="0 0 15.993 16.529">
        <path id="arrow-right" d="M7.786,34.717l-.707.739a.463.463,0,0,0,0,.633l5.538,5.79H.428A.438.438,0,0,0,0,42.327v1.045a.438.438,0,0,0,.428.448H12.617l-5.538,5.79a.463.463,0,0,0,0,.633l.707.739a.415.415,0,0,0,.606,0l7.476-7.816a.463.463,0,0,0,0-.633L8.392,34.717A.415.415,0,0,0,7.786,34.717Z" transform="translate(0 -34.586)" fill="#1c3557"/></svg>
        </Link>    
    </div>
</div>
{/* item #6 */}
</div>

                {/*  */}
                <div className="col-span-3 hidden lg:block">
                <div className='mb-6'>
                    <h2 className='font-playfair text-secondary font-bold text-[32px] mb-1'>questions?</h2>
                    <p className='text-lg text-secondary'>Need help? Just give us a call at
                801-XXX-XXXX and we’d be happy
                to help.</p>
                    </div>

                    <ActionCardButton title="" url="/favorites" linktitle="VIEW FAVORITES" icon={true} iconimage={iconRecipt} iconcname="w-[13.76px] h-[18.35px]" />
            {/* END  */}

                    {/* <div className="bg-gray-light w-full border-t-6 border-cyan rounded-md mb-6">
              <Link to="/favorites" className='block p-3'>
                <div className="text-gray-light h-14 flex justify-left items-left">
                  <FontAwesomeIcon
                    icon={faBookmark}
                    className="text-secondary text-base"
                  />
                </div>
                <div className="flex items-center">
                  <div className="text-secondary text-10px lg:lg:text-sm font-bold uppercase">
                    VIEW FAVORITES
                  </div>
                  <div className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg"   className='h-[11px] w-3 lg:w-4 lg:h-4  ml-auto' viewBox="0 0 15.993 16.529">
                    <path id="arrow-right" d="M7.786,34.717l-.707.739a.463.463,0,0,0,0,.633l5.538,5.79H.428A.438.438,0,0,0,0,42.327v1.045a.438.438,0,0,0,.428.448H12.617l-5.538,5.79a.463.463,0,0,0,0,.633l.707.739a.415.415,0,0,0,.606,0l7.476-7.816a.463.463,0,0,0,0-.633L8.392,34.717A.415.415,0,0,0,7.786,34.717Z" transform="translate(0 -34.586)" fill="#1c3557"/>
                    </svg>
                  </div>
                </div>
              </Link>
            </div> */}
            {/* END  */}

            <div className="bg-gray-light w-full border-t-6 border-cyan rounded-md">
              <Link to="#" className='block p-3'>
                <div className="flex items-center">
                  <div className="text-secondary text-10px lg:text-sm font-bold uppercase">
                  REFER A FRIEND
                  </div>
                  <div className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg"   className='h-[11px] w-3 lg:w-4 lg:h-4  ml-auto' viewBox="0 0 15.993 16.529">
                    <path id="arrow-right" d="M7.786,34.717l-.707.739a.463.463,0,0,0,0,.633l5.538,5.79H.428A.438.438,0,0,0,0,42.327v1.045a.438.438,0,0,0,.428.448H12.617l-5.538,5.79a.463.463,0,0,0,0,.633l.707.739a.415.415,0,0,0,.606,0l7.476-7.816a.463.463,0,0,0,0-.633L8.392,34.717A.415.415,0,0,0,7.786,34.717Z" transform="translate(0 -34.586)" fill="#1c3557"/>
                    </svg>
                  </div>
                </div>
              </Link>
            </div>


                </div>
                 {/*  ENd */}


                
            </div>
        </div>

    </>
  )
}

export default HSASummary