import React from 'react';

const ErrorMessage = (props) => {
    return (
        <div className='text-red text-xs font-bold'>
            {props.message}
        </div>
    );
}

export default ErrorMessage;