import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import Input from '../../../../components/Input';
import CustomSelect from '../../../../components/Select/';

function EnterAddress() {

  useEffect(() => {
    document.title = 'Enter Address';
  }, []);

  const handleChange = (event) => {
    const { value, name } = event.target;
    this.useState({ [name]: value });
}

const options = [
  { value: 'one', label: 'One' },
  { value: 'two', label: 'Two' },
  { value: 'three', label: 'Three' }
];

  return (
    <>
     <div className="h-full lg:h-auto lg:min-h-[calc(100vh_-_107px)] flex lg:justify-between lg:items-center">
        <div className="h-full flex flex-col justify-between items-center lg:h-auto w-full pt-12 lg:py-0 px-8 lg:px-0 min-h-[calc(100vh_-_60px)] lg:min-h-full">
        <div className="max-w-md mx-auto w-full">
        <div className="text-center mb-5 lg:mb-7">
            <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-2">where do you live?</h2>
            <p className='text-cyan text-xs font-bold lg:text-lg'>Please enter your current address.</p>
        </div>
        <form className="" action="#">
            <div className="mb-3">
              <Input 
                  type="text" 
                  className="form-control" 
                  placeholder="Street Address Line 1"
                  handleChange={handleChange}
                  />
            </div>
            <div className="flex mb-3 space-x-3">
              <div className="w-1/2">
                <Input 
                  type="text" 
                  className="form-control mr-[15px]"
                  placeholder="Line 2 (Optional)"
                  handleChange={handleChange}
                  />

              </div>
              <div className="w-1/2">
              <Input 
                  type="text" 
                  className="form-control"
                  placeholder="City"
                  handleChange={handleChange}
                  />
              </div>
            </div>

            <div className="flex mb-3 space-x-3">
              <div className="w-1/2">
                <CustomSelect phtext="State" optionsval={options} />
              </div>

              <div className="w-1/2">
              <Input 
                  type="text" 
                  className="form-control"
                  placeholder="Zip Code"
                  handleChange={handleChange}
                  />
              </div>
            </div>

            <div className="text-center mt-7 mb-5"><Link to="/which-account" className='btn uppercase'>Continue</Link></div>
            <div className='text-center'><Link to="/set-password" className='text-xs font-bold text-secondary uppercase'>Go Back</Link></div>

          </form>
        </div>
        
      

        
        </div>
      </div>
    </>
  )
}

export default EnterAddress