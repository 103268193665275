import React, {useEffect} from 'react'
import { Link } from "react-router-dom";
import Input from '../../../../components/Input';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function AddBankAccount() {

  useEffect(()=>{
    document.title = 'Add Bank Account';
  }, []);

  const handleChange = (event) => {
    const { value, name } = event.target;
    this.useState({ [name]: value });
}

  return (
    <>
 <div className="h-full lg:h-auto lg:min-h-[calc(100vh_-_107px)] flex lg:justify-between lg:items-center">
        <div className="h-full flex flex-col justify-between items-center lg:h-auto w-full pt-12 xl:pt-0    lg:px-0 min-h-[calc(100vh_-_60px)] lg:min-h-full">
        <div className="container px-30px 2xl:px-0">
        <div className="text-center mb-5 lg:mb-12">
            <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-2">add a bank account</h2>
            <p className='text-cyan text-xs font-bold lg:text-lg'>Please enter your banking information.</p>
        </div>
        <form className="" action="#">
        <div className="lg:grid lg:grid-cols-12 lg:gap-16">
        
          <div className="lg:col-span-6">
          <div className="mb-3">
            <h3 className='hidden lg:block uppercase text-lg text-secondary font-bold'>YOUR BANK INFORMATION</h3>
          </div>
            
            <div className="mb-3">
            <div className="w-full">
                <Input 
                  type="text" 
                  className="form-control" 
                  placeholder="Bank Name"
                  handleChange={handleChange}
                  />
                </div>
            </div>

            <div className="mb-3">
            <div className="w-full">
                <Input 
                  type="text" 
                  className="form-control" 
                  placeholder="Routing Number (9 Digits)"
                  handleChange={handleChange}
                  />
                </div>
            </div>

            <div className="mb-3">
            <div className="w-full">
                <Input 
                type="text" 
                className="form-control" 
                placeholder="Confirm Routing Number"
                handleChange={handleChange}
                />
                </div>
            </div>

            <div className="mb-3">
            <div className="w-full">
                <Input 
                  type="text" 
                  className="form-control" 
                  placeholder="Account Number"
                  handleChange={handleChange}
                  />
                </div>
            </div>

            <div className="mb-3">
            <div className="w-full">
                <Input 
                  type="text" 
                  className="form-control" 
                  placeholder="Confirm Account Number"
                  handleChange={handleChange}
                  />
                </div>
            </div>


            <div className="mb-5 mt-6">
                <span className='text-xs text-secondary block mb-2 lg:text-lg'>Please select the account type:</span>
            <ul className='bg-gray-light border-t-6 rounded-md border-cyan'>
              <li className=''>
              <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                      <input type="radio" value="option1" name='selectAccountType' className='form-radio text-secondary' checked />
                      <span className="ml-2 text-xs text-secondary lg:text-base">Checking</span>
                    </label>
              </li>
              <li className=''>
              <label className="inline-flex items-center w-full p-4 cursor-pointer">
                      <input type="radio" value="option2" className='form-radio text-secondary' name='selectAccountType' />
                      <span className="ml-2 text-xs text-secondary lg:text-base">Saving</span>
                    </label>
              </li>
            </ul>
            </div>
          </div>
          <div className="lg:col-span-6">
          <div className="bg-transparent lg:bg-gray-light border-0 lg:border-t-6 rounded-md border-cyan">
            <div className='text-lg leading-6 px-8 text-secondary pt-7 hidden lg:block'>
              Please confirm the information entered is correct before continuing with your account set up.
              </div>
          <div className="text-center mt-7 pb-5 lg:pb-8 px-8">
            <Link to="/open-start-hsa" className='btn uppercase w-full lg:flex lg:justify-center items-center cursor-pointer bg-primary'>
              Continue
              <FontAwesomeIcon
                icon={faArrowRight}
                className="text-white w-3 ml-auto hidden lg:inline-block"
              />
            </Link>
            
            </div>
  <div className='text-center'>
    <Link to="/which-account" className='text-xs font-bold text-secondary uppercase lg:hidden block'>Go Back</Link>
    </div>
          </div>
          </div>
          
        </div>
        </form>
        
        </div>
        </div>
      </div>
    </>
  )
}

export default AddBankAccount