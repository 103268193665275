import React, {useEffect} from 'react'
import { Link, useParams } from "react-router-dom";
import SearchBar from '../../components/SearchBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight
} from '@fortawesome/free-solid-svg-icons'
import ArrowRightIcon from '../../components/Icons/ArrowRightIcon';

const FindCareList = () => {
  useEffect(()=>{
    document.title = 'Care Detail';
  }, []);

let {id} = useParams()
  return (
    <>
    <SearchBar />
<div className='container px-30px 2xl:px-0 lg:mt-12 mb-5 mt-5 flex'>
    <div className='text-left'>
    <h2 className='font-playfair text-secondary font-bold text-2xl lg:text-38px mb-1'>doctor visit</h2>
    <p className='textext-secondary text-xs lg:text-lg pt-3t-xs text-secondary'>Please select the type of doctor visit you wish to have.</p>
    </div>
</div>

<div className='mt-5 '>
   

    <div className='container px-30px 2xl:px-0 '>
    <div className='flex justify-start  5 py-3'>
    <h4 className='flex-grow text-xs lg:text-base  text-secondary font-bold uppercase'>CARE TYPES</h4>
   </div>
    </div>

    <div className='bg-gray-light border-t-2 border-t-secondary border-opacity-25'>
        <div className='container px-30px 2xl:px-0'>
            <Link to="#" className='flex items-center py-3 lg:py-5'>
            <h4 className='flex-grow text-xs lg:text-base text-secondary'>Comprehensive Wellness Visit / Physical Exam</h4>
            <ArrowRightIcon fill="#1c3557" cname="h-[10.98px] w-[10.98px] ml-auto" />
            </Link>
        </div>
    </div>  {/* END */}
    <div className='bg-gray-light border-t-2 border-t-secondary border-opacity-25'>
        <div className='container px-30px 2xl:px-0'>
            <Link to="#" className='flex items-center py-3 lg:py-5'>
            <h4 className='flex-grow text-xs lg:text-base text-secondary'>Wellness / Preventive Visit</h4>
            <ArrowRightIcon fill="#1c3557" cname="h-[10.98px] w-[10.98px] ml-auto" />
            </Link>
        </div>
    </div>  {/* END */}
    <div className='bg-gray-light border-t-2 border-t-secondary border-opacity-25'>
        <div className='container px-30px 2xl:px-0'>
            <Link to="#" className='flex items-center py-3 lg:py-5'>
            <h4 className='flex-grow text-xs lg:text-base text-secondary'>Earn, Nose Throat (ENT) Visit</h4>
            <ArrowRightIcon fill="#1c3557" cname="h-[10.98px] w-[10.98px] ml-auto" />
            </Link>
        </div>
    </div> {/* END */}

    <div className='bg-gray-light border-t-2 border-t-secondary border-opacity-25'>
        <div className='container px-30px 2xl:px-0'>
            <Link to="#" className='flex items-center py-3 lg:py-5'>
            <h4 className='flex-grow text-xs lg:text-base text-secondary'>Pediatrician Visit</h4>
            <ArrowRightIcon fill="#1c3557" cname="h-[10.98px] w-[10.98px] ml-auto" />
            </Link>
        </div>
    </div> {/* END */}
    <div className='bg-gray-light border-t-2 border-t-secondary border-opacity-25'>
        <div className='container px-30px 2xl:px-0'>
            <Link to="#" className='flex items-center py-3 lg:py-5'>
            <h4 className='flex-grow text-xs lg:text-base text-secondary'>Cardiologist Visit</h4>
            <ArrowRightIcon fill="#1c3557" cname="h-[10.98px] w-[10.98px] ml-auto" />
            </Link>
        </div>
    </div> {/* END */}
    <div className='bg-gray-light border-t-2 border-t-secondary border-opacity-25'>
        <div className='container px-30px 2xl:px-0'>
            <Link to="#" className='flex items-center py-3 lg:py-5'>
            <h4 className='flex-grow text-xs lg:text-base text-secondary'>Ophthalmologist Visit</h4>
            <ArrowRightIcon fill="#1c3557" cname="h-[10.98px] w-[10.98px] ml-auto" />
            </Link>
        </div>
    </div> {/* END */}
    <div className='bg-gray-light border-t-2 border-t-secondary border-opacity-25'>
        <div className='container px-30px 2xl:px-0'>
            <Link to="#" className='flex items-center py-3 lg:py-5'>
            <h4 className='flex-grow text-xs lg:text-base text-secondary'>Psychiatrist Visit</h4>
            <ArrowRightIcon fill="#1c3557" cname="h-[10.98px] w-[10.98px] ml-auto" />
            </Link>
        </div>
    </div> {/* END */}
    <div className='bg-gray-light border-t-2 border-t-secondary border-opacity-25'>
        <div className='container px-30px 2xl:px-0'>
            <Link to="#" className='flex items-center py-3 lg:py-5'>
            <h4 className='flex-grow text-xs lg:text-base text-secondary'>Nutritionist Visit</h4>
            <ArrowRightIcon fill="#1c3557" cname="h-[10.98px] w-[10.98px] ml-auto" />
            </Link>
        </div>
    </div> {/* END */}
    <div className='bg-gray-light border-t-2 border-t-secondary border-opacity-25'>
        <div className='container px-30px 2xl:px-0'>
            <Link to="#" className='flex items-center py-3 lg:py-5'>
            <h4 className='flex-grow text-xs lg:text-base text-secondary'>Pathologist Visit</h4>
            <ArrowRightIcon fill="#1c3557" cname="h-[10.98px] w-[10.98px] ml-auto" />
            </Link>
        </div>
    </div> {/* END */}
    <div className='bg-gray-light border-t-2 border-t-secondary border-opacity-25'>
        <div className='container px-30px 2xl:px-0'>
            <Link to="#" className='flex items-center py-3 lg:py-5'>
            <h4 className='flex-grow text-xs lg:text-base text-secondary'>Pulmonologist Visit</h4>
            <ArrowRightIcon fill="#1c3557" cname="h-[10.98px] w-[10.98px] ml-auto" />
            </Link>
        </div>
    </div> {/* END */}
    <div className='bg-gray-light border-t-2 border-t-secondary border-opacity-25'>
        <div className='container px-30px 2xl:px-0'>
            <Link to="#" className='flex items-center py-3 lg:py-5'>
            <h4 className='flex-grow text-xs lg:text-base text-secondary'>Emergency Medicine</h4>
            <ArrowRightIcon fill="#1c3557" cname="h-[10.98px] w-[10.98px] ml-auto" />
            </Link>
        </div>
    </div> {/* END */}
    <div className='bg-gray-light border-t-2 border-t-secondary border-opacity-25'>
        <div className='container px-30px 2xl:px-0'>
            <Link to="#" className='flex items-center py-3 lg:py-5'>
            <h4 className='flex-grow text-xs lg:text-base text-secondary'>Endocrinologist Visit</h4>
            <ArrowRightIcon fill="#1c3557" cname="h-[10.98px] w-[10.98px] ml-auto" />
            </Link>
        </div>
    </div> {/* END */}
    <div className='bg-gray-light border-t-2 border-t-secondary border-opacity-25'>
        <div className='container px-30px 2xl:px-0'>
            <Link to="#" className='flex items-center py-3 lg:py-5'>
            <h4 className='flex-grow text-xs lg:text-base text-secondary'>Emergency Visit</h4>
            <ArrowRightIcon fill="#1c3557" cname="h-[10.98px] w-[10.98px] ml-auto" />
            </Link>
        </div>
    </div> {/* END */}
</div>
    </>
  )
}

export default FindCareList