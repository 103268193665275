import React, { useState } from "react";
import { Link } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import { useJsApiLoader } from "@react-google-maps/api";
import Map from "../../Map";

import ErrorModal from "../../components/UI/ErrorModal";

import logoicon from "../../assets/images/logo-icon.svg";
import ArrowRightIcon from "../../components/Icons/ArrowRightIcon";

const CareDetail = () => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCpF6GJcN6LJoYUsRZr_JG5QaIeq4UowWo",
    id: "google-map-script",
  });

  // Modal

  const [isOpen, setIsOpen] = useState(false);

  function openModal(event, modalData) {
    setIsOpen({
      title: modalData.title,
      message: modalData.message,
      buttonlink: modalData.buttonlink,
      buttontext: modalData.buttontext,
    });
    return;
  }

  const popupHandler = () => {
    setIsOpen(null);
  };

  const modalObj = {
    title: "reimbursement details",
    message:
      '<div className="my-4"> <ol className="list-group"><li className="flex px-4 py-2 text-xs lg:text-sm text-secondary border-b-2 border-secondary border-opacity-25"><div className="w-4/5">Fair Price</div><div className="w-1/3 text-right text-secondary font-bold text-10px lg:text-sm">$950</div></li><li className="flex px-4 py-2 text-xs lg:text-sm text-secondary border-b-2 border-secondary border-opacity-25"><div className="w-4/5">Start Reimbursement</div><div className="w-1/3 text-right text-secondary font-bold text-10px lg:text-sm">$850</div></li><li className="px-4 py-2 lg:text-sm"><p>Start’s reimbursement amount will be applied to your deductible.</p><strong className="my-1 block">NOTE:</strong><p>A single medical visit is often comprised of multiple components like procedures and tests. To estimate total visit reimbursement, be sure to include components outlined by your physician.</p><p>Questions? Call XXX-XXX-XXXX</p></li></ol></div>',
    buttonlink: "",
    buttontext: "",
  };

  return (
    <>
      <SearchBar />
      <div className="container px-30px 2xl:px-0 lg:mt-12 mb-5 mt-5 flex">
        <div className="text-left">
          <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-1">
            MRI – brain
          </h2>
          <p className="text-secondary text-xs font-bold lg:text-base">
            FAIR PRICE: $950
          </p>
        </div>
      </div>

      <div className="container lg:px-30px 2xl:px-0">
        <div className="lg:grid lg:grid-cols-12 lg:gap-16">
          <div className="lg:col-span-6 pb-10 mt-4">
            <div className="px-30px lg:px-0">
              <Link
              className="block bg-gray-light w-full border-t-6 border-cyan rounded-md  p-3 lg:p-4"
                to="#"
                onClick={(e) => {
                  openModal(e, modalObj);
                }}
              >
                <div className="font-playfair text-secondary font-bold text-24px lg:text-22px lg:mb-2">
                  start pays
                </div>
                <div className="my-3 lg:mt-5 lg:mb-5 text-primary text-28px lg:text-[32px] font-bold">
                  $850
                </div>
                <div className="flex items-center">
                  <div className="text-secondary text-10px lg:text-sm font-bold uppercase">
                  View Details
                  </div>
                  <div className="ml-auto">
                  <ArrowRightIcon
                    fill="#1c3557"
                    cname="h-[11px] w-[12px] lg:h-[16.53px] lg:w-[15.99px]"
                  />
                  </div>
                </div>
              </Link>
              {isOpen && (
                <ErrorModal
                  title={isOpen.title}
                  message={isOpen.message}
                  buttonlink={isOpen.buttonlink}
                  buttontext={isOpen.buttontext}
                  onConfirm={popupHandler}
                />
              )}
             </div>

            <div className="mt-10">
              <h1 className=" text-secondary uppercase font-bold text-xs pl-30px lg:pl-0">
                LOCATIONS NEAR YOU
              </h1>
              <div className="mt-3 h-[253px] w-full bg-gray-light flex justify-center items-center">
                {" "}
                {isLoaded && <Map />}{" "}
              </div>
            </div>
          </div>

          <div className="lg:col-span-6">
            <div className="flex px-8 lg:pl-12 lg:pr-7 py-2">
              <div className="w-4/5 text-xs font-bold uppercase lg:text-base text-secondary">
                PROVIDERS
              </div>
              {/* <div className="w-1/5 text-xs lg:text-lg text-left lg:text-right pl-2 lg:pl-0 text-secondary">
                Estimate
              </div> */}
            </div>
            {/* END */}

            <div className="bg-gray-light lg:bg-transparent border-t-2 border-secondary border-opacity-25 pl-8 pr-5 lg:pl-12 lg:pr-7 py-3">
              <div className="w-full flex mb-4 lg:mb-9">
                <div className="w-4/5 text-xs">
                  <div className="flex relative lg:text-base text-secondary">
                    <div className="absolute -left-5 top-[0.15rem]">
                      <FontAwesomeIcon
                        icon={faBookmark}
                        className="text-secondary text-sm"
                      />
                    </div>
                    Open Imaging
                    <img src={logoicon} alt="" className="w-3 ml-2" />
                  </div>
                  <small className="text-6px lg:text-10px uppercase text-secondary font-bold">
                  3 LOCATIONS NEAR YOU
                  </small>
                </div>
                {/* <div className="w-1/5  text-xs">
                  <span className="block font-bold lg:text-base lg:text-right text-secondary">
                    $750
                  </span>
                  <small className="text-6px uppercase text-primary font-bold lg:hidden">
                  + $100 CREDIT
                  </small>
                </div> */}
              </div>

              <Link to="/find-care/provider/open-imaging" className="flex">
                <div className="text-secondary text-10px lg:text-sm font-bold uppercase">
                  VIEW DETAILS
                </div>
                <div className="ml-auto">
                  <ArrowRightIcon
                    fill="#1c3557"
                    cname="h-[11px] w-[12px] lg:h-[16.53px] lg:w-[15.99px]"
                  />
                </div>
              </Link>
            </div>
            {/* END */}

            <div className="bg-gray-light lg:bg-transparent border-t-2 border-secondary border-opacity-25 pl-8 pr-5 lg:pl-12 lg:pr-7 py-3">
              <div className="w-full flex mb-4 lg:mb-9">
                <div className="w-4/5 text-xs">
                  <div className="flex relative lg:text-base text-secondary">
                     Wasatch Imaging
                    <img src={logoicon} alt="" className="w-3 ml-2" />
                  </div>
                  <small className="text-6px lg:text-10px uppercase text-secondary font-bold">
                  SANDY, UT
                  </small>
                </div>
                {/* <div className="w-1/5  text-xs">
                  <span className="block font-bold lg:text-base lg:text-right text-secondary">
                    $800
                  </span>
                  <small className="text-6px uppercase text-primary font-bold lg:hidden">
                    + $100 CREDIT
                  </small>
                </div> */}
              </div>

              <Link to="/find-care/provider/open-imaging" className="flex">
                <div className="text-secondary text-10px lg:text-sm font-bold uppercase">
                  VIEW DETAILS
                </div>
                <div className="ml-auto">
                  <ArrowRightIcon
                    fill="#1c3557"
                    cname="h-[11px] w-[12px] lg:h-[16.53px] lg:w-[15.99px]"
                  />
                </div>
              </Link>
            </div>
            {/* END */}

            <div className="bg-gray-light lg:bg-transparent border-t-2 border-secondary border-opacity-25 pl-8 pr-5 lg:pl-12 lg:pr-7 py-3">
              <div className="w-full flex mb-4 lg:mb-9">
                <div className="w-4/5 text-xs">
                  <div className="flex relative lg:text-base text-secondary">
                    US MRI
                    <img src={logoicon} alt="" className="w-3 ml-2" />
                  </div>
                  <small className="text-6px lg:text-10px uppercase text-secondary font-bold">
                    3 LOCATIONS NEAR YOU
                  </small>
                </div>
                {/* <div className="w-1/5  text-xs">
                  <span className="block font-bold lg:text-base lg:text-right text-secondary">
                    $825
                  </span>
                  <small className="text-6px uppercase text-primary font-bold lg:hidden">
                    + $100 CREDIT
                  </small>
                </div> */}
              </div>

              <Link to="/find-care/provider/open-imaging" className="flex">
                <div className="text-secondary text-10px lg:text-sm font-bold uppercase">
                  VIEW DETAILS
                </div>
                <div className="ml-auto">
                  <ArrowRightIcon
                    fill="#1c3557"
                    cname="h-[11px] w-[12px] lg:h-[16.53px] lg:w-[15.99px]"
                  />
                </div>
              </Link>
            </div>
            {/* END */}

            <div className="bg-gray-light lg:bg-transparent border-t-2 border-secondary border-opacity-25 pl-8 pr-5 lg:pl-12 lg:pr-7 py-3">
              <div className="w-full flex mb-4 lg:mb-9">
                <div className="w-4/5 text-xs">
                  <div className="flex relative lg:text-base text-secondary">
                    Mountain Medical Specialists Radiology
                    <img src={logoicon} alt="" className="w-3 ml-2" />
                  </div>
                  <small className="text-6px lg:text-10px uppercase text-secondary font-bold">
                  2 LOCATIONS NEAR YOU
                  </small>
                </div>
                {/* <div className="w-1/5  text-xs">
                  <span className="block font-bold lg:text-base lg:text-right text-secondary">
                  $1,404
                  </span>
                  <small className="text-6px uppercase text-red font-bold lg:hidden">
                  - $554 OWED
                  </small>
                </div> */}
              </div>

              <Link to="/find-care/provider/open-imaging" className="flex">
                <div className="text-secondary text-10px lg:text-sm font-bold uppercase">
                  VIEW DETAILS
                </div>
                <div className="ml-auto">
                  <ArrowRightIcon
                    fill="#1c3557"
                    cname="h-[11px] w-[12px] lg:h-[16.53px] lg:w-[15.99px]"
                  />
                </div>
              </Link>
            </div>
            {/* END */}

            


          </div>
        </div>
      </div>
    </>
  );
};

export default CareDetail;
