import React  from 'react'
import { Link } from "react-router-dom";
import ArrowRightIcon from '../../../components/Icons/ArrowRightIcon'


const SelectYourQuoteforFamily = () => {
  return (
    <>
    <div className="h-full lg:h-auto lg:min-h-[calc(100vh_-_107px)] flex lg:justify-between lg:items-center">
        <div className="h-full flex flex-col justify-between items-center lg:h-auto w-full pt-12 lg:pt-0  lg:px-0 min-h-[calc(100vh_-_60px)] lg:min-h-full">
        <div className="container px-30px 2xl:px-0">
        <div className="text-center mb-5 lg:mb-12">
            <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-2">select your start plan.</h2>
            <p className='text-cyan text-xs font-bold lg:text-lg'>Please select the plan that best suits your family.</p>
        </div>
        <div className="lg:grid lg:grid-cols-12 lg:gap-12">
        
        <div className="lg:col-span-4 mb-8 lg:mb-0">
        <ul className='bg-gray-light border-t-6 rounded-md border-cyan'>
            <li className='p-4'>
                <h3 className='font-playfair text-secondary text-2xl lg:text-28px'>option 1:</h3>
              </li>
              <li className='inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 text-xs text-secondary'>
                <div className='w-3/4 lg:text-lg'>Premium</div>
                <div className='w-1/4 font-bold text-10px lg:text-sm'>$429/mo</div>
              </li>
              <li className='inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 text-xs text-secondary'>
                <div className='w-3/4 lg:text-lg'>Deductible</div>
                <div className='w-1/4 font-bold text-10px lg:text-sm'>$2,000</div>
              </li>
              <li className='inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 text-xs text-secondary'>
                <div className='w-3/4 lg:text-lg'>Max OOP</div>
                <div className='w-1/4 font-bold text-10px lg:text-sm'>$4,000</div>
              </li>
                <Link to="/select-go-live-date" className='inline-flex items-center w-full p-4 text-10px lg:text-base text-secondary'>
                <div className='font-bold'>CHOOSE THIS PLAN</div>
                <div className="ml-auto"><ArrowRightIcon fill="#1c3557" cname="w-[10.98px] h-[10.85px]" /></div>
                </Link>
            </ul>
        </div>

        <div className="lg:col-span-4  mb-8 lg:mb-0">
        <ul className='bg-gray-light border-t-6 rounded-md border-cyan'>
            <li className='p-4'>
                <h3 className='font-playfair text-secondary text-2xl lg:text-28px'>option 2:</h3>
              </li>
              <li className='inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 text-xs text-secondary'>
                <div className='w-3/4 lg:text-lg'>Premium</div>
                <div className='w-1/4 font-bold text-10px lg:text-sm'>$529/mo</div>
              </li>
              <li className='inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 text-xs text-secondary'>
                <div className='w-3/4 lg:text-lg'>Deductible</div>
                <div className='w-1/4 font-bold text-10px lg:text-sm'>$4,000</div>
              </li>
              <li className='inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 text-xs text-secondary'>
                <div className='w-3/4 lg:text-lg'>Max OOP</div>
                <div className='w-1/4 font-bold text-10px lg:text-sm'>$7,000</div>
              </li>
                <Link to="/select-go-live-date" className='inline-flex items-center w-full p-4 text-10px lg:text-base text-secondary'>
                <div className='font-bold'>CHOOSE THIS PLAN</div>
                <div className="ml-auto"><ArrowRightIcon fill="#1c3557" cname="w-[10.98px] h-[10.85px]" /></div>
                </Link>
            </ul>
        </div>

        <div className="lg:col-span-4  mb-8 lg:mb-0">
        <ul className='bg-gray-light border-t-6 rounded-md border-cyan'>
            <li className='p-4'>
                <h3 className='font-playfair text-secondary text-2xl lg:text-28px'>option 3:</h3>
              </li>
              <li className='inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 text-xs text-secondary'>
                <div className='w-3/4 lg:text-lg'>Premium</div>
                <div className='w-1/4 font-bold text-10px lg:text-sm'>$629/mo</div>
              </li>
              <li className='inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 text-xs text-secondary'>
                <div className='w-3/4 lg:text-lg'>Deductible</div>
                <div className='w-1/4 font-bold text-10px lg:text-sm'>$6,000</div>
              </li>
              <li className='inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 text-xs text-secondary'>
                <div className='w-3/4 lg:text-lg'>Max OOP</div>
                <div className='w-1/4 font-bold text-10px lg:text-sm'>$7,000</div>
              </li>
                <Link to="/select-go-live-date" className='inline-flex items-center w-full p-4 text-10px lg:text-base text-secondary'>
                <div className='font-bold'>CHOOSE THIS PLAN</div>
                <div className="ml-auto"><ArrowRightIcon fill="#1c3557" cname="w-[10.98px] h-[10.85px]" /></div>
                </Link>
            </ul>
        </div>
  
          
        </div>
         </div>
        </div>
      </div>

    </>
  )
}

export default SelectYourQuoteforFamily