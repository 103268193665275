import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { Disclosure } from '@headlessui/react'

import SubHeaderUser from '../../components/SubHeaderUser';
import { ChevronRightIcon, } from '@heroicons/react/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faReceipt,
  faCircleExclamation,
  faPenToSquare,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons'

import ErrorModal from '../../components/UI/ErrorModal';
import Dropzone from '../../components/Dropzone';
import ArrowRightIcon from '../../components/Icons/ArrowRightIcon';

import demoImg from '../../assets/images/IMG_9121.png';

function CardGeneratedNewExpense() {

  const [isOpen, setIsOpen] = useState(false);

  function openModal(event, modalData){
    setIsOpen({
      title: modalData.title,
      message: modalData.message
    });
    return;
  }

  const popupHandler = () => {
    setIsOpen(null);
  };

  const modalObj = {
    title: 'reimbusrsment status',
    message: '<div className="my-4 pl-6 pr-10 lg:text-sm"><p className="text-sm font-bold mb-2">What this means:</p><p>The status of your reimbursement is defined by the following:</p><p><strong>Upload Receipt:</strong> To receive reimbursement  or applied benefits for an eligible expense, you must upload an itemized receipt under “Proof of Care.” Once you’ve submitted your itemized receipt, this step is considered “done.”</p><p><strong>In Review:</strong> Once Start has approved the expense and reviewed your itemized receipt and determined the final reimbursable amount for care, this step is considered “done.”</p><p><strong>Compete:</strong> When the reimbursable amount for care has been applied, this step is considered “done.”</p><p><strong>Note:</strong> If the expense is denied because it is not an eligible expense under your policy, the reimbursement status will be considered “complete” and an indicator that your  expense was denied will be shown for the expense in your dashboard.</p></div>'
  }

  const modalObj1 = {
    title: 'summary',
    message: '<div className="my-4 pl-6 pr-10 lg:text-sm"><p className="text-sm font-bold mb-2">What this means:</p><p>Your summary is a benefits breakdown regarding a given expense. </p><p><strong>Amount Paid:</strong> The amount you paid at service by your designated account.</p><p><strong>Start Reimbursement:</strong> Your refund amount. This will be applied to your deductible. Once your deductible is met, 20% of the refund  amount will be applied to your out-of-pocket max.</p><p><strong>Your Responsibility:</strong> The difference between the    amount paid and the Start Reimbursement   that you are responsible for if the amount paid   Is higher than the reimbursement amount. </p><p><strong>Amount Owed to You:</strong> The difference between   the amount paid and the Start Reimbursement   that is owed to you if the amount paid   Is lower than the reimbursement amount. </p><p><strong>Applied Benefits:</strong> The amount that is   applied to your deductible or out-of-pocket max based on where you are at currently in your benefits.</p></div>'
  }

  const modalObj2 = {
    title: 'services provided',
    message: '<div className="my-4 pl-6 pr-10 lg:text-sm"><p className="text-sm font-bold mb-2">What you need to provide:</p><p>This is a detailed account of care received. Once the expense marked “complete,” the  provider rate for each component of care that was received, as well as the reimbursable  amount that Start pays associated with the    components of care will be detailed.</p></div>'
  }

  const modalObj3 = {
    title: 'proof of care',
    message: '<div className="my-4 pl-6 pr-10 lg:text-sm"><p className="text-sm font-bold mb-2">What you need to provide:</p><p>To receive your benefits, please provide the following information in your proof of care no later than 30 days after receiving care.</p><ul><li>Patient’s full name</li><li>Provider’s name and address</li><li>Date(s) of service</li><li>Charge amount(s)</li><li>CPT / Procedure code(s) </li><li>Prescription drug(s) name, form, strength and dosage or NDC code.</li><li>Diagnosis code(s)</li></ul></div>'
  }

  
  // File upload
  const [file, setFile] = useState(null)

  return (
    <>
    <SubHeaderUser />
    <header className='container px-30px 2xl:px-0 lg:mt-12 mb-5  lg:mb-8 mt-5 flex'>
      <div className='text-left'>
        <h2 className='font-playfair text-secondary font-bold text-2xl lg:text-38px mb-1'>expense #XXXX</h2>
        <p className='text-secondary text-xs font-bold lg:text-base'>MEMBER: JANE DOE</p>
        <p className='text-secondary text-6px lg:text-xs font-bold'>MM/DD/YY</p>
      </div>
    </header>

        <div className='container px-30px 2xl:px-0'>
            <div className='lg:grid lg:grid-cols-12 lg:gap-16'>
                <div className='lg:col-span-6 pb-10'>
                    <Link
                      to="/manage-start-card"
                      className="bg-gray-light w-full border-t-6 border-cyan rounded-md p-3 block">
                      <div className="flex font-playfair text-secondary font-bold text-[18px] lg:text-22px">
                          payment
                      </div>
                      <div className='text-xs lg:text-lg lg:pt-4 lg:pb-8 py-2 text-secondary'>
            <span className='block'>A payment of $5.00</span>
            was made on <strong>MM/DD/YY</strong>
          </div>
                      <div className="flex items-center">
                        <div className="text-secondary text-10px lg:text-sm font-bold uppercase">
                        PAYMENT METHOD: JANE DOE’S ACCT.
                        </div>
                        <div className="ml-auto">
                        <ArrowRightIcon fill="#1c3557" cname="h-[11px] w-3 lg:w-[15.99px] lg:h-[16.53px] ml-auto" />
                        </div>
                      </div>
                    </Link>

                    


                    

        <Disclosure defaultOpen="true">
        {({ open }) => (
        <div className="card">
        <div className="card-title flex"> <h4 className='lg:text-22px flex items-center'>reimbursement status 
        <FontAwesomeIcon icon={faCircleExclamation} className="text-secondary text-xs ml-2 cursor-pointer" onClick={(e) => {openModal(e, modalObj) }} /></h4>


        {isOpen && (
        <ErrorModal
        title={isOpen.title}
        message={isOpen.message}
        onConfirm={popupHandler}
        />
        )}

        <Disclosure.Button className="ml-auto">
        <ChevronRightIcon className={`${ open ? 'transform rotate-90' : ''} h-5 w-5 lg:h-8 lg:w-8 text-secondary`} />
        </Disclosure.Button>
        </div>

        <Disclosure.Panel className="text-gray-500">
        <ul className="list-group">
        <li className="">
        <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
        <input type="checkbox" className="form-checkbox" checked />
        <span className="ml-2 text-xs lg:text-base text-secondary">
        Upload Receipt
        </span>
        </label>
        </li>
        <li className="">
        <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
        <input type="checkbox" className="form-checkbox" checked />
        <span className="ml-2 text-xs lg:text-base text-secondary">
        In Review
        </span>
        </label>
        </li>
        <li className="">
        <label className="inline-flex items-center w-full p-4 border-b-0 border-secondary border-opacity-25 cursor-pointer">
        <input type="checkbox" className="form-checkbox"  checked />
        <span className="ml-2 text-xs lg:text-base text-secondary">
        Completed
        </span>
        </label>
        </li>
        </ul> 
        </Disclosure.Panel>
        {/**/}
        </div>
        )}
        </Disclosure>

        <Disclosure defaultOpen="true">
        {({ open }) => (
        <div className="card">
        <div className="card-title flex"> <h4 className='lg:text-22px flex items-center'>summary
        <FontAwesomeIcon icon={faCircleExclamation} className="text-secondary text-xs ml-2 cursor-pointer" onClick={(e) => {openModal(e, modalObj1) }} /></h4>


        {isOpen && (
        <ErrorModal
        title={isOpen.title}
        message={isOpen.message}
        onConfirm={popupHandler}
        />
        )}

        <Disclosure.Button className="ml-auto">
        <ChevronRightIcon className={`${ open ? 'transform rotate-90' : ''} h-5 w-5 lg:h-8 lg:w-8 text-secondary`} />
        </Disclosure.Button>
        </div>

        <Disclosure.Panel className="text-gray-500">
        <ul className='list-group'>
          <li className='flex px-4 py-4  lg:py-5  text-secondary items-center  border-b-2 border-secondary border-opacity-25'>
              <div className="w-3/4 lg:w-3/4 text-xs lg:text-base">Amount Paid</div>
              <div className="w-24 lg:w-1/4 ml-auto text-left text-secondary font-bold text-10px lg:text-base">$5.00</div>
          </li>
          <li className='flex px-4 py-4 lg:py-5  text-secondary  border-b-2 border-secondary border-opacity-25'>
              <div className="w-3/4 lg:w-3/4 text-xs lg:text-base">Start Reimbursement</div>
              <div className="w-24 lg:w-1/4 ml-auto text-left text-red font-bold text-10px lg:text-base">NOT COVERED</div>
          </li>
          <li className='flex px-4 py-4 lg:py-5   text-secondary  border-b-2 border-secondary border-opacity-25'>
              <div className="w-3/4 lg:w-3/4 text-xs lg:text-base">Your Responsibility</div>
              <div className="w-24 lg:w-1/4 ml-auto text-left text-secondary font-bold text-10px lg:text-base">$2,934</div>
          </li>
          <li className='flex px-4 py-4  lg:py-5  text-secondary   border-b-2 border-secondary border-opacity-25'>
              <div className="w-3/4 lg:w-3/4 text-xs lg:text-base lg:leading-normal">Applied Benefits <small className='block font-bold uppercase text-6px lg:text-8px'>Applied to deductible</small> </div>
              <div className="w-24 lg:w-1/4 ml-auto text-left text-red font-bold text-10px lg:text-base">NOT COVERED</div>
          </li>
        </ul>
        
        <Link to="#" className='card-footer  lg:py-5'>
                <div className='font-bold text-10px lg:text-base'>BENEFITS BREAKDOWN</div>
                <div className='text-secondary ml-auto'>
                <ArrowRightIcon fill="#1c3557" cname="h-[11px] w-3 lg:w-4 lg:h-4" />
                </div>
              </Link>
        </Disclosure.Panel>
        {/**/}
        </div>
        )}
        </Disclosure>

        <Disclosure defaultOpen="true">
       {({ open }) => (
       <div className="card">
      <div className="card-title flex"><h4 className='lg:text-22px flex items-center'>services provided <FontAwesomeIcon icon={faCircleExclamation} className="text-secondary text-xs ml-2 cursor-pointer" onClick={(e) => {openModal(e, modalObj2) }} /></h4>
      <Disclosure.Button className="ml-auto">
        <ChevronRightIcon className={`${ open ? 'transform rotate-90' : ''} h-5 w-5 lg:h-8 lg:w-8 text-secondary`} />
      </Disclosure.Button>
      </div>

      <Disclosure.Panel className="text-gray-500">
      <ul className="list-group">
          <li className='flex items-center w-full px-4 py-2 lg:py-5 border-b-2 border-secondary border-opacity-25 text-10px lg:text-base text-secondary'>
              <div className="w-1/3 font-bold">CARE RECEIVED</div>
              <div className="w-1/3">Provider Rate</div>
              <div className="w-1/3">Reimbursement</div>
          </li>
          <li className='flex items-start w-full px-4 py-4  lg:py-5 border-b-2 border-secondary border-opacity-25 text-10px lg:text-base text-secondary'>
              <div className="w-1/3 text-xs  lg:text-base">
                  Doctor Visit
                  <p className='text-6px lg:text-10px leading-normal'>
                    <span className='font-bold uppercase'>Merchant:</span> Name <br /> 
                    <span className='font-bold uppercase'>SERVICE DATE:</span> MM/DD/YY</p>
              </div>
              <div className="w-1/3 text-10px  lg:text-base font-bold">$5.00</div>
              <div className="w-1/3 text-red font-bold uppercase">NOT COVERED</div>
          </li>
          <li className='flex items-center w-full px-4 py-4  lg:py-5 border-b-0 border-secondary border-opacity-25 text-10px lg:text-base text-secondary'>
              <div className="w-1/3">Total</div>
              <div className="w-1/3 text-10px lg:text-base font-bold">$5.00</div>
              <div className="w-1/3 text-red font-bold uppercase">NOT COVERED</div>
          </li>
      </ul> 
      </Disclosure.Panel>
      {/**/}
        </div>
        )}
        </Disclosure>
    
       <Disclosure defaultOpen="true">
       {({ open }) => (
       <div className="card">
      <div className="card-title flex"> <h4 className='lg:text-22px flex items-center'>proof of care <FontAwesomeIcon icon={faCircleExclamation} className="text-secondary text-xs ml-2 cursor-pointer" onClick={(e) => {openModal(e, modalObj3) }} /></h4>
      <Disclosure.Button className="ml-auto">
        <ChevronRightIcon className={`${ open ? 'transform rotate-90' : ''} h-5 w-5 lg:h-8 lg:w-8 text-secondary`} />
      </Disclosure.Button>
      </div>

      <Disclosure.Panel className="text-gray-500">
      <ul className="list-group">
        <li className='flex items-center w-full px-4 py-4 lg:py-5 border-b-2 border-secondary border-opacity-25'>
          <div className='mr-5'><FontAwesomeIcon icon={faReceipt} className="text-secondary text-lg" /></div>
          <div className="text-xs lg:text-base text-secondary">File #XXXX</div>
          <div className="ml-auto">
              <Link to="#" className='mr-3'><FontAwesomeIcon icon={faPenToSquare} className="text-secondary text-xs" /></Link>
              <Link to="#"><FontAwesomeIcon icon={faTrashCan} className="text-secondary text-xs" /></Link>
          </div>
        </li>
        <li className='flex items-center w-full px-4 py-4 lg:py-5 border-b-0 border-secondary border-opacity-25'>
          <div className='mr-5'><FontAwesomeIcon icon={faReceipt} className="text-secondary text-lg" /></div>
          <div className="text-xs lg:text-base text-secondary">File #XXXX</div>
          <div className="ml-auto">
              <Link to="#" className='mr-3'><FontAwesomeIcon icon={faPenToSquare} className="text-secondary text-xs" /></Link>
              <Link to="#"><FontAwesomeIcon icon={faTrashCan} className="text-secondary text-xs" /></Link>
          </div>
        </li>
      </ul> 
      </Disclosure.Panel>
      {/**/}
        </div>
        )}
        </Disclosure>
        
       <Disclosure defaultOpen="true">
       {({ open }) => (
       <div className="card">
      <div className="card-title flex"> <h4 className='lg:text-22px flex items-center'>activity <FontAwesomeIcon icon={faCircleExclamation} className="text-secondary text-xs ml-2 cursor-pointer" onClick={(e) => {openModal(e, modalObj1) }} /></h4>
      <Disclosure.Button className="ml-auto">
        <ChevronRightIcon className={`${ open ? 'transform rotate-90' : ''} h-5 w-5 lg:h-8 lg:w-8 text-secondary`} />
      </Disclosure.Button>
      </div>

      <Disclosure.Panel className="text-gray-500">
        <div className='grid grid-cols-7 py-4 lg:py-6 !pt-3 border-b-2 border-secondary border-opacity-25'>
            <div className='col-span-2'><div className='pl-4 font-bold text-secondary text-xs lg:text-lg'>MM/DD/YY</div></div>
            <div className='col-span-3'><div className='text-xs lg:text-base text-secondary'>You Paid Provider <small className="block text-6px font-bold leading-none	">PAID WITH JANE DOE’s ACCT.</small></div></div>
            <div className='col-span-2'><div className='text-red text-xs lg:text-lg font-bold text-right pr-5 lg:pr-8'>$XX.XX</div></div>
        </div>
        <div className='grid grid-cols-7 py-4 lg:py-6 border-b-2 border-secondary border-opacity-25'>
            <div className='col-span-2'><div className='pl-4 font-bold text-secondary text-xs lg:text-lg'>MM/DD/YY</div></div>
            <div className='col-span-3'><div className='text-xs lg:text-base text-secondary'>Proof of Care Submitted</div></div>
            <div className='col-span-2'><div className='text-red text-xs lg:text-lg font-bold text-right pr-5 lg:pr-8'></div></div>
        </div>
        <div className='grid grid-cols-7 py-4 lg:py-6 border-b-2 border-secondary border-opacity-25'>
            <div className='col-span-2'><div className='pl-4 font-bold text-secondary text-xs lg:text-lg'>MM/DD/YY</div></div>
            <div className='col-span-3'><div className='text-xs lg:text-base text-secondary'>In Review by Start</div></div>
            <div className='col-span-2'><div className='text-red text-xs lg:text-lg font-bold text-right pr-5 lg:pr-8'></div></div>
        </div>
        <div className='grid grid-cols-7 py-4 lg:py-6 border-b-0 border-secondary border-opacity-25'>
            <div className='col-span-2'><div className='pl-4 font-bold text-secondary text-xs lg:text-lg'>MM/DD/YY</div></div>
            <div className='col-span-3'><div className='text-xs lg:text-base text-secondary'>Reimbursement</div></div>
            <div className='col-span-2'><div className='text-red text-xs lg:text-lg font-bold text-right pr-5 lg:pr-8'>NOT COVERED</div></div>
        </div>
      </Disclosure.Panel>
      {/**/}
        </div>
        )}
        </Disclosure>

                </div>{/* END */}
                
                <div className='lg:col-span-6 hidden lg:block'>
                    <div className='border-2 border-secondary p-8 rounded-md border-opacity-50 bg-gray-light'>
                          <h3 className='font-playfair text-secondary font-bold text-22px mb-2'>add proof of care <FontAwesomeIcon icon={faCircleExclamation} className="text-secondary text-xs ml-1 cursor-pointer" /></h3>
                          <p className='text-secondary text-lg leading-6'>To receive your benefits, please provide the following your proof 
of care no later than 30 days after receiving care. </p>
        <div className='my-10'>
          <h4 className='font-playfair text-secondary font-bold text-22px mb-1'>file #XXXX</h4>
          <p className='text-secondary text-xs font-bold mb-3'>UPLOADED MM/DD/YY</p>
          <figure className='flex flex-col items-center justify-center h-full space-y-3 border-2 border-dashed border-secondary rounded-xl bg-white py-8'>
          <img src={demoImg} alt="" />
          </figure>
        </div>

        <div className='my-10'>
          <h4 className='font-playfair text-secondary font-bold text-22px mb-1'>file #XXXX</h4>
          <p className='text-secondary text-xs font-bold mb-3'>UPLOADED MM/DD/YY</p>
          <figure className='flex flex-col items-center justify-center h-full space-y-3 border-2 border-dashed border-secondary rounded-xl bg-white py-8'>
          <img src={demoImg} alt="" />
          </figure>
        </div>
        {/* <Dropzone setFile={setFile} />
        {file?.name} */}
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default CardGeneratedNewExpense