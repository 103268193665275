 import React, { useEffect, useState, useRef } from 'react'
import { Link, useNavigate } from "react-router-dom";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Logo from '../../../assets/images/logo-blue.png';
import Logo2x from '../../../assets/images/logo-blue@2x.png';
import ErrorMessage from '../../../components/ErrorMessage';
import ArrowRight from '../../../components/Icons/ArrowRight';

const Login = () => {

  useEffect(()=>{
    document.title = 'Login';
  }, []);

  const [enteredEmail, setEnteredEmail] = useState('');
  const [enteredPassword, setEnteredPassword] = useState('');
  const [error, setError] = useState();

  const navigateurl  = useNavigate(); 
  const emailRef = useRef();
  const passwordRef = useRef();

  const loginObj = {
    email : 'codechk@gmail.com',
    password : '123456'
  }

  const url = '/dashboard';

  const submitHandler = (event) => {
    event.preventDefault();
    setError(null);

    if (!enteredEmail.includes('@') || enteredEmail.trim().length === 0) {
      setError({
        message: 'Please enter a valid Email Address.'
      });

      emailRef.current.focus();
      return;
    }

    if (enteredPassword.trim().length < 6) {
      setError({
        message: 'Please enter a password minimum of 6 Letters.'
      });
      passwordRef.current.focus();
      return;
    }

    if(enteredEmail === loginObj.email && enteredPassword === loginObj.password){
      navigateurl(url);
    } else {
      setError({
        message: 'Your email or password is incorrect. You may try again or click "Forgot Email or Password."'
      });
      return;
    }
    
  }

  const emailChangeHandler = (event) => {
    setError(null);
    setEnteredEmail(event.target.value);
    
  }

  const passwordChangeHandler = (event) => {
    setError(null);
    setEnteredPassword(event.target.value);
  }
  
    return (
        <React.Fragment>
        <div className="min-h-full flex justify-center  px-12 sm:px-6 lg:px-8">
        <div className="max-w-md w-full pt-40">
          
          <div className="text-center pt-12">
            <img src={Logo} srcSet={`${Logo2x} 2x`} alt="starthealth" className="mx-auto" />
          </div>

          <div className="text-center mt-6">
            {error && (
              <ErrorMessage
                message={error.message}
              />
            )}
          </div>

          <form className="mt-8 space-y-6">
                <label className="block">
                  <input type="email" className="form-control" placeholder="Email" ref={emailRef} onChange={emailChangeHandler} />
                </label>

                <label className="block">
                  <input type="password" className="form-control" placeholder="Password" ref={passwordRef} onChange={passwordChangeHandler} />
                </label>

                <div className="mt-1 text-center">
                  <Link to="/forgot-password" className="text-xs font-bold text-cyan">Forgot Email or Password</Link>
                </div>

                <div className="flex justify-between mt-4">
                    <button className="btn uppercase" onClick={submitHandler}>Login</button>
                </div>
          </form>

          <div className="text-center mt-40 pb-12">
              <h4 className='text-lg font-bold text-secondary mb-3'>Don’t have an account?</h4>
              <Link to="/enter-your-email" className="text-base	font-bold text-cyan uppercase flex justify-center items-center ">SIGN UP 
              <ArrowRight fill="#A9DADC" cname="w-[10.98px] h-[10.85px] inline ml-1.5" />
              </Link>
          </div>
        </div>
      </div>
      </React.Fragment>
    );
};

export default Login;