import React from 'react'
import { Link } from "react-router-dom";
import Logo from '../../../assets/images/logo-blue.svg'

const MembershipDenied = () => {
  return (
    <>
    <div className="h-full lg:h-auto lg:min-h-[calc(100vh_-_107px)] flex lg:justify-between lg:items-center">
        <div className="h-full flex flex-col justify-between items-center lg:h-auto w-full pt-12 lg:py-0 px-8 lg:px-0 min-h-[calc(100vh_-_60px)] lg:min-h-full">
        <div className="max-w-lg mx-auto w-full">
        <div className="text-center mb-5 lg:mb-7">
            <div className="mb-8"><img src={Logo} alt="" className="mx-auto w-48"  /></div>
            <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-2">thank you for giving us a try.
</h2>
<p className='pt-5 text-xs lg:text-lg	 text-secondary text-left'>Unfortunately, after reviewing your health questionnaire we’ve determined that <strong>Start will not be the best fit</strong> for  you and your needed health coverage.</p>    
        </div>
        <div className="text-center mt-9 mb-5 font-bold"><Link to="/" className='btn uppercase  px-10'>OKAY</Link></div>
        </div>
        
      

        
        </div>
      </div>
    </>
  )
}

export default MembershipDenied