import React from 'react'
import { Link } from "react-router-dom";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faBookmark, faArrowRight, faDollarSign, faMagnifyingGlassDollar, faPrescription, faReceipt, faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import ArrowRightIcon from '../../components/Icons/ArrowRightIcon';


const AccountSummary = () => {
  return (
    <>
  
      <div className='container px-30px 2xl:px-0'>
        <div className="text-left lg:mt-12 mb-5 mt-5">
          <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-1">hsa summary</h2>
          <p className="text-secondary text-xs font-bold lg:text-base">MEMBER: JANE DOE</p>
        </div>

        <div className="lg:grid lg:grid-cols-12 lg:gap-10">
          <div className="lg:col-span-6">
              <div className='card'>
              <h4 className='card-title lg:text-22px lg:pb-10'>available balance</h4>
              <div className='text-3xl font-bold text-primary px-4 pb-0 lg:pb-5'>
              $2,934.32
              </div>

              <Link to="/transaction-history" className='card-footer lg:hidden'>
              <div className='font-bold text-10px lg:text-sm'>VIEW TRANSACTIONS</div>
              <div className='text-secondary ml-auto'>
              <ArrowRightIcon fill="#1c3557" cname="h-[11px] w-3 lg:w-4 lg:h-4 ml-auto mt-1" />
              </div>
              </Link>
              </div> {/* END .card */}

              <div className='card'>
              <h4 className='card-title lg:text-22px pb-0'>details</h4>

              <ul className='list-group'>
              <li className='list-group-item'>
              <div className="w-3/4 lg:text-base">Available to Spend</div>
              <div className="w-1/4 text-right font-bold lg:text-base">$2,934.32</div>
              </li>
              <li className='list-group-item'>
              <div className="w-3/4 lg:text-base">Pending Credits</div>
              <div className="w-1/4 text-right font-bold lg:text-base">$0</div>
              </li>
              <li className='list-group-item last'>
              <div className="w-3/4 lg:text-base">Pending Debits</div>
              <div className="w-1/4 text-right font-bold lg:text-base">- $23.83</div>
              </li>
              </ul>
              </div> {/* END .card */}

              <div className='card hidden lg:block'>
              <Link to="#">
              <h4 className='card-title lg:text-22px lg:pb-10'>contribute</h4>
              <div className='text-3xl font-bold text-primary px-4'></div>
              <div  className='card-footer'>
              <div className='font-bold text-10px lg:text-sm'>MAKE AN HSA CONTRIBUTION</div>
              <div className='text-secondary ml-auto'>
              <ArrowRightIcon fill="#1c3557" cname="h-[11px] w-3 lg:w-4 lg:h-4 ml-auto mt-1" />
              </div>
              </div>
              </Link>
              </div> {/* END .card */}

              <div className='card'>
              <Link to="#">
              <h4 className='card-title  lg:text-22px lg:pb-10'>transfer existing hsa funds</h4>
              <div className='text-3xl font-bold text-primary px-4'></div>
              <div  className='card-footer'>
              <div className='font-bold text-10px lg:text-sm'>CALL START HEALTH</div>
              <div className='text-secondary ml-auto'>
              <ArrowRightIcon fill="#1c3557" cname="h-[11px] w-3 lg:w-4 lg:h-4 ml-auto mt-1" />
              </div>
              </div>
              </Link>
              </div> {/* END .card */}


          </div>
          <div className="lg:col-span-6 hidden lg:block">
          <div className='grid grid-cols-5 py-4'>
    <div className='col-span-3'><div className='pl-14 text-xs text-secondary font-bold lg:text-base'>TRANSACTIONS</div></div>
    <div className='col-span-2'><div className='text-secondary text-xs lg:text-lg pr-8 lg:text-right max-w-[114px] lg:max-w-[142px] ml-auto'>Amount Paid</div></div>
</div>

<div className='grid grid-cols-5 py-4 bg-gray-light lg:bg-transparent border-t-2 border-t-secondary border-opacity-25'>
    <div className='col-span-3'>
    <div className='pl-14'>
                        <div className="text-secondary text-xs lg:text-base mb-1">Mountain Point #4321</div>
                        <div className="text-secondary  text-8px lg:text-10px uppercase font-bold leading-none lg:leading-3">
                        USER WITHDRAW <br/>
                            02/22/22
                        </div>

                        <div className="mt-4 flex items-center">
                            <Link to="/pre-deductible-2"
                                className="py-1 text-center w-[64px] inline-block rounded-5px text-10px bg-primary text-white cursor-pointer font-medium uppercase">PENDING</Link>
                        </div>
                    </div>
    </div>
    <div className='col-span-2'><div className='text-secondary text-xs lg:text-lg  pr-8 font-bold lg:text-right max-w-[114px]  lg:max-w-[142px] ml-auto'>- $23.83</div></div>
</div>
{/* item #1 */}

<div className='grid grid-cols-5 py-4 bg-gray-light lg:bg-transparent border-t-2 border-t-secondary border-opacity-25'>
    <div className='col-span-3'>
    <div className='pl-14'>
                        <div className="text-secondary text-xs lg:text-base mb-1">Interest Earned</div>
                        <div className="text-secondary text-8px lg:text-10px uppercase font-bold  leading-none lg:leading-3">
                            MEMBER: JANE DOE <br/>
                            01/12/22
                        </div>

                        <div className="mt-4 flex items-center">
                            <Link to="/pre-deductible-2"
                                className="py-1 text-center w-[64px] inline-block rounded-5px text-10px bg-cyan text-white cursor-pointer font-medium uppercase">complete</Link>
                        </div>
                    </div>
    </div>
    <div className='col-span-2'><div className='text-secondary text-xs lg:text-lg  pr-8 font-bold lg:text-right max-w-[114px]  lg:max-w-[142px] ml-auto'>$18.54</div></div>
</div>
{/* item #2 */}

<div className='grid grid-cols-5 py-4 bg-gray-light lg:bg-transparent border-t-2 border-t-secondary border-opacity-25'>
    <div className='col-span-3'>
    <div className='pl-14'>
                        <div className="text-secondary text-xs lg:text-base mb-1">Start Reimbursement</div>
                        <div className="text-secondary text-8px lg:text-10px uppercase font-bold  leading-none lg:leading-3">
                        Start Reimbursement <br/>
                        08/06/21
                        </div>

                        <div className="mt-4 flex items-center">
                            <Link to="/pre-deductible-2"
                                className="py-1 text-center w-[64px] inline-block rounded-5px text-10px bg-cyan text-white cursor-pointer font-medium uppercase">complete</Link>
                        </div>
                    </div>
    </div>
    <div className='col-span-2'><div className='text-secondary text-xs lg:text-lg  pr-8 font-bold lg:text-right max-w-[114px]  lg:max-w-[142px] ml-auto'>$1.70</div></div>
</div>
{/* item #3 */}

<div className='grid grid-cols-5 py-4 bg-gray-light lg:bg-transparent border-t-2 border-t-secondary border-opacity-25'> 
    <div className='col-span-3'>
    <div className='pl-14'>
                        <div className="text-secondary text-xs lg:text-base mb-1">User Direct Deposit</div>
                        <div className="text-secondary text-8px lg:text-10px uppercase font-bold  leading-none lg:leading-3">
                        USEr CONTRIBUTION <br/>
                        03/28/21
                        </div>

                        <div className="mt-4 flex items-center">
                            <Link to="/pre-deductible-2"
                                className="py-1 text-center w-[64px] inline-block rounded-5px text-10px bg-cyan text-white cursor-pointer font-medium uppercase">complete</Link>
                        </div>
                    </div>
    </div>
    <div className='col-span-2'><div className='text-secondary text-xs lg:text-lg  pr-8 font-bold lg:text-right max-w-[114px]  lg:max-w-[142px] ml-auto'>+ $2.80</div></div>
</div>
{/* item #4 */}



<div className='py-4 bg-gray-light lg:bg-transparent border-t-2 border-t-secondary border-opacity-25'>
    <div className='col-span-5 hidden lg:block'>
        <Link to="/transaction-history" className='text-sm font-bold text-secondary pl-14'>
        VIEW ALL TRANSACTIONS
        <svg xmlns="http://www.w3.org/2000/svg"   className='h-[10.85px] w-[10.98px] inline-block ml-3 -mt-px' viewBox="0 0 15.993 16.529">
        <path id="arrow-right" d="M7.786,34.717l-.707.739a.463.463,0,0,0,0,.633l5.538,5.79H.428A.438.438,0,0,0,0,42.327v1.045a.438.438,0,0,0,.428.448H12.617l-5.538,5.79a.463.463,0,0,0,0,.633l.707.739a.415.415,0,0,0,.606,0l7.476-7.816a.463.463,0,0,0,0-.633L8.392,34.717A.415.415,0,0,0,7.786,34.717Z" transform="translate(0 -34.586)" fill="#1c3557"/></svg>
        </Link>    
    </div>
</div>
{/* item #6 */}
          </div>
        </div>
      </div>
     
    </>
  )
}

export default AccountSummary