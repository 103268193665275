import React from 'react'
// import { Link } from "react-router-dom";
 import Visitorsthisyear from "../../../assets/images/Visitorsthisyear.png";


const Investments = () => {
  return (
    <>
    <div className="px-8 my-5 max-w-md mx-auto">
        <header className='text-left mb-5'>
            <h2 className='font-playfair text-secondary font-bold text-2xl mb-2'>investments</h2>
        </header>

      <div className='my-5'>
        <div className="font-bold text-xs text-secondary">AVAILABLE TO INVEST</div>
        <div className="text-primary font-bold text-[28px]">$934.32</div>
      </div>

      <div className='my-5'>
        <div className="font-bold text-xs text-secondary">AVAILABLE TO INVEST</div>
        <div className="text-primary font-bold text-[28px]">$934.32</div>
      </div>

      <div className='my-5'>
        <div className="font-bold text-xs text-secondary">AVAILABLE TO INVEST</div>
        <div className="text-primary font-bold text-[28px]">$934.32</div>
      </div>

      <div className="">
        <img src={Visitorsthisyear} alt="" />
      </div>
    </div>
    </>
  )
}

export default Investments