import React from 'react'
import { Link } from "react-router-dom";
import democheck from "../../assets/images/demo-check.png"


const AddaBankAccount = () => {
  return (
    <>
       <header className='container px-30px 2xl:px-0 lg:mt-12 mb-5 mt-5 flex'>
        <div className="text-left">
          <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-1">add bank account</h2>
          <p className="text-secondary text-xs font-bold lg:text-base">MEMBER: JANE DOE</p>
        </div>
     </header>

     <div className='container px-30px 2xl:px-0'>
     <div className="lg:grid lg:grid-cols-12 lg:gap-16">
          <div className="lg:col-span-6 pb-10">
          <form className="" action="#">
            
            <div className="mb-3">
            <div className="w-full">
                <input
                        type="text"
                        className="form-control"
                        placeholder="Bank Name"
                />
                </div>
            </div>

            <div className="mb-3">
            <div className="w-full">
                <input
                        type="text"
                        className="form-control"
                        placeholder="Routing Number (9 Digits)"
                />
                </div>
            </div>

            <div className="mb-3">
            <div className="w-full">
                <input
                        type="text"
                        className="form-control"
                        placeholder="Confirm Routing Number"
                />
                </div>
            </div>

            <div className="mb-3">
            <div className="w-full">
                <input
                        type="text"
                        className="form-control"
                        placeholder="Account Number"
                />
                </div>
            </div>

            <div className="mb-3">
            <div className="w-full">
                <input
                        type="text"
                        className="form-control"
                        placeholder="Confirm Account Number"
                />
                </div>
            </div>


            <div className="mb-5 mt-6">
                <span className='text-xs text-secondary block mb-2 lg:text-lg'>Please select the account type:</span>
                <div className='card mt-0'>
            <ul>
              <li className=''>
              <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                      <input type="radio" value="option1" name='selectAccountType' className='form-radio' />
                      <span className="ml-2 text-xs text-secondary lg:text-base">Checking</span>
                    </label>
              </li>
              <li className=''>
              <label className="inline-flex items-center w-full p-4 cursor-pointer">
                      <input type="radio" value="option2" className='form-radio' name='selectAccountType' />
                      <span className="ml-2 text-xs text-secondary lg:text-base">Saving</span>
                    </label>
              </li>
            </ul>
            </div>
            </div>
        <div className="text-center lg:text-left mt-7 lg:mt-10 mb-5"><Link to="#" className='btn uppercase'>Confirm</Link></div>
        </form>
          </div> {/* END */}

          <div className="lg:col-span-6 hidden lg:block">
              <h3 className='uppercase text-secondary mb-2 font-bold text-lg'>WHERE TO FIND YOUR INFORMATION:</h3>
              <img src={democheck} alt="" />



          </div>
    </div>
     </div> 
    </>
  )
}

export default AddaBankAccount