import React, { useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Input from "../../../components/Input";

const Confirmation = () => {
  const [disable, setDisable] = useState(true);

  const navigateurl  = useNavigate(); 

  const url = '/registration';

  function handleChange(event) {
    setDisable(event.target.value === "");
  }

  const submitHandler = (event) => {
    navigateurl(url);
  }
  

  return (
    <>
    <div className="h-full lg:h-auto lg:min-h-[calc(100vh_-_107px)] flex lg:justify-between lg:items-center">
        <div className="h-full flex flex-col justify-between items-center lg:h-auto w-full pt-12    lg:px-0 min-h-[calc(100vh_-_60px)] lg:min-h-full">
        <div className="container px-30px 2xl:px-0">
        <div className="text-center mb-5 lg:mb-12">
            <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-2">it’s official, welcome to start.</h2>
            <p className='text-cyan text-xs font-bold lg:text-lg'>Please read the following information carefully.</p>
             
        </div>
     
        <div className="lg:grid lg:grid-cols-12 lg:gap-16">
        
        <div className="lg:col-span-6">
        <h3 className='text-secondary text-xs lg:text-lg font-bold uppercase mb-2'>WHAT NEXT?</h3>
          <p className='mb-4 text-xs lg:text-lg text-secondary'>You will receive a confirmation email that includes
both your policy and health questionnaire documents. 
Please review these documents and store them for 
your records. Once you’ve created your Start account, 
these documents will also reside in your Account tab.</p>

          <h3 className='text-secondary text-xs lg:text-lg font-bold uppercase mb-2'>YOUR START ACCOUNT</h3>
          <p className='mb-4 text-xs  lg:text-lg text-secondary'>To access your benefits, manage your HSA, and search
for care, you will need to create your account. You will do so using your email after you have finished opening your Start policy. </p>

          <h3 className='text-secondary text-xs lg:text-lg font-bold uppercase mb-2'>ADDITIONAL POLICY MEMBERS</h3>
          <p className='mb-4 text-xs  lg:text-lg text-secondary'>Additional policy members (excluding dependents) are 
encouraged to create their own accounts to manage
claims history, account preferences, and their Start 
card transactions.</p>

          <h3 className='text-secondary text-xs  lg:text-lg font-bold uppercase mb-2'>YOUR START CARD</h3>
          <p className='mb-4 text-xs  lg:text-lg text-secondary'>
          Your Start card will be send to you by mail once you 
have created your account and provided us your 
detailed address. </p>
<p className='mb-4 text-xs  lg:text-lg text-secondary'>
<strong>NOTE: </strong> Until then, you may use your payment method 
of choice, save your receipts, and request 
reimbursement for any expenses after your chosen 
effective date. </p>

        </div>
        <div className="lg:col-span-6">
        {/* <form> </form> */}
        <div className="">
                <span className='text-xs lg:text-lg text-secondary block mb-2'>Please confirm your understanding.</span>
            <ul className='bg-gray-light border-t-6 rounded-md border-cyan'>
              <li className=''>
                <label className="inline-flex p-4 border-b-2 border-transparent lg:border-secondary lg:border-opacity-25 cursor-pointer-flex items-center w-full cursor-pointer">
                  <Input type="radio" value="option1" name='selectAccountType' className='form-radio text-secondary' onChange={handleChange}   />
                  <span className="ml-2 text-xs lg:text-lg text-secondary">I understand the information.</span>
                </label>
              </li>
            </ul>
            </div>
            <div className="text-center mt-7 lg:mt-0 lg:pt-5 pb-5 lg:pb-8 px-8 lg:bg-gray-light">
            {/* <Link to="/open-start-hsa" className='btn uppercase w-full lg:flex lg:justify-center items-center cursor-pointer bg-primary' >
              Continue
              <FontAwesomeIcon
                icon={faArrowRight}
                className="text-white w-3 ml-auto hidden lg:inline-block"
              />
            </Link> */}

            <button className='btn uppercase lg:w-full lg:flex lg:justify-center items-center cursor-pointer bg-primary disabled:opacity-75' disabled={disable} onClick={submitHandler}>
            Continue
              <FontAwesomeIcon
                icon={faArrowRight}
                className="text-white w-3 ml-auto hidden lg:inline-block"
              />
            </button>
            
            
            </div>
  <div className='text-center'>
    <Link to="#" className='text-xs font-bold text-secondary uppercase lg:hidden block'>Setup Later</Link></div>

       

        </div>
        

           
          
        </div>
       
        </div>
         
      

        
        </div>
      </div>
    </>
  )
}

export default Confirmation