import React from 'react'
import { Link } from "react-router-dom";
import SearchBar from '../../components/SearchBar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPrescription,
  faBookmark,
  faMagnifyingGlassDollar,
  faReceipt
} from '@fortawesome/free-solid-svg-icons'
import CardButton from '../../components/Button/CardButton';
import ArrowRightIcon from '../../components/Icons/ArrowRightIcon';

import iconFindCare from "../../assets/images/svg/magnifying-glass-dollar-solid.svg";
import iconPrescriptions from "../../assets/images/svg/prescription-solid.svg";
import iconViewFavorites from "../../assets/images/svg/bookmark-solid.svg";
import iconUploadReceipt from "../../assets/images/svg/receipt-solid.svg";

const FindDoctor = () => {
  return (
    <>
      <SearchBar />
      <div className="bg-white lg:bg-gray-light py-6 lg:py-12">
        <div className="container px-30px 2xl:px-0">
          <div className="grid grid-cols-12 gap-7 lg:gap-14">
              <div className="col-span-6 lg:col-span-3">
                <CardButton title="FIND Care" xicon={iconFindCare} url="/find-care" imageClassName="w-[27.66px] h-[32.06px] lg:w-[34.85px] lg:h-[40.39px] mx-auto lg:m-0"  />
              </div>
              <div className="col-span-6 lg:col-span-3">
                <CardButton title="PRESCRIPTIONS" xicon={iconPrescriptions} url="/prescriptions" imageClassName="w-[27.66px] h-[32.06px] lg:w-[34.85px] lg:h-[40.39px] mx-auto lg:m-0"  />
              </div>
              <div className="col-span-6 lg:col-span-3">
                <CardButton title="VIEW FAVORITES" xicon={iconViewFavorites} url="/favorites" imageClassName="w-[21.8px] h-[29.06px] lg:w-[27.52px] lg:h-[36.7px] mx-auto lg:m-0"  />
              </div>
              <div className="col-span-6 lg:col-span-3">
                <CardButton title="Expenses" xicon={iconUploadReceipt} url="/expenses" imageClassName="w-[21.8px] h-[29.06px] lg:w-[27.52px] lg:h-[36.7px] mx-auto lg:m-0"  />
              </div>
          </div>
          </div>
        </div>{/* END  */}


        <div className='mt-5 '>
   

    <div className='container px-30px 2xl:px-0 '>
    <div className='flex justify-start  5 py-3'>
    <h4 className='flex-grow text-xs lg:text-base  text-secondary font-bold uppercase'>PROVIDER SPECIALTIES</h4>
   </div>
    </div>

    <div className='bg-gray-light border-t-2 border-t-secondary border-opacity-25'>
        <div className='container px-30px 2xl:px-0'>
            <Link to="#" className='flex items-center py-3 lg:py-5'>
            <h4 className='flex-grow text-xs lg:text-base text-secondary'>Family Practice</h4>
            <ArrowRightIcon fill="#1c3557" cname="h-[10.98px] w-[10.98px] ml-auto" />
            </Link>
        </div>
    </div>  {/* END */}
    <div className='bg-gray-light border-t-2 border-t-secondary border-opacity-25'>
        <div className='container px-30px 2xl:px-0'>
            <Link to="#" className='flex items-center py-3 lg:py-5'>
            <h4 className='flex-grow text-xs lg:text-base text-secondary'>Pediatric </h4>
            <ArrowRightIcon fill="#1c3557" cname="h-[10.98px] w-[10.98px] ml-auto" />
            </Link>
        </div>
    </div>  {/* END */}
    <div className='bg-gray-light border-t-2 border-t-secondary border-opacity-25'>
        <div className='container px-30px 2xl:px-0'>
            <Link to="#" className='flex items-center py-3 lg:py-5'>
            <h4 className='flex-grow text-xs lg:text-base text-secondary'>Dermatology</h4>
            <ArrowRightIcon fill="#1c3557" cname="h-[10.98px] w-[10.98px] ml-auto" />
            </Link>
        </div>
    </div> {/* END */}

    <div className='bg-gray-light border-t-2 border-t-secondary border-opacity-25'>
        <div className='container px-30px 2xl:px-0'>
            <Link to="#" className='flex items-center py-3 lg:py-5'>
            <h4 className='flex-grow text-xs lg:text-base text-secondary'>Psychiatry</h4>
            <ArrowRightIcon fill="#1c3557" cname="h-[10.98px] w-[10.98px] ml-auto" />
            </Link>
        </div>
    </div> {/* END */}
    <div className='bg-gray-light border-t-2 border-t-secondary border-opacity-25'>
        <div className='container px-30px 2xl:px-0'>
            <Link to="#" className='flex items-center py-3 lg:py-5'>
            <h4 className='flex-grow text-xs lg:text-base text-secondary'>General Practice</h4>
            <ArrowRightIcon fill="#1c3557" cname="h-[10.98px] w-[10.98px] ml-auto" />
            </Link>
        </div>
    </div> {/* END */}
    <div className='bg-gray-light border-t-2 border-t-secondary border-opacity-25'>
        <div className='container px-30px 2xl:px-0'>
            <Link to="#" className='flex items-center py-3 lg:py-5'>
            <h4 className='flex-grow text-xs lg:text-base text-secondary'>General Surgery</h4>
            <ArrowRightIcon fill="#1c3557" cname="h-[10.98px] w-[10.98px] ml-auto" />
            </Link>
        </div>
    </div> {/* END */}
    <div className='bg-gray-light border-t-2 border-t-secondary border-opacity-25'>
        <div className='container px-30px 2xl:px-0'>
            <Link to="#" className='flex items-center py-3 lg:py-5'>
            <h4 className='flex-grow text-xs lg:text-base text-secondary'>Physical Medicine & Rehab</h4>
            <ArrowRightIcon fill="#1c3557" cname="h-[10.98px] w-[10.98px] ml-auto" />
            </Link>
        </div>
    </div> {/* END */}
    <div className='bg-gray-light border-t-2 border-t-secondary border-opacity-25'>
        <div className='container px-30px 2xl:px-0'>
            <Link to="#" className='flex items-center py-3 lg:py-5'>
            <h4 className='flex-grow text-xs lg:text-base text-secondary'>Cardiology</h4>
            <ArrowRightIcon fill="#1c3557" cname="h-[10.98px] w-[10.98px] ml-auto" />
            </Link>
        </div>
    </div> {/* END */}
    <div className='bg-gray-light border-t-2 border-t-secondary border-opacity-25'>
        <div className='container px-30px 2xl:px-0'>
            <Link to="#" className='flex items-center py-3 lg:py-5'>
            <h4 className='flex-grow text-xs lg:text-base text-secondary'>Chiropractic</h4>
            <ArrowRightIcon fill="#1c3557" cname="h-[10.98px] w-[10.98px] ml-auto" />
            </Link>
        </div>
    </div> {/* END */}
    <div className='bg-gray-light border-t-2 border-t-secondary border-opacity-25'>
        <div className='container px-30px 2xl:px-0'>
            <Link to="#" className='flex items-center py-3 lg:py-5'>
            <h4 className='flex-grow text-xs lg:text-base text-secondary'>Otolaryngology</h4>
            <ArrowRightIcon fill="#1c3557" cname="h-[10.98px] w-[10.98px] ml-auto" />
            </Link>
        </div>
    </div> {/* END */}
    <div className='bg-gray-light border-t-2 border-t-secondary border-opacity-25'>
        <div className='container px-30px 2xl:px-0'>
            <Link to="#" className='flex items-center py-3 lg:py-5'>
            <h4 className='flex-grow text-xs lg:text-base text-secondary'>Critical Care</h4>
            <ArrowRightIcon fill="#1c3557" cname="h-[10.98px] w-[10.98px] ml-auto" />
            </Link>
        </div>
    </div> {/* END */}
    <div className='bg-gray-light border-t-2 border-t-secondary border-opacity-25'>
        <div className='container px-30px 2xl:px-0'>
            <Link to="#" className='flex items-center py-3 lg:py-5'>
            <h4 className='flex-grow text-xs lg:text-base text-secondary'>Pathology</h4>
            <ArrowRightIcon fill="#1c3557" cname="h-[10.98px] w-[10.98px] ml-auto" />
            </Link>
        </div>
    </div> {/* END */}
    <div className='bg-gray-light border-t-2 border-t-secondary border-opacity-25'>
        <div className='container px-30px 2xl:px-0'>
            <Link to="#" className='flex items-center py-3 lg:py-5'>
            <h4 className='flex-grow text-xs lg:text-base text-secondary'>Emergency Medicine</h4>
            <ArrowRightIcon fill="#1c3557" cname="h-[10.98px] w-[10.98px] ml-auto" />
            </Link>
        </div>
    </div> {/* END */}
</div>
    </>
  )
}

export default FindDoctor