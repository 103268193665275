import React, {useState} from "react";
import { Link } from "react-router-dom";
import { Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'


const InvestorProfile = () => {

  const gender = [
    { id: "0", name: 'Select', value: "statusg" },
    { id: "1", name: 'Male', value: "pending" },
    { id: "2", name: 'Female', value: "female" },
  ]
  const [selectedGender, setSelectedGender] = useState(gender[0])

  const citizenship = [
    { id: "0", name: 'Citizenship', value: "statusg" },
    { id: "1", name: 'One', value: "one" },
    { id: "2", name: 'Two', value: "two" },
    { id: "3", name: 'Three', value: "three" },
  ]
  const [selectedCitizenship, setSelectedCitizenship] = useState(citizenship[0])

  const EmploymentStatus = [
    { id: "0", name: 'Employment Status', value: "employmentstatus" },
    { id: "1", name: 'One', value: "one" },
    { id: "2", name: 'Two', value: "two" },
  ]
  const [selectedEmploymentStatus, setSelectedEmploymentStatus] = useState(EmploymentStatus[0])

  const InvestmentExperience = [
    { id: "0", name: 'Investment Experience', value: "employmentstatus" },
    { id: "1", name: 'One', value: "one" },
    { id: "2", name: 'Two', value: "two" },
  ]
  const [selectedInvestmentExperience, setSelectedInvestmentExperience] = useState(InvestmentExperience[0])

  const RiskTolerance = [
    { id: "0", name: 'Risk Tolerance', value: "employmentstatus" },
    { id: "1", name: 'One', value: "one" },
    { id: "2", name: 'Two', value: "two" },
  ]
  const [selectedRiskTolerance, setSelectedRiskTolerance] = useState(RiskTolerance[0])

  return (
    <>
<div className="max-w-md w-full m-auto px-8 mt-7 pb-8">
        <header className="mb-5">
            <h2 className="font-playfair text-secondary font-bold text-2xl mb-2">
            investor profile
            </h2>
            <p className='text-secondary text-xs'>Please provide the following information to complete 
your profile:
</p>
        </header>
        <form className="" action="#">
            <div className="flex mb-3 space-x-3">
                <div className="w-1/2">
                  <input
                          className="form-control"
                          placeholder="First Name"
                          type="text"
                          id="fname"
                  />
                </div>
                <div className="w-1/2">
                <input
                        className="form-control"
                        placeholder="Last Name"
                        type="text"
                        id="lname"
                />
                </div>
            </div>



            <div className="mb-3">
            <div className="w-full">
                <input
                        type="email"
                        className="form-control"
                        placeholder="janedoe@gmail.com"
                />
                </div>
            </div>

            <div className="flex mb-3 space-x-3">
                <div className="w-1/2">
                    <input
                        className="form-control"
                        placeholder="01/01/1980"
                        type="text"
                        id="date"
                    />
                </div>
                <div className="w-1/2">
                <Listbox value={selectedGender} onChange={setSelectedGender}>
            <div className="relative">
              <Listbox.Button className="relative w-full cursor-pointer rounded-[5px] border border-opacity-25 bg-white py-3.5 pl-3 pr-10 text-left text-xs 
              border-secondary 
              ocus:outline-none
              font-bold
              text-secondary
              ">
                <span className="block truncate">{selectedGender.name}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDownIcon
                    className="h-5 w-5 text-secondary"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute  max-h-60 w-full overflow-auto bg-gray-light text-xs focus:outline-none border-b-6 border-cyan rounded-md">
                  {gender.map((gender, genderIdx) => (
                    <Listbox.Option
                      key={genderIdx}
                      className={({ active }) =>
                        `relative cursor-pointer select-none py-3 px-3 border-b-2 border-b-secondary border-opacity-25 ${
                          active
                            ? "bg-transparent text-secondary"
                            : "text-secondary"
                        }`
                      }
                      value={gender}
                    >
                      {({ selectedGender }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selectedGender ? "font-medium" : "font-normal"
                            }`}
                          >
                            {gender.name}
                          </span>
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>

                </div>
            </div>

            <div className="mb-3">
                <div className="w-full">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="1111 Dock St."
                    />
                </div>
            </div>



            <div className="flex mb-3 space-x-3">
                <div className="w-1/2">
                  <input
                          className="form-control"
                          placeholder="Line 2 (Optional)"
                          type="text"
                          id="pobox"
                  />
                </div>
                <div className="w-1/2">
                <input
                        className="form-control"
                        placeholder="South jordan"
                        type="text"
                        id="zip"
                />
                </div>
            </div>

            <div className="flex mb-3 space-x-3">
                <div className="w-1/2">
                    <select  className="form-control"
                    >
                        <option>State</option>
                        <option>One</option>
                        <option>Two</option>
                        <option>Three</option>
                    </select>
                </div>
                <div className="w-1/2">
                    <input
                        className="form-control"
                        placeholder="84009"
                        type="text"
                        id="fname"
                    />
                </div>

            </div>

            <div className="mb-3">
            <div className="w-full">
                <input
                        type="text"
                        className="form-control bg-cyan/25"
                        placeholder="Mobile Phone"
                />
                </div>
            </div>

            <div className="mb-3">
            <div className="w-full">
            <Listbox value={selectedCitizenship} onChange={setSelectedCitizenship}>
            <div className="relative z-40">
              <Listbox.Button className="relative w-full cursor-pointer rounded-[5px] border border-opacity-25 bg-cyan bg-opacity-25 py-3.5 pl-3 pr-10 text-left text-xs 
              border-secondary 
              ocus:outline-none
              font-bold
              text-secondary
              ">
                <span className="block truncate">{selectedCitizenship.name}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDownIcon
                    className="h-5 w-5 text-secondary"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute  max-h-60 w-full overflow-auto bg-gray-light text-xs focus:outline-none border-b-6 border-cyan rounded-md">
                  {citizenship.map((citizenship, citizenshipIdx) => (
                    <Listbox.Option
                      key={citizenshipIdx}
                      className={({ active }) =>
                        `relative cursor-pointer select-none py-3 px-3 border-b-2 border-b-secondary border-opacity-25 ${
                          active
                            ? "bg-transparent text-secondary"
                            : "text-secondary"
                        }`
                      }
                      value={citizenship}
                    >
                      {({ selectedCitizenship }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selectedCitizenship ? "font-medium" : "font-normal"
                            }`}
                          >
                            {citizenship.name}
                          </span>
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
                </div>
            </div>

             <div className="mb-3">
            <div className="w-full">
            <Listbox value={selectedEmploymentStatus} onChange={setSelectedEmploymentStatus}>
            <div className="relative z-30">
              <Listbox.Button className="relative w-full cursor-pointer rounded-[5px] border border-opacity-25 bg-cyan bg-opacity-25 py-3.5 pl-3 pr-10 text-left text-xs 
              border-secondary 
              ocus:outline-none
              font-bold
              text-secondary
              ">
                <span className="block truncate">{selectedEmploymentStatus.name}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDownIcon
                    className="h-5 w-5 text-secondary"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute  max-h-60 w-full overflow-auto bg-gray-light text-xs focus:outline-none border-b-6 border-cyan rounded-md">
                  {EmploymentStatus.map((EmploymentStatus, EmploymentStatusIdx) => (
                    <Listbox.Option
                      key={EmploymentStatusIdx}
                      className={({ active }) =>
                        `relative cursor-pointer select-none py-3 px-3 border-b-2 border-b-secondary border-opacity-25 ${
                          active
                            ? "bg-transparent text-secondary"
                            : "text-secondary"
                        }`
                      }
                      value={EmploymentStatus}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {EmploymentStatus.name}
                          </span>
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
                </div>
            </div>

            <div className="mb-3">
            <div className="w-full">
                <input
                        type="text"
                        className="form-control bg-cyan/25"
                        placeholder="Annual Income"
                />
                </div>
            </div>

            <div className="mb-3">
            <div className="w-full">
            <Listbox value={selectedInvestmentExperience} onChange={setSelectedInvestmentExperience}>
            <div className="relative z-20">
              <Listbox.Button className="relative w-full cursor-pointer rounded-[5px] border border-opacity-25 bg-cyan bg-opacity-25  py-3.5 pl-3 pr-10 text-left text-xs 
              border-secondary 
              ocus:outline-none
              font-bold
              text-secondary
              ">
                <span className="block truncate">{selectedInvestmentExperience.name}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDownIcon
                    className="h-5 w-5 text-secondary"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute  max-h-60 w-full overflow-auto bg-gray-light text-xs focus:outline-none border-b-6 border-cyan rounded-md">
                  {InvestmentExperience.map((InvestmentExperience, InvestmentExperienceIdx) => (
                    <Listbox.Option
                      key={InvestmentExperienceIdx}
                      className={({ active }) =>
                        `relative cursor-pointer select-none py-3 px-3 border-b-2 border-b-secondary border-opacity-25 ${
                          active
                            ? "bg-transparent text-secondary"
                            : "text-secondary"
                        }`
                      }
                      value={InvestmentExperience}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {InvestmentExperience.name}
                          </span>
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
                </div>
            </div>  

            <div className="mb-3">
            <div className="w-full">
            <Listbox value={selectedRiskTolerance} onChange={setSelectedRiskTolerance}>
            <div className="relative z-10">
              <Listbox.Button className="relative w-full cursor-pointer rounded-[5px] border border-opacity-25 bg-cyan bg-opacity-25 py-3.5 pl-3 pr-10 text-left text-xs 
              border-secondary 
              ocus:outline-none
              font-bold
              text-secondary
              ">
                <span className="block truncate">{selectedRiskTolerance.name}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDownIcon
                    className="h-5 w-5 text-secondary"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute  max-h-60 w-full overflow-auto bg-gray-light text-xs focus:outline-none border-b-6 border-cyan rounded-md">
                  {RiskTolerance.map((RiskTolerance, RiskToleranceienceIdx) => (
                    <Listbox.Option
                      key={RiskToleranceienceIdx}
                      className={({ active }) =>
                        `relative cursor-pointer select-none py-3 px-3 border-b-2 border-b-secondary border-opacity-25 ${
                          active
                            ? "bg-transparent text-secondary"
                            : "text-secondary"
                        }`
                      }
                      value={RiskTolerance}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {RiskTolerance.name}
                          </span>
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
                </div>
            </div> 

            

            <div className="mb-5 mt-7">
              <span className="text-xs text-secondary block mb-5">
              Is the account maintained for a current or former 
Politically Exposed Person or Public Official (includes 
U.S. and Foreign)? A politically exposed person is 
someone who has been entrusted with a prominent 
public function, or who is closely related to such a 
person. 
              </span>
              <ul className="bg-gray-light border-t-[6px] rounded-md border-cyan">
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                    <input type="radio" className="form-radio" />
                    <span className="ml-2 text-xs text-secondary">
                      Yes
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center w-full p-4 cursor-pointer">
                    <input type="radio" className="form-radio" />
                    <span className="ml-2 text-xs text-secondary">
                      No
                    </span>
                  </label>
                </li>
              </ul>
            </div>

            <div className="mb-5 mt-7">
              <span className="text-xs text-secondary block mb-5">
              Is the account holder(s) a control person of a publicly 
traded company? A Director, Officer or 10% stock 
owner? 
              </span>
              <ul className="bg-gray-light border-t-[6px] rounded-md border-cyan">
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                    <input type="radio" className="form-radio" />
                    <span className="ml-2 text-xs text-secondary">
                      Yes
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center w-full p-4  cursor-pointer">
                    <input type="radio" className="form-radio" />
                    <span className="ml-2 text-xs text-secondary">
                      No
                    </span>
                  </label>
                </li>
              </ul>
            </div>


            <div className="mb-5 mt-7">
              <span className="text-xs text-secondary block mb-5">
              Are you affiliated with or employed by a stock 
exchange, member firm of an exchange or FINRA, or a 
municipal securities broker-dealer? 
              </span>
              <ul className="bg-gray-light border-t-[6px] rounded-md border-cyan">
                <li className="">
                  <label className="inline-flex items-center w-full p-4 border-b-2 border-secondary border-opacity-25 cursor-pointer">
                    <input type="radio" className="form-radio" />
                    <span className="ml-2 text-xs text-secondary">
                      Yes
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center w-full p-4  cursor-pointer">
                    <input type="radio" className="form-radio" />
                    <span className="ml-2 text-xs text-secondary">
                      No
                    </span>
                  </label>
                </li>
              </ul>
            </div>
          
            <div className="mb-5 mt-7">
             
              <ul className="bg-gray-light border-t-[6px] rounded-md border-cyan">
                <li className="">
                  <label className="inline-flex items-center w-full p-4   border-b-2 border-secondary border-opacity-25 cursor-pointer">
                    <input type="checkbox" className="form-checkbox" />
                    <span className="ml-2 text-xs text-secondary">
                    I have ready the <Link to="#" className="text-cyan font-bold">Privacy Policy Disclosure</Link>.
                    </span>
                  </label>
                </li>
                <li className="">
                  <label className="inline-flex items-center w-full p-4   cursor-pointer">
                    <input type="checkbox" className="form-checkbox" />
                    <span className="ml-2 text-xs text-secondary">
                    I am a U.S. taxpayer. 
                    </span>
                  </label>
                </li>
              </ul>
            </div>

            <div className="mb-3">
            <div className="w-full">
                <input
                        type="text"
                        className="form-control bg-cyan/25"
                        placeholder="Signature"
                />
                </div>
            </div>

            <div className="mb-5 mt-7">
             <ul className="bg-gray-light border-t-6 rounded-md border-cyan">
               <li className="">
                 <label className="inline-flex items-center w-full p-4 last:border-b-0  border-b-2 border-secondary border-opacity-25 cursor-pointer">
                   <input type="checkbox" className="form-checkbox" />
                   <span className="ml-2 text-xs text-secondary">
                   I consent to signing electronically.
                   </span>
                 </label>
               </li>
             </ul>
           </div>

          <div className="text-center mt-7 mb-5"><Link to="#" className='btn uppercase'>CONTINUE</Link></div>
          <div className='text-center'><Link to="#" className='text-xs font-bold text-secondary uppercase'>CANCEL</Link></div>
            
        </form>
        </div>
    </>
  )
}

export default InvestorProfile