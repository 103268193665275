import React from 'react'
import { Link } from "react-router-dom";
import SearchBar from '../../components/SearchBar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBookmark,
} from '@fortawesome/free-solid-svg-icons'

//import arrowright from '../../assets/images/arrow-right.svg'

import { useJsApiLoader } from "@react-google-maps/api"
import Map from "../../Map"
import ArrowRightIcon from '../../components/Icons/ArrowRightIcon';

//import CardButton from '../../components/Button/CardButton';
import logoicon from "../../assets/images/logo-icon.svg";
import ActionCardButton from '../../components/Button/ActionCardButton';

import iconRecipt from "../../assets/images/svg/bookmark-solid.svg";

const CategoryDetails = () => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCpF6GJcN6LJoYUsRZr_JG5QaIeq4UowWo", 
    id: 'google-map-script'
  });
  return (
    <>
     <SearchBar />
        <header className='container px-30px 2xl:px-0 lg:mt-12 mb-5 lg:mb-12 mt-5 flex'>
            <div className='text-left'>
            <h2 className='font-playfair text-secondary font-bold text-2xl lg:text-38px mb-3'>dermatology providers</h2>
            <p className='text-secondary text-xs font-bold lg:text-base'>LOCATION: SALT LAKE VALLEY</p>
            </div>
        </header>


<div className='container px-0 lg:px-30px 2xl:px-0'>
  <div className='lg:grid lg:grid-cols-12 lg:gap-16'>
  <div className='lg:col-span-6 pb-10'>

<div className='px-30px lg:px-0'>
<ActionCardButton title="" url="/favorites" linktitle="VIEW FAVORITES" icon={true} iconimage={iconRecipt} iconcname="w-[13.76px] h-[18.35px]" />
</div>

<div className='lg:hidden block mb-8 lg:mb-14 '>
<h1 className='px-8 text-secondary uppercase font-bold text-xs'>LOCATIONS Near You</h1>
<div className='mt-2  w-full  flex justify-center items-center'> { isLoaded && <Map /> } </div>
</div>
             

  <div className="flex px-8 lg:pl-12 lg:pr-7 py-2">
              <div className="w-4/5 text-xs font-bold uppercase lg:text-base text-secondary">
                PROVIDERS
              </div>
              {/* <div className="w-1/5 text-xs lg:text-lg lg:text-right text-secondary">
                Estimate
              </div> */}
            </div>
            {/* END */}

            <div className="bg-gray-light lg:bg-transparent border-t-2 border-secondary border-opacity-25 pl-8 pr-5 lg:pl-12 lg:pr-7 py-3">
              <div className="w-full flex mb-4 lg:mb-9 text-secondary">
                <div className="w-4/5 text-xs">
                  <div className="flex relative lg:text-base">
                    <div className="absolute -left-5 lg:-left-7 top-0">
                      <FontAwesomeIcon
                        icon={faBookmark}
                        className="text-secondary text-sm"
                      />
                    </div>
                    Dr. Smith
                    <img src={logoicon} alt="" className="w-3 ml-2" />
                  </div>
                  <small className="text-6px lg:text-10px uppercase text-secondary font-bold">
                  Draper, UT
                  </small>
                </div>
                 
              </div>

              <Link to="#" className="flex">
                <div className="text-secondary text-10px lg:text-sm font-bold uppercase">
                  VIEW DETAILS
                </div>
                <div className="ml-auto">
                  <ArrowRightIcon
                    fill="#1c3557"
                    cname="h-[11px] w-[12px] lg:h-[16.53px] lg:w-[15.99px]"
                  />
                </div>
              </Link>
            </div>
            {/* END */}

            <div className="bg-gray-light lg:bg-transparent border-t-2 border-secondary border-opacity-25 pl-8 pr-5 lg:pl-12 lg:pr-7 py-3">
              <div className="w-full flex mb-4 lg:mb-9 text-secondary">
                <div className="w-4/5 text-xs">
                  <div className="flex relative lg:text-base">
                  Dr. Jacob
                    <img src={logoicon} alt="" className="w-3 ml-2" />
                  </div>
                  <small className="text-6px lg:text-10px uppercase text-secondary font-bold">
                  Sandy, UT
                  </small>
                </div>
                
              </div>

              <Link to="#" className="flex">
                <div className="text-secondary text-10px lg:text-sm font-bold uppercase">
                  VIEW DETAILS
                </div>
                <div className="ml-auto">
                  <ArrowRightIcon
                    fill="#1c3557"
                    cname="h-[11px] w-[12px] lg:h-[16.53px] lg:w-[15.99px]"
                  />
                </div>
              </Link>
            </div>
            {/* END */}

            <div className="bg-gray-light lg:bg-transparent border-t-2 border-secondary border-opacity-25 pl-8 pr-5 lg:pl-12 lg:pr-7 py-3">
              <div className="w-full flex mb-4 lg:mb-9 text-secondary">
                <div className="w-4/5 text-xs">
                  <div className="flex relative lg:text-base">
                  U of U Dermatology
                    <img src={logoicon} alt="" className="w-3 ml-2" />
                  </div>
                  <small className="text-6px lg:text-10px uppercase text-secondary font-bold">
                    3 LOCATIONS NEAR YOU
                  </small>
                </div>
                 
              </div>

              <Link to="#" className="flex">
                <div className="text-secondary text-10px lg:text-sm font-bold uppercase">
                  VIEW DETAILS
                </div>
                <div className="ml-auto">
                  <ArrowRightIcon
                    fill="#1c3557"
                    cname="h-[11px] w-[12px] lg:h-[16.53px] lg:w-[15.99px]"
                  />
                </div>
              </Link>
            </div>
            {/* END */}

            <div className="bg-gray-light lg:bg-transparent border-t-2 border-secondary border-opacity-25 pl-8 pr-5 lg:pl-12 lg:pr-7 py-3">
              <div className="w-full flex mb-4 lg:mb-9 text-secondary">
                <div className="w-4/5 text-xs">
                  <div className="flex relative lg:text-base">
                  Dr. Jones
                    <img src={logoicon} alt="" className="w-3 ml-2" />
                  </div>
                  <small className="text-6px lg:text-10px uppercase text-secondary font-bold">
                  Salt Lake City, ut
                  </small>
                </div>
                 
              </div>

              <Link to="#" className="flex">
                <div className="text-secondary text-10px lg:text-sm font-bold uppercase">
                  VIEW DETAILS
                </div>
                <div className="ml-auto">
                  <ArrowRightIcon
                    fill="#1c3557"
                    cname="h-[11px] w-[12px] lg:h-[16.53px] lg:w-[15.99px] "
                  />
                </div>
              </Link>
            </div>
            {/* END */}
  </div>{/* END */}
  <div className='lg:col-span-6 hidden lg:block'>
             <h1 className='px-8 pl-0 text-secondary uppercase font-bold text-base'>LOCATIONS NEAR YOU</h1>
            <div className='mt-2  w-full  flex justify-center items-center'> { isLoaded && <Map /> } </div>
      </div>
  </div>
</div>


       
    </>
  )
}

export default CategoryDetails