import React from 'react'
import { Link } from "react-router-dom";

const InvestmentsVerificationStatus1 = () => {
  return (
    <>
    <div className="max-w-md w-full m-auto px-8  mt-7">
              <header className="mb-5">
              <h2 className="font-playfair text-secondary font-bold text-2xl mb-2">
              start investing
              </h2>
              <p className='text-secondary text-xs mb-2 uppercase font-bold'>FEES TO INVEST</p>
              <p className='text-secondary text-xs'>Fees to invest depend on the investment path you 
choose before. The fees are deducted on a monthly basis 
from the cash portion of your HSA. Investments are not 
liquidated to cover the stated monthly investments fees. 
In the event the cash portion of your HSA does not have 
a sufficient balance to pay your investment services fee, 
a debit balance will appear on your HSA cash until 
satisfied by a deposit or you initiating a liquidation of
your investment positions.</p>
              </header>

              <div className="text-center mt-7 mb-5"><Link to="#" className='btn uppercase'>CONFIRM</Link></div>
            </div>
    </>
  )
}

export default InvestmentsVerificationStatus1