import React from 'react'
import { useCallback } from 'react'
import {useDropzone} from 'react-dropzone'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faReceipt
} from '@fortawesome/free-solid-svg-icons'

const Dropzone = () => {

    const onDrop = useCallback((acceptedFiles) => {
        console.log(acceptedFiles);
    }, []);

    const {getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'image/png': ['.png'],
            'image/jpg': ['.jpg'],
            'image/jpeg': ['.jpeg']
        },
    });

    return (
        <>
            <div className='mt-5'>
                <div {...getRootProps()} className="w-full h-80 rounded-md cursor-pointer focus:outline-none">
                <input {...getInputProps()} />
                <div className={
                    "flex flex-col items-center justify-center h-full space-y-3 border-2 border-dashed border-secondary rounded-xl bg-white " +
                    (isDragReject === true ? "border-red-500" : "") +
                    (isDragAccept === true ? "border-green-500" : "") 
                }
                >
                    <FontAwesomeIcon icon={faReceipt} className="text-secondary text-5xl" />
                    {isDragReject ? (<p>Sorry, This app only supports images</p>) : (
                        <p className='text-secondary text-lg'>Drag file here or <span className='text-cyan font-bold'>upload file.</span> </p>
                    )
                    }
                    
                </div>
                </div>
            </div>
        </>
    )
}

export default Dropzone