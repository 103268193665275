import React, { useState, Fragment } from 'react'
import { Link } from "react-router-dom";
import ActionCardButton from '../../components/Button/ActionCardButton';
//import ArrowRightIcon from '../../components/Icons/ArrowRightIcon';
import CustomSelect from '../../components/Select';

import ErrorModal from '../../components/UI/ErrorModal';

const Contributions = () => {

  const [isOpen, setIsOpen] = useState(false);

  function openModal(event, modalData){
    setIsOpen({
      title: modalData.title,
      message: modalData.message,
      buttonlink: modalData.buttonlink,
      buttontext: modalData.buttontext
    });
    return;
  }

  const popupHandler = () => {
    setIsOpen(null);
  };

  const modalObj = {
    title: '',
    message: '<div className="mb-4 pl-6 pr-10 lg:text-sm"><p>By clicking Submit, you authorize the transfer of the amount shown above into your HSA. You affirm that you understand the eligibility requirements of making a contribution to   your HSA and that you qualify to make the contribution.</p><p>You assume complete responsibility for:</p><p>1. Determining that you are eligible to contribute to an HSA each year you contribute.<br />2. Ensuring you do not exceed the annual contribution limits set by the IRS.<br />3. The tax consequences of any contribution (including rollover contributions) and distributions.</p></div>',
    buttonlink: '/account-summary',
    buttontext: 'Confirm'
  }


  return (
    <>
      <header className='container px-30px 2xl:px-0 lg:mt-12 mb-5 mt-5 flex'>
        <div className="text-left">
          <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-1">contribute</h2>
          <p className="text-secondary text-xs font-bold lg:text-base">MEMBER: JANE DOE</p>
          <p className='text-secondary text-xs lg:text-lg pt-3'>Please allow 3 - 5 business days for contributed funds to become available.</p>
        </div>
     </header>

     <div className='container px-30px 2xl:px-0'>
     <div className="lg:grid lg:grid-cols-12 lg:gap-16">
          <div className="lg:col-span-6 pb-10 mt-4">
          <form className="" action="#">
          <h4 className="mb-3 text-secondary font-bold text-xs lg:text-lg">ONE-TIME CONTRIBUTION</h4>
          <div className="mb-3">
              <label className="block">
                  <CustomSelect phtext="Select Tax Year" />
              </label>
          </div>

          <div className="mb-3">
          <label className="block">
                <CustomSelect phtext="Select Bank Account" />
              </label>
          </div>

          <div className="mb-3">
          <label className="block">
          <input
                        type="text"
                        className="form-control"
                        placeholder="Amount"
                />
              </label>
          </div>

          <div className='text-sm text-secondary mt-8 hidden lg:block'>
            <p className='mb-5'>By clicking Submit, you authorize the transfer of the amount shown above into your HSA. You affirm that you understand the eligibility requirements of making a contribution to your HSA and that you qualify to make the 
contribution.
</p>

<p className='mb-5'>You assume complete responsibility for:
</p>

<ol className='list-decimal pl-4'>
<li>Determining that you are eligible to contribute to an HSA each year you contribute.</li>
<li>Ensuring you do not exceed the annual contribution limits set by the IRS.</li>
<li>The tax consequences of any contribution (including rollover contributions) and distributions.</li>
</ol>

          </div>

          <div className="text-center lg:text-left mt-7 mb-5"><Link to="#" className='btn uppercase' onClick={(e) => {openModal(e, modalObj) }}>Submit</Link></div>

          {isOpen && (
                <ErrorModal
                  title={isOpen.title}
                  message={isOpen.message}
                  buttonlink={isOpen.buttonlink}
                  buttontext={isOpen.buttontext}
                  onConfirm={popupHandler}
                />
              )}
      </form>

      
          </div> {/* END */}

          <div className="lg:col-span-6">


          <ActionCardButton title="add bank account" url="/add-bankaccount" linktitle="ADD ACCOUNT" />
          <ActionCardButton title="transfer existing HSA funds" url="#" linktitle="CALL START HEALTH" hidden="mobile"  />
          
          </div>
    </div>
     </div>
      

    </>
  )
}

export default Contributions


