import React from 'react'
import { Link } from "react-router-dom";
import Input from '../../../components/Input';

const Wheredoyoulive = ({ onNext, onSkip }) => {
  return (
    <>
        <div className="h-full lg:h-auto lg:min-h-[calc(100vh_-_107px)] flex lg:justify-between lg:items-center">
        <div className="h-full flex flex-col justify-between items-center lg:h-auto w-full pt-12 lg:py-0 px-8 lg:px-0 min-h-[calc(100vh_-_60px)] lg:min-h-full">
        <div className="max-w-md mx-auto w-full">
        <div className="text-center mb-5 lg:mb-7">
            <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-2">let’s get started.</h2>
        </div>
 
        <div className="mb-3">
            <span className="text-gray-700 text-xs lg:text-lg mb-2 block">Where do you currently live?</span>
                <Input
                        type="text"
                        className="form-control"
                        placeholder="Zip Code"
                />
            </div>
          <div className="text-center mt-7 mb-5">
            
          {/* <button onClick={onNext} className='btn uppercase'>Continue</button> */}
            <Link to="/add-initial-member" className='btn uppercase'>Continue</Link>
            </div>
 
        </div>
       
      

        
        </div>
      </div>
    </>
  )
}

export default Wheredoyoulive