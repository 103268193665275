import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faMagnifyingGlass,
    faCircleXmark,
    faArrowRight
} from '@fortawesome/free-solid-svg-icons'

const SearchBar = (props) => {

    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <div className={`search-bar ${isOpen ? "open" : ""}`}>
                <div className="container px-30px 2xl:px-0 py-8">
                    <div className="search-box">
                        <input className="form-control border-0" id="search" type="text" placeholder={props.phtext ? props.phtext : 'Search..'} onChange={() => setIsOpen(true)} />
                        <div className="pr-2">
                            {isOpen ? (
                                <span className='close-icon' onClick={() => setIsOpen(false)}><FontAwesomeIcon icon={faCircleXmark} className="text-secondary mr-2" /></span>
                            ) :  (
                                <span className='magnifying-icon'><FontAwesomeIcon icon={faMagnifyingGlass} className="text-secondary mr-2" /></span>
                            )}
                        </div>
                    </div> 
                </div>

                {props.page == 'Prescription' ? (

                    <ul className="search-items">
                    <li>
                        <Link to="/prescriptions/medication-name" className='search-item'>
                            <span>Medication Name</span>
                            <FontAwesomeIcon icon={faArrowRight} className="text-black w-2.5" />
                        </Link>
                    </li>
                    <li>
                        <Link to="#/prescriptions/medication-name2" className='search-item'>
                            <span>Medication Name 2</span>
                            <FontAwesomeIcon icon={faArrowRight} className="text-black w-2.5" />
                        </Link>
                    </li>
                    <li>
                        <Link to="/prescriptions/medication-name3" className='search-item'>
                            <span>Medication Name 3</span>
                            <FontAwesomeIcon icon={faArrowRight} className="text-black w-2.5" />
                        </Link>
                    </li>
                    <li>
                        <Link to="/prescriptions/medication-name4" className='search-item'>
                            <span>Medication Name 4</span>
                            <FontAwesomeIcon icon={faArrowRight} className="text-black w-2.5" />
                        </Link>
                    </li>
                    <li>
                <Link to="#" className='search-item'>
                <span className='font-bold'>Can’t find what you’re looking for?</span>
                    <FontAwesomeIcon icon={faArrowRight} className="text-black w-2.5"  />
                </Link>
            </li> 
                    </ul>
                ) : (
                    <ul className="search-items">
                    <li>
                        <Link to="#" className='search-item'>
                            <span>MRI (Contrast)</span>
                            <FontAwesomeIcon icon={faArrowRight} className="text-black w-2.5" />
                        </Link>
                    </li>

                    <li>
                        <Link to="#" className='search-item'>
                            <span>MRI (No Contrast)</span>
                            <FontAwesomeIcon icon={faArrowRight} className="text-black w-2.5" />
                        </Link>
                    </li>

                    <li>
                        <Link to="#" className='search-item'>
                            <span>MRI (No Contrast)</span>
                            <FontAwesomeIcon icon={faArrowRight} className="text-black w-2.5" />
                        </Link>
                    </li>

                    <li>
                        <Link to="#" className='search-item'>
                            <span>MRI (No Contrast)</span>
                            <FontAwesomeIcon icon={faArrowRight} className="text-black w-2.5" />
                        </Link>
                    </li>

                    <li>
                <Link to="#" className='search-item'>
                <span className='font-bold'>Can’t find what you’re looking for?</span>
                    <FontAwesomeIcon icon={faArrowRight} className="text-black w-2.5"  />
                </Link>
            </li> 
                    </ul>
                )}
                
            </div>
        </>
    )
}

export default SearchBar