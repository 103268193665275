import React from 'react'
import PropTypes from 'prop-types'

const Input = ({handleChange, ...otherProps}) => {
  return (
    <>
        <input
          onChange={handleChange} 
          {...otherProps}
        />
    </>
  )
}

export default Input