import React from 'react'

const ManagedPath = () => {
  return (
    <>
      <div className="max-w-md w-full m-auto px-8 mt-7 pb-8">
        <header className="mb-5">
            <h2 className="font-playfair text-secondary font-bold text-2xl mb-2">
            investments dashboard
            </h2>
            <p className='text-secondary text-xs font-bold'>MANAGED PATH
</p>
        </header>


        <div className='card'>
        <h4 className='card-title pb-0'>summary</h4>
        <ol className=''>
                 <li className='inline-flex items-center w-full px-4 py-3 last:border-b-0 border-b-2 border-secondary border-opacity-25 text-xs text-secondary'>
                <div className='w-3/4'>Available to Invest</div>
                <div className='w-1/4 font-bold text-10px'>$429</div>
              </li>

              <li className='inline-flex items-center w-full px-4 py-3 last:border-b-0 border-b-2 border-secondary border-opacity-25 text-xs text-secondary'>
                <div className='w-3/4'>Investment Value</div>
                <div className='w-1/4 font-bold text-10px'>$30</div>
              </li>

              <li className='inline-flex items-center w-full px-4 py-3 last:border-b-0 border-b-2 border-secondary border-opacity-25 text-xs text-secondary'>
                <div className='w-3/4'>HSA Available Balance</div>
                <div className='w-1/4 font-bold text-10px'>$2934.32</div>
              </li>

              <li className='inline-flex items-center w-full px-4 py-3 last:border-b-0 border-b-2 border-secondary border-opacity-25 text-xs text-secondary'>
                <div className='w-3/4'>Pending Trades</div>
                <div className='w-1/4 font-bold text-10px'>$429</div>
              </li>
               
              
            </ol>
        
      </div> {/* END .card */}

      <div>
<img src="images/visitors-this-year.png" alt="" />

      </div>

        
 
        
        </div>
    </>
  )
}

export default ManagedPath