import React from 'react'
import { Link } from "react-router-dom";

const InvestmentsVerificationStatus = () => {
  return (
    <>
        <div className="max-w-md w-full m-auto px-8  mt-7">
              <header className="mb-5">
              <h2 className="font-playfair text-secondary font-bold text-2xl mb-2">
              investments verification
              </h2>
              <p className='text-secondary text-xs mb-2 uppercase font-bold'>YOU’VE BEEN VERIFIED.</p>
              <p className='text-secondary text-xs'>Let’s start building your health savings. </p>
              </header>

              <div className="text-center mt-7 mb-5"><Link to="#" className='btn uppercase'>CONTINUE</Link></div>
            </div>
    </>
  )
}

export default InvestmentsVerificationStatus