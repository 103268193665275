import React from 'react'
import { Link } from "react-router-dom";

const NeedRegistration = () => {
  return (
    <>
        <div className="h-full lg:h-auto lg:min-h-[calc(100vh_-_107px)] flex lg:justify-between lg:items-center">
        <div className="h-full flex flex-col justify-between items-center lg:h-auto w-full pt-12 lg:py-0 px-8 lg:px-0 min-h-[calc(100vh_-_60px)] lg:min-h-full">
        <div className="max-w-md mx-auto w-full">
        <div className="text-center mb-5 lg:mb-7">
            <h2 className="font-playfair text-secondary font-bold text-2xl lg:text-38px mb-2 lg:mb-3">
            oops! we can’t find you.
            </h2>
            <p className='text-cyan text-xs font-bold lg:text-lg'>Please register before creating an account.</p>
            <div className='text-secondary text-xs lg:text-lg py-5 text-justify lg:text-center'>In order to create an account with Start, you’ll need to register yourself and any family members.</div>
        </div>

        <div className="text-center mt-7 mb-5"><Link to="/where-do-you-live" className='btn  uppercase'>REGISTER</Link></div>
         <div className='text-center'><Link to="/registration" className='text-xs font-bold text-secondary uppercase'>Go Back</Link></div>
        
        </div>
         

         

        
        </div>
      </div>
  </>
  )
}

export default NeedRegistration