import React from 'react'

const Footer = () => {
  return (
    <>
        <div className="container m-auto text-xs text-secondary py-4 hidden lg:block px-30px 2xl:px-0 lg:absolute lg:left-0 lg:right-0 lg:bottom-0">
            <strong className='uppercase'>NEED HELP?</strong> Call us at 801-XXX-XXXX. 
        </div>
    </>
  )
}

export default Footer